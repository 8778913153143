import styled from "styled-components";
export default styled.div`
    max-width: 1600px;
    margin: auto;
    font-family: "Open Sans";
    // font-size: 1.7rem;
    line-height: 1.5;
    font-weight: 600;
    color: ${(props) => props.theme.black};
    padding: 1rem 1rem;
    display: grid;
    // grid-template-columns: 2fr 1fr;
    grid-gap: 20px;
    @media (max-width: 800px) {
        padding: 0;
        border: none;
        grid-template-columns: 1fr;
    }

    a.facebookConnect {
        background: ${(props) => props.theme.facebookBlue};
        color: white;
        border: 1px solid transparent;
    }
    a.facebookConnected{
    background: ${(props) => props.theme.facebookBlue};
        color: white;
        border: 1px solid transparent;
        border-radius: ${(props) => props.theme.borderRadius} 0 0 ${(props) => props.theme.borderRadius};
    }
    
    a.googleConnect {
        background: #DB4437;
        color: white;
        border: 1px solid transparent;
    }
    a.googleConnected{
    background: #DB4437;
        color: white;
        border: 1px solid transparent;
        border-radius: ${(props) => props.theme.borderRadius} 0 0 ${(props) => props.theme.borderRadius};
    }
    
    img.flogo,
    img.glogo {
        width: 1em;
        margin: 0 7px;
    }
    a,
    button {
        height:46px;
        font-family: "Open Sans";
        font-size: 1.7rem;
        font-weight: 600;
        text-transform: uppercase;
        background: white;
        cursor: pointer;
        box-shadow: none;
        padding: 1rem 2rem;
        border: none;
        border-radius: 5px;
        color: ${(props) => props.theme.black};
        border: solid 2px ${(props) => props.theme.lightGrey};
        display: flex;
        align-items: center;
        width: 100%;
        transition: all 0.2s ease-out;
        &:hover,
        &:active {
            /* color: white; */
            background: ${(props) => props.theme.lightGrey};
            // border: solid 2px white;
        }
    }
    
    a .glogo{
        border-radius: 3px;
        padding: 2px;
        background: #fff;
        box-sizing: initial;
    }
    
    a .flogo{
        border-radius: 3px;
        width: 19px;
    }
        
    button {
        border-radius: 0 ${(props) => props.theme.borderRadius} ${(props) => props.theme.borderRadius} 0;
    }
    
    .buttonGrouping {
        display: flex;
    }
    h3 {
        border-bottom: solid 2px ${(props) => props.theme.black};
        margin: 2rem 0;
    }
    button.unlink {
        width: max-content;
        font-size: 1.5em;
        border: solid 2px ${(props) => props.theme.lightGrey};
        border-left: none;
        &:hover {
            background: ${(props) => props.theme.red};
            color: white;
        }
    }
    .dangerButton:hover,
    .dangerButton:active {
        color: white;
        background: ${(props) => props.theme.red};
    }
`;
