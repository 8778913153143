import styled from "styled-components";

export default styled.button`
  font-family: "Open Sans";
  font-size: inherit;
  font-weight: 600;
  text-transform: uppercase;
  background: white;
  cursor: pointer;
  box-shadow: none;
  padding: 1rem 2rem;
  border: none;
  color: ${props => props.theme.black};
  border: solid 2px ${props => props.theme.lightGrey};
  display: flex;
  align-items: center;
  justify-content:center;

  margin-top: 1rem;
  transition: all 0.2s ease-out;
  &:hover,
  &:active {
    color: white;
    background: ${props => props.theme.lightBlue};
    border: solid 2px white;
  }
`;

