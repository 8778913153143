import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import DefaultLayout from "../DefaultLayout";
import FormStyles from "../styles/FormStyles";
import Title from "../styles/TitleStyle";
import { ClientDetailsStyle } from "../styles/ClientDetailsStyle";
import { MdCheck, MdDelete, MdClose } from "react-icons/md";
import GeneralModalStyles from "../styles/GeneralModalStyles";

import FileUploader from "../FileUploader/FileUploader";

const singleGuideline = {
    key: "",
    value: "",
};

class ClientDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            client: {
                name: "",
                notes: "",
                brandGuidelines: [],
                logo: ""
            },

            singleGuideline,

            guideLines: [],
            tags: [],
            selectedFile: null,

            file: null,
            uploader: null,
            size: 'cover',
            bgName: 'No file chosen'
        };

        this.sizes = [
            'auto',
            'contain',
            'cover'
        ];
    }

    componentDidMount() {
        if (this.props.client) {
            this.setState({
                client: this.props.client,
            });
        }

        this.getRules();
    }

    getRules = () => {
        const xsrfToken = this.props.extractCSRF();
        return fetch("/getCombinationRulesAndDictionary", {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "xsrf-Token": xsrfToken,
            },
        })
            .then((res) => {
                if (res.ok) return res.json();
                throw new Error(res.status + " " + res.statusText);
            })
            .then((data) => {
                if (data.error) throw new Error(data.message);
                return this.setState({ tags: data.brandGuidelinesTags });
            })
            .catch((err) => this.context.setBanner(true, "bad", err.message));
    };

    handleClientPreviewInputChange = (ev) => {
        const currentClient = { ...this.state.client };

        const changedProperty = ev.currentTarget.name;
        let newValue = ev.currentTarget.value;

        if (ev.currentTarget.type === "checkbox") {
            newValue = ev.currentTarget.checked;
        }

        currentClient[changedProperty] = newValue;

        this.setState({ client: currentClient });
    };

    handleGuideLinesInput = ({ currentTarget: input }) => {
        const key = input.name;

        const singleGuideline = { ...this.state.singleGuideline };
        singleGuideline[key] = input.value;

        this.setState(
            {
                singleGuideline,
            },
            () => console.log(singleGuideline)
        );
    };

    handleClientGuideLines = (e) => {
        e.preventDefault();

        if (this.state.singleGuideline.key.length === 0) {
            return;
        }

        let client = { ...this.state.client };
        client.brandGuidelines.push(this.state.singleGuideline);

        this.setState({ singleGuideline });
        this.setState({ client });
    };

    handleRemoveClientGuideLines = (key, value) => {
        this.setState({
            client: {
                ...this.state.client,
                brandGuidelines: (this.state.client.brandGuidelines = this.state.client.brandGuidelines.filter(
                    (existingRule) => existingRule.key !== key || existingRule.value !== value
                )),
            },
        });
    };

    setSelectedFile = (uploader, e) => {
        let tempClient = {...this.state.client}
        tempClient.logo = uploader.uploads[0].path;

        this.setState({
            file: uploader.uploads[0],
            uploader: uploader,
            bgName:uploader.uploads[0].filename,
            client: tempClient
        })
    }

    onSubmitSaveClient = (ev) => {
        ev.stopPropagation();
        ev.preventDefault();

        let client = { ...this.state.client };

        const xsrfToken = this.props.extractCSRF();
        new Promise((resolve, reject) => {
            // new client
            if (!client._id) {
                resolve(
                    fetch("/client", {
                        method: "post",
                        headers: {
                            "Content-Type": "application/json",
                            "xsrf-token": xsrfToken,
                        },
                        body: JSON.stringify({ client: this.state.client }),
                    })
                );
            } else {
                resolve(
                    fetch("/client", {
                        method: "put",
                        headers: {
                            "Content-Type": "application/json",
                            "xsrf-token": xsrfToken,
                        },
                        body: JSON.stringify({ client: client }),
                    })
                );
            }
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error(res.statusText);
                }
                return res.json();
            })
            .then((data) => {
                if (data.success) return data.client;
                if (data.error) {
                    throw new Error(data.message);
                }
            })
            .then((updatedClient) => {
                this.props.handleCloseModal();
                this.props.updateClients(updatedClient);

                this.props.setBanner(true, "good", "Client updated successfully");
            })
            .catch((err) => {
                this.props.setBanner(true, "bad", err.message);
            });
    };

    render() {
        const {
            client: { name, notes, brandGuidelines, logo },
        } = this.state;

        const edit = this.props.edit;

        const bgDimensions = {
            width:'100%',
            height:'250px',
            objectFit: 'cover'
        }

        return (
            <GeneralModalStyles className="modal-wrapper" style={{ opacity: this.props.showModal ? 1 : 0 }}>
                <div className="modal-body">
                    <ClientDetailsStyle>
                        <div onClick={this.props.handleCloseModal} className="modal-close">
                            <MdClose className="action-button button-delete" />
                        </div>

                        <FormStyles onSubmit={this.onSubmitSaveClient}>
                            <Title>{this.state.client._id ? `Edit ${this.state.client.name}` : "Add new client"}</Title>
                           
                            <div className="group">
                                <label>Name</label>
                                <input
                                    className="formInput"
                                    type="text"
                                    name="name"
                                    value={name}
                                    onChange={this.handleClientPreviewInputChange}
                                    required
                                />
                            </div>
                            <div className="group">
                                <label>Notes</label>
                                <textarea name="notes" onChange={this.handleClientPreviewInputChange} className="formInput" value={notes} />
                            </div>
                            <div className="group" style={{ display: "flex" }}>
                                {edit && this.state.client._id &&
                                    <FileUploader dimensions={bgDimensions} uploads={[{path: logo}]} onChange={this.setSelectedFile}/>
                                }
                                {!edit &&
                                    <FileUploader dimensions={bgDimensions} uploads={[{path: logo}]} onChange={this.setSelectedFile}/>
                                }

                                {this.state.selectedFile && (
                                    <img
                                        style={{
                                            marginLeft: "2rem",
                                            marginTop: "27px",
                                            maxWidth: "150px",
                                            maxHeight: "150px",
                                            borderRadius: "5px",
                                        }}
                                        src={this.state.selectedFile}
                                        alt={`${this.props.name} logo`}
                                    />
                                )}
                            </div>
                            <div className="group">
                                <h3>Brand Guidelines</h3>
                                {brandGuidelines && brandGuidelines.length !== 0 && (
                                    <>
                                        {brandGuidelines.map(({ key, value }, i) => {
                                            return (
                                                <div className="rule-wrapper" key={i}>
                                                    <label className="rule-key">{key}: </label>
                                                    <span className="rule-value">{value}</span>
                                                    <div
                                                        onClick={() => this.handleRemoveClientGuideLines(key, value)}
                                                        className="action-button button-delete"
                                                    >
                                                        <MdDelete />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </>
                                )}

                                <div className="brandstyles" style={{ marginTop: "30px" }}>
                                    <select
                                        value={this.state.singleGuideline.key}
                                        name="key"
                                        onChange={this.handleGuideLinesInput}
                                        data-testid="rule-key"
                                    >
                                        <option>Select One</option>
                                        {this.state.tags.map((tag) => (
                                            <option data-testid="option" key={tag} value={tag}>
                                                {tag}
                                            </option>
                                        ))}
                                    </select>

                                    <span style={{ display: "flex", width: "100%" }}>
                                        <input
                                            type="text"
                                            value={this.state.singleGuideline.value}
                                            name="value"
                                            onChange={this.handleGuideLinesInput}
                                            data-testid="rule-value"
                                        />

                                        <button
                                            onClick={this.handleClientGuideLines}
                                            className="confirm-button"
                                            style={{ padding: "0", width: "120px", fontSize: "12px" }}
                                        >
                                            ADD RULE
                                        </button>
                                    </span>
                                </div>
                            </div>
                            <div className="button-wrapper">
                                <button className="confirm-button">Save</button>
                            </div>
                        </FormStyles>
                    </ClientDetailsStyle>
                </div>
            </GeneralModalStyles>
        );
    }
}

export default withRouter(ClientDetails);
