import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import CampaignNav from "./CampaignNav";
import ClientNav from "./ClientNav";
import Loader from "./styles/Loader";

class ClientsMainPage extends Component {
    render() {
        return (
            <>
                {this.props.loadingClients && <Loader />}
                {this.props.activeClient && <Redirect to="/client" />}
                <ClientNav
                    activeClient={this.props.activeClient}
                    addClient={this.props.addClient}
                    setActiveClient={this.props.setActiveClient}
                    visibleClients={this.props.visibleClients}
                    handleSearchCampaigns={this.props.handleSearchCampaignsInput}
                    InputshowingCampaignDrawer={true}
                />
            </>
        );
    }
}
export default ClientsMainPage;
