import styled from "styled-components";

export default styled.div`
  
    .code {
      resize: none;
      padding: 1rem 1rem;
      font-size: 1.2rem;
      background: lightgrey;
      margin-top: 2rem;
      cursor: pointer;
      height: 8rem;
    }
  
    textarea{
      padding: 1rem 1rem;
      font-size: 1.7rem;
      font-family: "Open Sans";
      border: solid 1px ${props => props.theme.lightGrey};
      width: 100%;
      &:focus {
        outline: 0;
      }
    }
  
    .campaign-controls-main {
        margin-bottom: 2rem;

        &_first{
            float:left;
            
            .confirm-button{
                margin-right:20px;
            }
        }
        &_second {
            float: right;
            width: 360px;
            display:flex;
            justify-content: space-between;
        }
        
        &:after{
            content: "";
            clear: both;
            display: table;
        }
    }
  
    .gallery-wrapper{

        float:left;
        width: calc(100% - 430px);
        
        margin: 0 auto;
        padding: 20px;
        text-align: left;
        border-radius: 10px;
        background-color: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        min-height: 600px;
        
        .gallery-controls{

            display: flex;
            justify-content: space-between;
            margin-bottom: 30px;
            border-bottom: 1px solid #e2e2e2;
            padding-bottom: 20px;

            
            .filter-client{
                margin-top: 8px;
            }
            
            .galleryGroupsList{
                list-style: none;
                padding: 0;
                margin: 0;
                margin-top: 10px;
                
                .selected{
                    color: ${(props) => props.theme.lightBlue};
                    font-weight: bold;
                }
                
                li{
                    float:left;
                    font-size: 16px;
                    margin-right: 40px;
                    cursor: pointer;
                           
                    .rejected-icon{
                        float:left;
                        fill: red;
                        font-size: 20px;
                        margin-top: -3px;
                    }                           
                           
                    .colorIcon{
                        float:left;
                        width:15px;
                        height:15px;
                        border-radius: ${(props) => props.theme.borderRadius};
                    }
                    
                    .group-name{
                        float:left;
                        line-height: 14px;
                        margin-left: 5px;
                    }
                    
                    &:after{
                        content: "";
                        clear: both;
                        display: table;
                    }
                    
                    &:first-child{
                        margin-right: 10px;
                    }
                }
                
                &:after{
                    content: "";
                    clear: both;
                    display: table;
                }
            }
            
            .galleryGroupsList.active{
                .group-name{
                    color: ${(props) => props.theme.lightBlue}
                }
            }
        }
        
          
        .gallery {

            .loader {
                margin-top: 12%;
            }
            
            margin: 0 -1%;
        
            &:after{
                content: "";
                clear: both;
                display: table;
            }
                
            .photoContainer {
                float:left;
                max-width: 23%;
                border: ${(props) => props.theme.border};
                border-width:2px;
                border-radius: ${(props) => props.theme.borderRadius};
                overflow: hidden;
                margin: 0 1% 2%;
                line-height: 0;
                
                .image-header{
                    display: flex;
                    justify-content: space-between;
                    height:30px;
                    background:#fff;
                    
                    .color-icon-wrapper{
                        .colorIcon{
                            float:left;
                            width: 2rem;
                            height: 2rem;
                            margin-top: 5px;
                            margin-left: 5px;
                            border-radius: ${(props) => props.theme.borderRadius};
                        }
                        
                        &:after{
                            content: "";
                            clear: both;
                            display: table;
                        }
                    }
                    
                    .rejected-icon{
                        fill: red;
                        font-size: 28px;
                        margin-top: 2px;
                    }
                    
                    .zoom-icon{
                        font-size:23px;
                        margin-top: 3px;
                        margin-right: 3px;
                        cursor: pointer;
                    }
                }       
                
                img{
                        width: 100%;
                    cursor:pointer;
                }
                .rejected {
                    opacity: 0.6;
                    filter: grayscale();
                    transform: all 0.1s ease;
                    &:hover {
                      filter: none;
                      opacity: 1;
                    }
                }
                
                &.image-picked{
                    border-color: ${(props) => props.theme.lightBlue};
                }
                
                &:after{
                    content: "";
                    clear: both;
                    display: table;
                }
            }
        
            .modal {
                width: 100%;
                height: 100%;
                background: rgba(72, 72, 72, 0.7);
                z-index: 10;
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    
    .campaign-controls-sidebar {
        float:right;
        width: 400px;
         
        .manage-groups, .manage-embed{
            box-shadow: 0 4px 15px rgb(0 0 0 / 7%), 0 1px 3px rgb(0 0 0 / 7%);
            margin: 0 auto;
            padding: 20px;
            text-align: left;
            border-radius: 10px;
            background-color: #fff;
            border: 1px solid #e0e0e0;
            border-radius: 5px;
            
            .manage-groups-title{
                border-bottom: 1px solid #e0e0e0;
                margin-bottom: 20px;
                padding-bottom: 5px;
                
                h3 {
                    float:left;
                    margin:0;
                }
                h4 {
                    float:right;
                    margin:0;
                }
                
                &:after{
                    content: "";
                    clear: both;
                    display: table;
                }
            }
            
            .manage-group-select{
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
                
                select{
                    line-height: 16px;
                    padding: 6px 10px;
                    border: 1px solid #e0e0e0;
                    border-radius: 5px;
                    background: #fff;
                }
            }
        }

        .manage-embed {
            margin-top: 10px;
            text-align: center;
            .manage-groups-title{ 
                padding-top:0px;
            }
        }
        
        .input-range {
            .input-range__label--min,
            .input-range__label--max {
                bottom: -3.6rem;
            }

            .input-range__label {
                font-size: 2rem;

                &--value {
                    top: 0;
                }
            }
        }
    }
    
`;
