import styled from "styled-components";

export const ClientSearchBarStyle = styled.div`
    display: flex;
    justify-content: space-between;

    .search-client-wrapper {
        position: relative;

        input#search-client {
            padding: 1rem 4.5rem;
            padding-right: 2rem;
            font-size: 14px;
            height: 40px;
            width: 300px;
            border-radius: ${(props) => props.theme.borderRadius};
            border-color: ${(props) => props.theme.red};
            border: ${(props) => props.theme.border};
            cursor: pointer;
           
            &:focus {
                border: 1px solid ${(props) => props.theme.blue};
                outline: none;
            }
        }

        svg {
            font-size: 24px;
            position: absolute;
            top: 8px;
            left: 10px;
            transform: rotate(90deg);
        }
    }

    .filter-add-client {
        display: flex;

        .filter-client {
            text-align: left;
            cursor: pointer;
            margin-right: 10px;
        }
    }
`;
