import React from "react";
import { withRouter } from "react-router-dom";
import Image from "./Image";
import Modal from "./Modal";
import GalleryStyles from "./styles/GalleryStyles";
import CampaignContext from "./CampaignContext";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

class Gallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            images: "loading",
            modal: { active: false, image: null },
            picked: [],
            allSelected: false,
            groups: [
                { name: "Ungrouped", images: [] },
                { name: "Rejected", images: [] },
            ],
            selectedGroup: "",
            addingGroup: false,
            newGroupName: "",
            addToGroupTarget: "",
            isPublic: false,
            showingLog: false,
            contrast: 1,
            loading: false,
            errors: null,
        };
        this.project = this.props.location.search
            ? this.props.location.search.substr(4)
            : this.props.activeCampaign
            ? this.props.activeCampaign.projectID
            : "";
        // query looks like gallery?id=soifhysdkfsdkjhfsdkj
        this.isPublic = this.props.location.pathname.includes("shared");
    }

    fetchImages = () => {
        // console.log(this.project);
        return fetch(`/check/${this.project}`)
            .then((res) => {
                if (!res.ok) throw new Error(res.statusText);
                return res.json();
            })
            .then((data) => {
                if (data.status) {
                    if (data.status.current === "mixing") {
                        // project isn't done yet, need to send to waiting page
                        this.props.history.push(`/combinations?pid=${this.project}`);
                    } else if (data.status.current !== "done") {
                        return this.props.setBanner(true, "bad", "This campaign has no banners.", true);
                    } else {
                        const infoObject = data.combinations.shift(); // get rid of the first empty object used as general data store;
                        if (infoObject && infoObject.status && infoObject.status.errors.length) {
                            this.props.setBanner(true, "bad", "There were errors generating banners. Please check log.", true);
                        }
                        const images = data.combinations.map((image, i) => {
                            // in case the project was re-uploaded, Combinations.json still has the old path for images,
                            // so we need to change that using the current project ID from the page
                            const url = image.image;
                            // const fragments = url.split("/");
                            // const possibilities = [];
                            // possibilities.push(fragments[fragments.length - 3]);
                            // possibilities.push(fragments[fragments.length - 2]);
                            // let projectInImageUrl =
                            //   possibilities[1] === "__screenshots"
                            //     ? possibilities[0]
                            //     : possibilities[1];
                            // const project = this.props.location.search.substr(4);
                            // let urlToBeUsed = url;
                            // if (projectInImageUrl !== project) {
                            //   if (possibilities[1] === "__screenshots")
                            //     projectInImageUrl += "/__screenshots/";
                            //   urlToBeUsed = url.replace(projectInImageUrl, project + "/");
                            // }
                            let urlToBeUsed = url;
                            image.image = urlToBeUsed;
                            return image;
                        });

                        const rawGroups = infoObject.groups || [];
                        const groups = rawGroups.map((group) => {
                            group.color = "#" + ("FFFFFF" + Math.floor(Math.random() * 16777215).toString(16)).slice(-6);
                            if (group.images.length) {
                                group.images = new Set([...group.images]); // this is because the stringifying ruins sets
                            } else {
                                group.images = new Set([]);
                            }
                            return group;
                        });

                        const ungrouped = images.filter((image) => {
                            let isUngrouped = true;
                            rawGroups.forEach((group) => {
                                if (group.images.length) return;
                                group.images.forEach((groupedImage) => {
                                    if (image.image === groupedImage.image) isUngrouped = false;
                                });
                            });
                            return isUngrouped;
                        });
                        groups.unshift({ name: "Ungrouped", images: new Set(ungrouped) });
                        const [rejected] = groups.filter((group) => group.name === "Rejected");
                        if (!rejected) groups.unshift({ name: "Rejected" });
                        let errors = null;
                        if (infoObject && infoObject.status && infoObject.status.errors.length) {
                            errors = infoObject.status.errors;
                        }
                        this.setState({ images, groups, errors });
                    }
                } else if (data.error) {
                    // there was a problem and the project never finished
                    this.props.setBanner(true, "bad", data.message, true);
                }
            })
            .catch((err) => {
                this.props.setBanner(true, "bad", err.message, true);
            });
    };
    fetchPublicImages = () => {
        const xsrfToken = this.props.extractCSRF();
        const link = this.props.location.pathname.split("/")[2];
        return fetch("/getPublicImages", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "xsrf-token": xsrfToken,
            },
            body: JSON.stringify({ link }),
        })
            .then((res) => {
                if (!res.ok) throw new Error(res.statusText);
                return res.json();
            })
            .then((data) => {
                if (data.error) throw new Error(data.message);
                const infoObject = data.combinations.shift(); // get rid of the first empty object used as general data store;
                if (!this.project) this.project = infoObject.projectID;
                const images = data.combinations.map((image, i) => {
                    // in case the project was re-uploaded, Combinations.json still has the old path for images,
                    // so we need to change that using the current project ID from the page
                    const url = image.image;
                    // const fragments = url.split("/");
                    // const possibilities = [];
                    // possibilities.push(fragments[fragments.length - 3]);
                    // possibilities.push(fragments[fragments.length - 2]);
                    // let projectInImageUrl =
                    //   possibilities[1] === "__screenshots"
                    //     ? possibilities[0]
                    //     : possibilities[1];
                    // const project = this.props.location.search.substr(4);
                    // let urlToBeUsed = url;
                    // if (projectInImageUrl !== project) {
                    //   if (possibilities[1] === "__screenshots")
                    //     projectInImageUrl += "/__screenshots/";
                    //   urlToBeUsed = url.replace(projectInImageUrl, project + "/");
                    // }
                    let urlToBeUsed = url;
                    image.image = urlToBeUsed;
                    return image;
                });

                const rawGroups = infoObject.groups || [];
                const groups = rawGroups.map((group) => {
                    group.color = "#" + ("FFFFFF" + Math.floor(Math.random() * 16777215).toString(16)).slice(-6);
                    if (group.images.length) {
                        group.images = new Set([...group.images]); // this is because the stringifying ruins sets
                    } else {
                        group.images = new Set([]);
                    }
                    return group;
                });
                const ungrouped = images.filter((image) => {
                    let isUngrouped = true;
                    rawGroups.forEach((group) => {
                        if (group.images.length) return;
                        group.images.forEach((groupedImage) => {
                            if (image.image === groupedImage.image) isUngrouped = false;
                        });
                    });
                    return isUngrouped;
                });
                groups.unshift({ name: "Ungrouped", images: new Set(ungrouped) });
                const [rejected] = groups.filter((group) => group.name === "Rejected");
                if (!rejected) groups.unshift({ name: "Rejected", images: new Set() });
                this.setState({ images, groups });
            })
            .catch((err) => {
                this.props.setBanner(true, "bad", err.message, true);
            });
    };
    componentDidMount() {
        if (this.isPublic) {
            this.fetchPublicImages();
        } else {
            this.fetchImages();
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.activeCampaign !== this.props.activeCampaign) {
            this.project = this.props.activeCampaign ? this.props.activeCampaign.projectID : this.props.location.search.substr(4);
            this.props.history.push({
                pathname: "/gallery",
                search: `id=${this.project}`,
            });
            this.fetchImages();
        }
        // create colors for the groups
        if (this.state.groups.length !== prevState.groups.length) {
            const coloredGroups = this.state.groups.map((group) => {
                if (!group.color && group.name !== "Ungrouped") {
                    group.color = "#" + Math.floor(Math.random() * 16777215).toString(16);
                }
                return group;
            });
            return this.setState({ groups: coloredGroups });
        }
    }

    openModal = (e) => {
        e.stopPropagation();
        this.setState({
            modal: { active: true, image: e.currentTarget.dataset.image },
        });
    };
    closeModal = (e) => {
        e.stopPropagation();
        this.setState({ modal: { active: false } });
    };
    pickImage = (e) => {
        e.stopPropagation();
        const clickedImage = e.currentTarget.dataset.image;
        const [imageObject] = this.state.images.filter((image) => image.image === clickedImage);
        const fullImage = { ...imageObject };
        let picked = [...this.state.picked];
        let wasPicked = picked.filter((image) => image.image === clickedImage).length;
        if (wasPicked) {
            picked = picked.filter((image) => image.image !== clickedImage);
        } else {
            picked.push(fullImage);
        }
        const allSelected = picked.length === this.state.images.length && this.state.images.length !== 0;
        this.setState({ picked, allSelected });
    };

    rejectImage = (e) => {
        e.stopPropagation();
        const clickedImage = e.currentTarget.dataset.image;
        const [imageObject] = this.state.images.filter((image) => image.image === clickedImage);
        const fullImage = { ...imageObject };
        let picked = [...this.state.picked];
        let rejected = null;
        if (this.state.groups) rejected = this.state.groups.filter((group) => group.name === "Rejected")[0];
        // Go through both the currently picked images and the full rejected group
        //  to see if image was previously rejected - though just the rejected group itself should be itself enough
        let wasAlreadyRejected = picked.filter((image) => image.image === clickedImage).length;
        if (!wasAlreadyRejected && rejected) wasAlreadyRejected = [...rejected.images].filter((image) => image.image === clickedImage).length;
        if (wasAlreadyRejected) {
            picked = picked.filter((image) => image.image !== clickedImage);
        } else {
            picked.push(fullImage);
        }
        const allSelected = picked.length === this.state.images.length && this.state.images.length !== 0;

        if (!wasAlreadyRejected) return this.addToGroup(null, fullImage);
        else {
            return this.removeFromGroup(null, fullImage);
        }
    };

    renderImages = () => {
        // console.log(this.state.images);
        let images;

        if (this.state.selectedGroup && this.state.selectedGroup.images) {
            images = [...this.state.selectedGroup.images];
        } else if (this.state.selectedGroup) {
            images = [];
        } else {
            images = [...this.state.images];
        }

        return images.map((image, i) => {
            // in case the project was re-uploaded, Combinations.json still has the old path for images,
            // so we need to change that using the current project ID from the page
            const url = image.image;
            // const fragments = url.split("/");
            // const possibilities = [];
            // possibilities.push(fragments[fragments.length - 3]);
            // possibilities.push(fragments[fragments.length - 2]);
            // let projectInImageUrl =
            //   possibilities[1] === "__screenshots"
            //     ? possibilities[0]
            //     : possibilities[1];
            // const project = this.props.location.search.substr(4);
            // let urlToBeUsed = url;
            // if (projectInImageUrl !== project) {
            //   if (possibilities[1] === "__screenshots")
            //     projectInImageUrl += "/__screenshots/";
            // urlToBeUsed = url.replace(projectInImageUrl, project + "/");
            let urlToBeUsed = url;
            // }

            // get which groups and the colors the image belongs to
            const colors = [];
            let rejected = false;
            this.state.groups.forEach((group) => {
                let found = false;
                if (group.images && group.images.size) {
                    for (let pic of group.images) {
                        if (image.image === pic.image) {
                            found = true;
                            if (group.name === "Rejected") rejected = true;
                            break;
                        }
                    }
                }
                if (found) colors.push(group.color);
            });
            let picked;
            for (let o of this.state.picked) {
                if (o.image === image.image) {
                    picked = true;
                }
            }
            return (
                <Image
                    openModal={this.openModal}
                    url={urlToBeUsed}
                    key={i}
                    name={image.number}
                    picked={picked}
                    pickImage={this.isPublic ? this.rejectImage : this.pickImage}
                    colors={colors}
                    rejected={rejected}
                />
            );
        });
    };

    selectAllHandler = (e) => {
        if (this.state.allSelected) {
            this.setState({ picked: [], allSelected: false });
        } else {
            let picked;
            if (this.state.selectedGroup) {
                picked = [...this.state.selectedGroup.images];
            } else {
                picked = [...this.state.images];
            }
            this.setState({ picked, allSelected: true });
        }
    };
    sendToArchive = (e) => {
        const imageObjectsToArchive = [...this.state.picked];
        if (!imageObjectsToArchive.length) return this.props.setBanner(true, "bad", "Please select at least one image to download");
        const imagesToArchive = imageObjectsToArchive.map((imageObject) => imageObject.image);
        this.props.setBanner(true, "good", "Preparing archive...", true);
        const xsrfToken = this.props.extractCSRF();
        fetch("/pls4toarchive", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "xsrf-token": xsrfToken,
            },
            body: JSON.stringify({
                projectID: this.project,
                imagesToArchive,
            }),
        })
            .then((res) => {
                if (res.ok) return res.blob();
                else
                    return res.json().then((data) => {
                        throw new Error(data.message);
                    });
            })
            .then((blob) => {
                const file = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = file;
                link.download = "archive.zip";
                link.click();
                this.props.setBanner(true, "good", "Downloading...");
            })
            .catch((err) => this.props.setBanner(true, "bad", err.message, false, 10000));
    };
    startaddNewGroup = () => {
        return this.setState({ addingGroup: true });
    };
    finishAddNewGroup = () => {
        if (!this.state.newGroupName) return this.props.setBanner(true, "bad", "New group must have a name.");
        if (this.state.newGroupName === "Rejected" || this.state.newGroupName === "Ungrouped") {
            return this.props.setBanner(true, "bad", "That's a reserved name. Please use a different one.");
        }
        const groups = [...this.state.groups];
        const [found] = groups.filter((group) => group.name === this.state.newGroupName);
        if (found) return this.props.setBanner(true, "bad", "Group name already exists.");
        const newGroup = { name: this.state.newGroupName, images: new Set([]) };
        groups.push(newGroup);
        this.setState({
            groups,
            addingGroup: false,
            newGroupName: "",
            addToGroupTarget: "null",
        });
        const projectID = this.project;
        const xsrfToken = this.props.extractCSRF();
        fetch("/addOrUpdateGroup", {
            method: "POST",
            headers: { "Content-Type": "application/json", "xsrf-token": xsrfToken },
            body: JSON.stringify({ projectID, group: newGroup }),
        })
            .then((res) => {
                if (res.ok) return res.json();
            })
            .then((data) => {
                if (data.error) return this.props.setBanner(true, "bad", data.message);
            });
    };
    handleChange = (e) => {
        return this.setState({ [e.target.name]: e.target.value });
    };
    selectGroup = (e) => {
        let name = e.target.dataset.name;
        const group = this.state.groups.filter((group) => group.name === name)[0];
        return this.setState({ selectedGroup: group });
    };
    addToGroup = (ev, image) => {
        let groupName = this.state.addToGroupTarget;
        if (this.isPublic) {
            groupName = "Rejected";
        }
        if (!groupName) return this.props.setBanner(true, "bad", "Please select a group and try again");
        const selectedImages = image ? [image] : [...this.state.picked];
        if (!selectedImages.length) return this.props.setBanner(true, "bad", "Please select at least one image and try again");
        // Add to group
        const groups = [...this.state.groups];
        const groupObject = groups.filter((group) => group.name === groupName)[0];
        const index = groups.indexOf(groupObject);
        const group = { ...groupObject };
        if (!group.name) return this.props.setBanner(true, "bad", "Could not find selected group");
        const groupImages = group.images || new Set();
        selectedImages.forEach((image) => {
            const groupImagesArr = [...groupImages];
            const [found] = groupImagesArr.filter((existingImage) => existingImage.id === image.id);
            if (!found) groupImages.add(image);
        });
        group.images = groupImages;
        // If modifying the Rejected group log the action
        if (group.name === "Rejected") {
            const log = group.log || [];
            selectedImages.forEach((image) => {
                log.unshift(
                    `${this.context.user && this.context.user.name ? this.context.user.name + " " : ""}Rejected image ${
                        image.image.split("/__screenshots/")[1]
                    } on ${new Date().toString().slice(0, 25)}`
                );
            });
            while (log.length > 200) {
                log.pop();
            }
            group.log = log;
        }

        groups.splice(index, 1, group);
        // Remove from ungrouped
        const [ungrouped] = groups.filter((group) => group.name === "Ungrouped");
        let ungroupedImages = [...ungrouped.images];
        selectedImages.forEach((selectedImage) => {
            ungroupedImages = ungroupedImages.filter((ungroupedImage) => selectedImage.image !== ungroupedImage.image);
        });
        ungrouped.images = new Set(ungroupedImages);
        this.setState({ groups });
        if (group.name === "Ungrouped") return;
        const projectID = this.project;
        // Save the grouping on the server
        const xsrfToken = this.props.extractCSRF();
        fetch("/addOrUpdateGroup", {
            method: "POST",
            headers: { "Content-Type": "application/json", "xsrf-token": xsrfToken },
            body: JSON.stringify({
                projectID,
                group: {
                    name: group.name,
                    images: [...group.images],
                    log: group.log ? [...group.log] : [],
                },
            }),
        })
            .then((res) => {
                if (res.ok) return res.json();
            })
            .then((data) => {
                if (data.error) return this.props.setBanner(true, "bad", data.message);
            });
    };
    removeFromGroup = (ev, image) => {
        let groupName = this.state.addToGroupTarget;
        if (this.isPublic) {
            groupName = "Rejected";
        }
        if (!groupName) return this.props.setBanner(true, "bad", "Please select a group and try again");
        let selectedImages;
        if (image) {
            selectedImages = [image];
        } else {
            selectedImages = [...this.state.picked];
        }
        if (!selectedImages.length) return this.props.setBanner(true, "bad", "Please select at least one image and try again");
        // Remove from group
        let groups = [...this.state.groups];
        const groupObject = groups.filter((group) => group.name === groupName)[0];
        const index = groups.indexOf(groupObject);
        const group = { ...groupObject };
        if (!group.name) return this.props.setBanner(true, "bad", "Could not find selected group");
        selectedImages.forEach((image) => {
            let found;
            for (let i of group.images) {
                if (image.image === i.image) found = i;
            }
            group.images.delete(found);
        });
        groups.splice(index, 1, group);
        // Redo ungrouped

        groups = groups.filter((group) => group.name !== "Ungrouped");
        const images = [...this.state.images];
        const ungrouped = images.filter((image) => {
            let isUngrouped = true;
            groups.forEach((group) => {
                if (!group.images) {
                    group.images = new Set([]);
                } else {
                    group.images = new Set([...group.images]);
                }
                if (group.images.length) return; // makes sure it's a Set which always has length 0 and not an array
                group.images.forEach((groupedImage) => {
                    if (image.image === groupedImage.image) isUngrouped = false;
                });
            });

            return isUngrouped;
        });
        groups.unshift({ name: "Ungrouped", images: new Set(ungrouped) });
        this.setState({ groups });

        const projectID = this.project;
        if (group.name === "Ungrouped") return;
        if (group.name === "Rejected") {
            const log = group.log || [];

            selectedImages.forEach((image) => {
                log.unshift(
                    `${this.context.user && this.context.user.name ? this.context.user.name + " " : ""}ACCEPTED image ${
                        image.image.split("/__screenshots/")[1]
                    } on ${new Date().toString().slice(0, 25)}`
                );
            });
            while (log.length > 200) {
                log.pop();
            }
            group.log = log;
        }
        // Save the grouping on the server
        const xsrfToken = this.props.extractCSRF();
        fetch("/addOrUpdateGroup", {
            method: "POST",
            headers: { "Content-Type": "application/json", "xsrf-token": xsrfToken },
            body: JSON.stringify({
                projectID,
                group: {
                    name: group.name,
                    images: [...group.images],
                    log: group.log ? [...group.log] : [],
                },
            }),
        })
            .then((res) => {
                if (res.ok) return res.json();
            })
            .then((data) => {
                if (data.error) return this.props.setBanner(true, "bad", data.message);
            });
    };
    deleteGroup = () => {
        const groupName = this.state.addToGroupTarget;
        if (!groupName) return this.props.setBanner(true, "bad", "Please select a group and try again");
        if (groupName === "Ungrouped" || groupName === "Rejected") return this.props.setBanner(true, "bad", "That group cannot be deleted");

        let groups = this.state.groups.filter((group) => group.name !== groupName && group.name !== "Ungrouped");

        const images = [...this.state.images];
        const ungrouped = images.filter((image) => {
            let isUngrouped = true;
            groups.forEach((group) => {
                if (!group.images) {
                    group.images = new Set([]);
                } else {
                    group.images = new Set([...group.images]);
                }
                if (group.images.length) return; // makes sure it's a Set which always has length 0 and not an array
                group.images.forEach((groupedImage) => {
                    if (image.image === groupedImage.image) isUngrouped = false;
                });
            });

            return isUngrouped;
        });
        groups.unshift({ name: "Ungrouped", images: new Set(ungrouped) });
        this.setState({ groups });
        const projectID = this.project;
        const xsrfToken = this.props.extractCSRF();
        fetch("/deleteGroup", {
            method: "POST",
            headers: { "Content-Type": "application/json", "xsrf-token": xsrfToken },
            body: JSON.stringify({ projectID, group: { name: groupName } }),
        })
            .then((res) => {
                this.setState({
                    addToGroupTarget: "null",
                });
                if (res.ok) return res.json();
                else throw new Error("Something went wrong. Refresh the page and try again");
            })
            .then((data) => {
                if (data.error) return this.props.setBanner(true, "bad", data.message);
            })
            .catch((err) => this.props.setBanner(true, "bad", err.message));
    };

    selectContrast = (ev) => {
        let picked;
        const threshold = parseFloat(this.state.contrast);
        if (this.state.selectedGroup) {
            picked = [
                ...this.state.selectedGroup.images.filter((image) => {
                    if (!image.meta) return true;
                    if (image.meta.contrast >= threshold) return true;
                    return false;
                }),
            ];
        } else {
            picked = [
                ...this.state.images.filter((image) => {
                    if (!image.meta) return true;
                    if (image.meta.contrast >= threshold) return true;
                    return false;
                }),
            ];
        }
        return this.setState({ picked });
    };

    sendToAi = () => {
        this.setState({ ...this.state, loading: true });
        const imagesToSend = [...this.state.picked];
        let image_count = imagesToSend.length;
        if (image_count < 1) {
            this.setState({ ...this.state, loading: false });
            return this.props.setBanner(true, "bad", "Please select at least one image and try again");
        }

        // prepare data to send to AI
        let projectID = this.project;
        let data = { files: [], batch_id: projectID };
        imagesToSend.forEach((image) => {
            data["files"].push({
                path: image.image.substring(2),
            });
        });
        const xsrfToken = this.props.extractCSRF();
        fetch("/sendFilesAI", {
            method: "POST",
            headers: { "Content-Type": "application/json", "xsrf-token": xsrfToken },
            body: JSON.stringify(data),
        })
            .then((res) => {
                if (res.ok) return res.json();
                throw new Error(res.status + " " + res.statusText);
            })
            .then((data) => {
                if (data.success) {
                    this.setState({ ...this.state, loading: false });
                    return this.props.setBanner(true, "good", image_count + " from project " + projectID + " Images sent to AI ");
                }
            })
            .catch((err) => {
                console.log(err);
                this.setState({ ...this.state, loading: false });
                return this.props.setBanner(true, "bad", "An error has occurred during server handshake");
            });
    };

    render() {
        return (
            <GalleryStyles>
                {!this.isPublic && (
                    <div className="left" data-testid="controls">
                        <div>
                            <div>
                                <h2>View | Selected: {this.state.picked.length}</h2>
                                <button type="button" onClick={() => this.setState({ showingLog: !this.state.showingLog })}>
                                    Toggle Log
                                </button>
                            </div>
                            <hr />
                            <ul data-testid="groups-list" className="galleryGroupsList" onClick={this.selectGroup}>
                                <h3>
                                    <li className={`galleryGroup ${!this.state.selectedGroup ? "selected" : ""}`}>All</li>
                                </h3>
                                {this.state.groups.map((group, i) => (
                                    <h3 key={i}>
                                        <li
                                            data-name={group.name}
                                            className={
                                                this.state.selectedGroup && this.state.selectedGroup.name === group.name
                                                    ? "galleryGroup selected"
                                                    : "galleryGroup"
                                            }
                                        >
                                            {group.name}
                                            {group.name === "Rejected" ? (
                                                <div className="colorIcon">❌</div>
                                            ) : (
                                                <div className="colorIcon" style={{ backgroundColor: group.color }} />
                                            )}
                                        </li>
                                    </h3>
                                ))}
                            </ul>

                            <button onClick={this.selectAllHandler} data-testid="selectAllImages">
                                {this.state.allSelected ? "Select none" : "Select All Images"}
                            </button>

                            <h3>Contrast Accessible Threshold</h3>
                            <input
                                data-testid="contrast-threshold"
                                type="number"
                                name="contrast"
                                onChange={(e) => this.setState({ contrast: e.target.value })}
                                value={this.state.contrast}
                            />
                            <a
                                style={{
                                    border: "none",
                                    padding: 0,
                                    display: "inline",
                                    textDecoration: "underline",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://usecontrast.com/guide"
                            >
                                <h3>WCAG Scores : AA > 4.5 AAA > 7.0</h3>
                            </a>

                            <button onClick={this.selectContrast} data-testid="contrast-button">
                                Select contrast accessible
                            </button>
                            <button onClick={this.sendToArchive} data-testid="download-archive">
                                Download as archive
                            </button>
                        </div>
                        <div data-testid="ai-control">
                            <h2>Artificial Intelligence</h2>
                            <hr />
                            {this.state.loading && (
                                <div>
                                    <CircularProgress />
                                </div>
                            )}
                            <button data-testid="ai-send" onClick={this.sendToAi}>
                                Send to AI
                            </button>
                        </div>
                        <div data-testid="groups-edit">
                            <h2>Groups</h2>
                            <hr />
                            <button data-testid="group-add" onClick={this.startaddNewGroup}>
                                Add new group
                            </button>
                            <div
                                style={{
                                    transition: "all 0.3s ease",
                                    border: "none",
                                    padding: 0,
                                    height: this.state.addingGroup ? "10rem" : 0,
                                    overflow: "hidden",
                                    width: "100%",
                                }}
                            >
                                <input
                                    type="text"
                                    name="newGroupName"
                                    placeholder="Name"
                                    onChange={this.handleChange}
                                    value={this.state.newGroupName}
                                />
                                <button data-testid="group-save" onClick={this.finishAddNewGroup}>
                                    Save
                                </button>
                            </div>

                            <h3>
                                Selected group:
                                <select
                                    data-testid="group-selected"
                                    defaultValue={this.state.addToGroupTarget || "null"}
                                    name="addToGroupTarget"
                                    // value={this.state.addToGroupTarget}
                                    onChange={this.handleChange}
                                >
                                    <option value="null" disabled>
                                        Select One
                                    </option>
                                    {this.state.groups.map((group, i) => {
                                        if (group.name === "Ungrouped") return null;
                                        return (
                                            <option key={i} value={group.name}>
                                                {group.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </h3>
                            <button data-testid="group-addImages" onClick={this.addToGroup}>
                                Add to group
                            </button>
                            <button data-testid="group-removeImages" onClick={this.removeFromGroup}>
                                Remove from group
                            </button>
                            <button data-testid="group-delete" id="formDeleteButton" onClick={this.deleteGroup}>
                                Delete group
                            </button>
                            <h3 />
                            {this.state.picked.length === 1 ? (
                                <>
                                    <h3>Name:</h3>
                                    <span> {this.state.picked[0].image}</span>
                                    <h3>Groups:</h3>
                                    <span>
                                        {this.state.groups.map((group) => {
                                            let found = false;
                                            if (group.images && group.images.length) {
                                                group.images.forEach((image) => {
                                                    if (image.image === this.state.picked[0]) found = true;
                                                });
                                            }
                                            if (found) return <p>{group.name}</p>;
                                            return null;
                                        })}
                                    </span>
                                </>
                            ) : null}
                        </div>
                    </div>
                )}
                {this.isPublic && (
                    <div className="left" data-testid="controls">
                        <h2>View</h2>
                        <ul data-testid="groups-list" className="galleryGroupsList" onClick={this.selectGroup}>
                            <h3>
                                <li className={`galleryGroup ${!this.state.selectedGroup ? "selected" : ""}`}>All</li>
                            </h3>
                            {this.state.groups.map((group, i) => (
                                <h3 key={i}>
                                    <li
                                        data-name={group.name}
                                        className={
                                            this.state.selectedGroup && this.state.selectedGroup.name === group.name
                                                ? "galleryGroup selected"
                                                : "galleryGroup"
                                        }
                                    >
                                        {group.name}
                                        {group.name === "Rejected" ? (
                                            <div className="colorIcon">❌</div>
                                        ) : (
                                            <div className="colorIcon" style={{ backgroundColor: group.color }} />
                                        )}
                                    </li>
                                </h3>
                            ))}
                        </ul>
                    </div>
                )}
                <div className="gallery" data-testid="gallery">
                    {this.state.images ? (
                        this.state.images === "loading" ? (
                            <h1>Loading...</h1>
                        ) : (
                            this.renderImages()
                        )
                    ) : (
                        <h1 style={{textAlign: "center"}}>Sorry, no images found for this project</h1>
                    )}
                    {this.state.modal.active ? <Modal closeModal={this.closeModal} image={this.state.modal.image} /> : ""}
                </div>
                {this.state.showingLog ? (
                    <div className="log" data-testid="log">
                        <ul>
                            {this.state.selectedGroup && this.state.selectedGroup.log ? (
                                this.state.selectedGroup.log.map((entry, i) => <li key={i}>{entry}</li>)
                            ) : this.state.errors ? (
                                this.state.errors.map((entry, i) => <li key={i}>{entry}</li>)
                            ) : (
                                <li>Sorry, no log available for selected group</li>
                            )}
                        </ul>
                    </div>
                ) : null}
            </GalleryStyles>
        );
    }
}

Gallery.contextType = CampaignContext;
export default withRouter(Gallery);
