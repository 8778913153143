import styled from "styled-components";

const BannerStyles = styled.div`
    color: white;
    text-align: center;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    font-size: 24px;
    word-wrap: break-word;
    border-radius: 5px;
    transition: all ease 450ms;
    position: fixed;
    z-index: 9999;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    padding: 1rem 2rem;

    @media (max-width: 1300px) {
        font-size: 20px;
    }

    &.__bad {
        // background: ${(props) => props.theme.red};
        background: rgba(255, 74, 0, 0.76);
    }

    &.__good {
        background: ${(props) => props.theme.green};
    }
`;

export default BannerStyles;
