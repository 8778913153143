import React from "react";
import styled from "styled-components";

const Processing = styled.div`
  border: solid 1px ${props => props.theme.lightGrey};
  font: ${props => props.theme.font};
  text-align: center;
  position: relative;
  transform: skew(-7deg);

  &:after {
    content: "";
    position: absolute;
    background: ${props =>
      props.failed ? props.theme.red : props.theme.lightBlue};
    top: 0;
    left: 0;
    height: 100%;
    /* transform: skew(-7deg); */
    width: ${props => (props.failed ? 100 : props.done)}%;
    z-index: -10;
    transition: width 0.3s ease-out;
  }
`;
export default props => (
  <Processing {...props}>
    <h2>{props.failed ? "Failed" : "Processing " + props.done + "%"}</h2>
  </Processing>
);
