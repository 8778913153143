import styled from 'styled-components';

const BannerCreatorDiv = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 40px;
    
    button{
        width: 100%;
        margin-top:10px;
    }
`

export default BannerCreatorDiv