import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import NProgress from "nprogress";
class Wrapper extends Component {

  constructor(props) {
    super(props);
    this.state = {
      location: null
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.location !== prevState.location) {
      NProgress.start();
      nextProps.resetBanner();
      return { location: nextProps.location };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
    }
  }

  render() {
    NProgress.done();
    return <>{this.props.children}</>;
  }
  
}

export default withRouter(Wrapper);
