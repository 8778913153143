import styled from "styled-components";

export default styled.div`
  display: grid;
  max-width: 1000px;
  margin: 0 auto;
  font-family: "Open Sans";
  font-size: 1.7rem;
  padding: 1rem 1rem;
  color: ${props => props.theme.black};
  h3 {
    align-self: start;
    border-bottom: solid 2px ${props => props.theme.black};
  }
  @media (max-width: 1300px) {
    font-size: 1.5rem;
  }
  label {
    font-size: 1em;
    font-weight: 400;
    background: ${props => props.theme.lightGrey};
    display: block;
    text-align: center;
  }

  input,
  textarea,
  select {
    padding: 1rem 1rem;
    font-size: 1em;
    font-family: "Open Sans";
    border: none;
    width: 100%;
    &:focus {
      outline: 0;
    }
  }

  textarea {
    border: solid 2px ${props => props.theme.lightGrey};
    &:active,
    &:focus {
      outline: solid 2px ${props => props.theme.lightBlue};
    }
  }
  a,
  button {
    font-family: "Open Sans";
    font-size: inherit;
    font-weight: 600;
    text-transform: uppercase;
    background: white;
    cursor: pointer;
    box-shadow: none;
    padding: 1rem 2rem;
    border: none;
    color: ${props => props.theme.black};
    border: solid 2px ${props => props.theme.lightGrey};
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    transition: all 0.2s ease-out;
    &:hover,
    &:active {
      /* color: white; */
      background: ${props => props.theme.lightGrey};
      border: solid 2px white;
    }
  }
  select {
    border: solid 2px ${props => props.theme.lightGrey};
    margin-bottom: 7px;
  }
  button[type="submit"],
  input[type="submit"] {
    text-transform: uppercase;
    cursor: pointer;
    color: white;
    display: flex;
    justify-content: center;
    &:disabled {
      background: ${props => props.theme.lightGrey};
    }
    &:enabled {
      background: ${props => props.theme.lightBlue};
    }
    &:hover,
    &:active {
      background: ${props => props.theme.lightBlue};
      &:disabled {
        background: ${props => props.theme.lightGrey};
      }
    }
  }
  .dangerButton:hover,
  .dangerButton:active {
    color: white;
    background: ${props => props.theme.red};
  }
  ul {
    list-style: none;
    padding: 0;
  }
`;
