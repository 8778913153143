import React, { useContext, useState, useEffect } from "react";
import DefaultLayout from "./DefaultLayout";
import Title from "./styles/TitleStyle";

import ClientCard from "../Components/Clients/ClientCard";
import { GeneralModalContext } from "./GeneralModal";

import ClientDetails from "./Clients/ClientDetails";
import { MdSearch } from "react-icons/md";
import { ClientSearchBarStyle } from "./styles/ClientSearchBarStyle";
import Select from "react-select";
import DeleteModal from "./Modals/DeleteModal";

const style = {
    display: "flex",
    flexWrap: "wrap",
    overflowY: "auto",
};

const selectStyles = {
    control: (base) => ({
        ...base,
        border: "1px solid #e0e0e0",
        outline: "none",
        boxShadow: "none",
        fontSize: "14px",
        width: "235px",
        cursor: "pointer",
    }),
};

const options = [
    { value: "recently-added-campaigns", label: "Recently Added Campaigns" },
    { value: "recently-added-users", label: "Recently Added Users" },
];

const ClientsMain = ({ extractCSRF, setBanner }) => {
    const [clients, setClients] = useState([]);
    const [client, setClient] = useState(null);
    const [visibleClients, setVisibleClients] = useState([]);
    const [loadingClients, setLoadingClients] = useState(true);
    const [activeClient, setActiveClient] = useState(null);
    const [modalType, setModalType] = useState("");
    const [currentFilter, setCurrentFilter] = useState("recently-added-users")


    useEffect(() => {
        (async () => {
            await fetchClients();
        })();
    }, []);

    const openDeleteModal = (client) => {
        setClient(client);
        setModalType("delete-client");
        handleShowModal();
    };

    const openClientModal = (client) => {
        setClient(client);
        setModalType("edit-client");
        handleShowModal();
    };

    const handleSearchClientsInput = (ev) => {

        const txt = ev.target.value.toLowerCase();
        const newClients = [...clients];

        const visibleClients = newClients.filter((client) => {
            if ((client.name && client.name.toLowerCase().includes(txt)) || (client.notes && client.notes.toLowerCase().includes(txt))) return client;
        });

        setVisibleClients(visibleClients);

    };

    const handleClientFilter = (ev) => {
        if (ev.value !== currentFilter) {
            setCurrentFilter(ev.value)
        }
    }

    const deleteClient = (ev) => {
        const xsrfToken = extractCSRF();
        fetch("/client", {
            method: "delete",
            headers: {
                "Content-Type": "application/json",
                "xsrf-token": xsrfToken,
            },
            body: JSON.stringify({ client }),
        })
            .then((res) => {
                if (res.ok) return res.json();
                throw new Error(res.status + " " + res.statusText);
            })
            .then((data) => {
                if (data.error) throw new Error(data.message);
                let oldClients = [...clients];
                oldClients = oldClients.filter((current) => current._id !== client._id);

                setClients(oldClients);
                setVisibleClients(oldClients);
                setActiveClient(null);

                handleCloseModal();
            })
            .catch((err) => setBanner(true, "bad", err.message, false));
    };

    const fetchClients = async () => {

        setLoadingClients(true);

        try {

            const res = await fetch("/getClients", {
                headers: {
                    "Content-Type": "application/json",
                    "xsrf-token": extractCSRF(),
                },
            });

            if (!res.ok) {
                throw new Error(res.statusText);
            }
            const data = await res.json();
            if (!data.error) {

                let clients = [];
                for(let client in data.clients){
                    let clientObj = data.clients[client];


                    let campaignCount = 0;
                    for(const campaign in data.campaigns){
                        if(data.campaigns[campaign].client && data.campaigns[campaign].client == clientObj._id){
                            campaignCount++;
                        }
                    }

                    clientObj.campaignCount = campaignCount;
                    clients.push(clientObj)
                }

                setClients(clients);
                setVisibleClients(clients);
                setLoadingClients(false);

            } else {
                setLoadingClients(false);
                throw new Error(data.message);
            }
        } catch (err) {
            setLoadingClients(false);
            setBanner(true, "bad", err.message);
        }
    };

    const updateClients = (updatedClient) => {
        let newClients = [...clients];
        let found = false;
        newClients = newClients.map((current) => {
            if (current._id === updatedClient._id) {
                current = updatedClient;
                found = true;
            }
            return current;
        });
        if (!found) {
            newClients.unshift(updatedClient);
        }

        setClients(newClients);
        setVisibleClients(newClients);
        setClient(null);
    };

    const { showModal, handleShowModal, handleCloseModal } = useContext(GeneralModalContext);

    let DeleteModalUx, EditModal;

    if (showModal && modalType === "delete-client") {
        DeleteModalUx = (
            <DeleteModal
                key={client._id}
                acceptFunction={deleteClient}
                showModal={showModal}
                message={["Do you really want to delete ", <b>{client.name}</b>, "?"]}
                handleCloseModal={handleCloseModal}
            />
        );
    }

    if (showModal && modalType === "edit-client") {
        EditModal = (
            <ClientDetails
                extractCSRF={extractCSRF}
                client={client}
                handleCloseModal={handleCloseModal}
                showModal={showModal}
                updateClients={updateClients}
                setBanner={setBanner}
                edit={Boolean(client)}
            />
        );
    }

    return (
        <DefaultLayout>
            <Title>Client List</Title>
            <ClientSearchBarStyle style={{ marginBottom: "4rem", textAlign: "right" }}>
                <span className="search-client-wrapper">
                    <MdSearch />
                    <input onChange={handleSearchClientsInput} id="search-client" type="text" placeholder="Search Client" />
                </span>

                <div className="filter-add-client">
                    <Select
                        className="filter-client"
                        styles={selectStyles}
                        name="form-field-name"
                        options={options}
                        value={options.filter(option => option.value === currentFilter)}
                        onChange={handleClientFilter}
                    />

                    <button className="confirm-button" onClick={()=>{openClientModal(null)}}>
                        Add Client
                    </button>
                </div>
            </ClientSearchBarStyle>

            <div style={style}>
                {visibleClients.length !== 0 &&
                    visibleClients.map((client) => {
                        return <ClientCard client={client} openDeleteModal={openDeleteModal} openEditModal={openClientModal} key={client._id} />;
                    })}
                {loadingClients === false && visibleClients.length === 0 && <h2>No clients found</h2>}
                {loadingClients === true && <h2>Loading clients...</h2>}
            </div>

            {DeleteModalUx}
            {EditModal}
        </DefaultLayout>
    );
};

export default ClientsMain;
