import React, {useEffect, useState} from "react";
import FormStyles from "../styles/FormStyles";
import Title from "../styles/TitleStyle";
import { MdCheck, MdDelete, MdClose } from "react-icons/md";
import GeneralModalStyles from "../styles/GeneralModalStyles";
import ManageGroupsStyle from "../styles/ManageGroupsStyle";
import FacebookStepOneStyle from "../styles/FacebookStepOneStyle";
import Loader from "../styles/Loader";

const FacebookStepOne = ({setBanner, extractCSRF, campaignId}) => {
    const [loading, setLoading] = useState(false);
    const [adAccounts, setAdAccounts] = useState([]);
    const [activeAdAccount, setActiveAdAccount] = useState(null);
    const [adCampaigns, setAdCampaigns] = useState(null);
    const [adSets, setAdSets] = useState(null);
    const [activeAdCampaign, setActiveAdCampaign] = useState(null);

    useEffect(() => {
        fetchAccounts();
    }, []);

    const fetchAccounts = () => {
        setLoading(true);
        fetch("/getFacebookAdAccounts", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "xsrf-token": extractCSRF()
            }
        })
            .then(res => {
                if (res.ok) return res.json();
                throw new Error(res.status + " " + res.statusText);
            })
            .then(data => {
                if (!data.error) {
                    setLoading(false);
                    setAdAccounts(data.adAccounts);
                    return;
                }

                if (data.message && data.message.includes("Error validating access token")) {
                    setBanner(true, "bad", "Session expired. Please log into Facebook again on your account page.", true);
                    setLoading(false);
                    return;
                }

                throw new Error(data.message);
            })
            .catch(err => {
                setBanner(true, "bad", err.message);
                setLoading(false);
            });
    };

    const selectAdAccount = ev => {
        let newActiveAdAccount = {...activeAdAccount};

        let accountId = ev.target.options[ev.target.selectedIndex].dataset.id;

        if(!accountId){
            setActiveAdAccount(null);
            setAdCampaigns(null);
            setAdSets(null);
            setLoading(false);
            return;
        }

        // get current check if same return
        if (newActiveAdAccount && newActiveAdAccount.id === accountId){
            return;
        }

        newActiveAdAccount = adAccounts.filter(account => {
            return account.id === accountId
        })[0];

        // get campaigns
        setLoading(true);
        fetch("/getFacebookAdCampaigns", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "xsrf-token": extractCSRF()
            },
            body: JSON.stringify({
                adAccountId: newActiveAdAccount.id,
                cid: campaignId
            })
        })
            .then(res => {
                if (res.ok) return res.json();
                throw new Error(res.status + " " + res.statusText);
            })
            .then(data => {
                if (data.error) throw new Error(data.message);
                const {adCampaigns} = data;

                if (data.adCampaigns.length > 0) {
                    setAdCampaigns(adCampaigns);
                } else {
                    setAdCampaigns(null);
                    setAdSets(null);
                }

                setActiveAdAccount(newActiveAdAccount);
                setLoading(false);

            })
            .catch(err => {
                setLoading(false);
                setBanner(true, "bad", err.message);
            });
    };

    const selectAdCampaign = ev => {
        let newActiveAdCampaign = {...activeAdCampaign};

        let campaignId = ev.target.options[ev.target.selectedIndex].dataset.id;

        if(!campaignId){
            setActiveAdCampaign(null);
            setAdSets(null);

            return;
        }

        // get current check if same return
        if (newActiveAdCampaign && newActiveAdCampaign.id === campaignId){
            return;
        }

        newActiveAdCampaign = adCampaigns.filter(
            campaign => campaign.id === campaignId
        )[0];

        setActiveAdCampaign(newActiveAdCampaign);
        setLoading(true);

        // get Ad sets based on selected campaingn
        fetch("/getFacebookAdSets", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "xsrf-token": extractCSRF()
            },
            body: JSON.stringify({
                campaign_id: newActiveAdCampaign.id
            })
        })
            .then(res => {
                if (res.ok) return res.json();
                throw new Error(res.status + " " + res.statusText);
            })
            .then(data => {
                if (data.error) throw new Error(data.message);

                const {adSets} = data;

                setAdSets(adSets);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                setBanner(true, "bad", err.message);
            });
    };

    return (
        <>
            {loading && <Loader/>}
            <FacebookStepOneStyle>
                <FormStyles>
                    <Title>Generate Ad Set</Title>

                    <div className="group">
                        <label>Ad Accounts</label>
                        <select onChange={selectAdAccount}>
                            <option>Select Ad Account</option>
                            {adAccounts
                                ? adAccounts.map((account, i) => (
                                    <option
                                        key={i}
                                        className={`adAccountNameListItem ${
                                            activeAdAccount &&
                                            account.id === activeAdAccount.id
                                                ? "selected"
                                                : ""
                                        }`}
                                        data-id={account.id}
                                    >
                                        {account.name}
                                    </option>
                                ))
                                : ""}
                        </select>
                    </div>

                    <div className="group">
                        <label>Campaigns</label>
                        <select onChange={selectAdCampaign}
                                data-testid="campaigns-list"
                                className="adAccountNameList"
                        >
                            <option>Select Campaign</option>
                            {adCampaigns
                                ? adCampaigns.map((campaign, i) => (
                                    <option
                                        key={i}
                                        className={`adAccountNameListItem ${
                                            activeAdCampaign &&
                                            campaign.id === activeAdCampaign.id
                                                ? "selected"
                                                : ""
                                        }`}
                                        data-id={campaign.id}
                                    >
                                        {campaign.name}
                                    </option>
                                ))
                                : ""}
                        </select>
                    </div>

                    <div className="button-wrapper">
                        <button className="confirm-button">Next</button>
                    </div>
                </FormStyles>
            </FacebookStepOneStyle>
        </>
    );
}

export default FacebookStepOne;
