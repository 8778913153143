import styled from "styled-components";

export const CampaignCardStyle = styled.div`
    width:25%;
    
    .card-style{
        position: relative;
        width: auto;
        
        .cardTitle{
            margin-bottom: 30px;
            width: 280px;
            margin-top: -65px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .cardDetails {
            max-height: 165px;
            overflow: hidden;
        }
        
        .card-description{
            height: 220px;
            color: #000;
            text-align: left;
            padding: 2rem;
            padding-top: 0;
            position: relative;
            
            a{
                &:hover h2{
                    color: ${props => props.theme.lightBlue}
                }
                
                div{
                    padding: 2px;
                }
                
                .campaign-notes{
                    display: inline-block;
                    width: 238px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .campaign-read-more{
                    display: inline-block;
                    vertical-align: top;
                    
                    &:hover{
                        color: ${props => props.theme.lightBlue}
                    }
                }
            }
        }
    } 
    
    @media (max-width: 1450px) {
        width: 33%;
    }
`;