import styled from "styled-components";

export default styled.div`
  font-family: "Open Sans";
  font-size: 1.5rem;
  max-height: 100%;
  font-weight: 600;
  color: ${props => props.theme.black};
  padding: 2rem 2rem;
  justify-items: center;
  max-width: 1300px;
  margin: auto;

  fieldset {
    border: none;
    padding: 0;
    margin: 1em 0;
  }

  form {
    padding: 0;
  }
  a,
  button {
    font-family: "Open Sans";
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
    background: white;
    cursor: pointer;
    box-shadow: none;
    padding: 1rem 2rem;
    border: none;
    color: ${props => props.theme.black};
    border: solid 2px ${props => props.theme.lightGrey};
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    transition: all 0.2s ease-out;
    &:hover,
    &:active {
      /* color: white; */
      background: ${props => props.theme.lightGrey};
      border: solid 2px white;
    }
  }

  @media (max-width: 700px) {
    padding: 0;
    border: none;
    grid-template-columns: 1fr;
  }

  input,
  textarea,
  select {
    padding: 1rem 1rem;
    /* font-size: 2rem; */
    font-family: "Open Sans";
    border: solid 2px ${props => props.theme.lightGrey};
    width: 100%;
    &:focus {
      outline: 0;
    }
  }

  input[type="checkbox"] {
    display: none;
    & + label {
      width: 66px;
      height: 2.3rem;
      cursor: pointer;
      border: solid 2px ${props => props.theme.lightGrey};
      display: block;
      position: relative;
      transform: skew(-7deg);
      &:before {
        content: "";
        position: absolute;
        border: solid 2px white;
        left: 0;
        width: 32px;
        height: calc(2.3rem - 4px);
        background: ${props => props.theme.lightGrey};
        transition: all 0.2s ease-out;
      }
    }
  }

  input[type="checkbox"]:checked {
    & + label {
      &:before {
        background: ${props => props.theme.lightBlue};
        left: 30px;
      }
    }
  }

  button[type="submit"],
  input[type="submit"] {
    text-transform: uppercase;
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 1.5rem;
    margin-top: 1rem;
    cursor: pointer;
    &:enabled {
      color: white;
      background: ${props => props.theme.lightBlue};
    }
    &:hover,
    &:active {
      background: ${props => props.theme.lightBlue};
      &:disabled {
        background: ${props => props.theme.lightGrey};
      }
    }
  }
  h3 {
    border-bottom: solid 2px ${props => props.theme.black};
  }

  /* ARCHIVE DROP MODAL */
  .archiveSelectModal {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #browseFilesLink {
    color: rgba(72, 72, 72, 0.472);
    font-size: 1.2em;
    cursor: pointer;
  }

  #browseFilesLink:hover {
    color: rgb(72, 72, 72);
    text-decoration: underline;
  }
  #archive {
    display: none;
  }

  .showing {
    visibility: visible !important;
  }

  .archivePopup {
    background: white;
    min-width: 200px;
    width: 45%;
    max-width: 475px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .archiveDrop {
    box-sizing: border-box;
    z-index: 10;
    padding: 25px;
    display: flex;
    width: 100%;
    justify-items: center;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: solid 1px rgba(72, 72, 72, 0.467);
    transition: opacity 0.2s ease-in-out 0s;
    align-items: center;
  }
  #archiveDropInstructions {
    align-self: center;
  }

  .archiveDropDragging {
    opacity: 0.2;
  }

  #svgWrapper {
    max-width: 300px;
    max-height: 300px;
  }

  .confirmArchiveButtonWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .confirmArchiveButton {
    display: flex;
    justify-content: center;
  }

  #browseFilesLink {
    padding: 0;
    margin: 0;
  }
  
    @media print {
     body * {
        visibility:hidden;
      }
      
      #ModalContent{
       visibility:visible;
       position: absolute;
       width: 100%;
       top: 0;      
       height: 100%;
       left: 0;
      }
   }
`;
