import React, {Component} from 'react';
import styled from "styled-components";
import { makeStyles } from '@material-ui/core/styles';

import ColorPickerBox from './ColorPickerBox';
import FileUploader from '../../../FileUploader/FileUploader'

import { Dispatcher, Events } from '../../../Events';
import { reactLocalStorage } from 'reactjs-localstorage';

import fonts from '../../Fonts';

const BannerTextStyling = styled.div`
textarea {
    width:100%;
    padding:1rem;
}

.color-picker {
    // width:30px;
    background:black;
}

.section-2 {
    display:grid;
    width:100%;
    grid-template-columns: 2fr 1.3fr .7fr;
    grid-gap:1rem;
    align-items:flex-end;

    input, select {
        height:35px;
    }
}

.section-3 {
    display:grid;
    grid-template-columns: 2fr 2fr;
    align-items:center;
    margin-top:1rem;
    grid-gap:1rem;

    input, select {
        height:35px;
    }
}

.section-4 {
    display:grid;
    grid-template-columns: 2fr 2fr;
    margin-top:1rem;
    grid-gap:1rem;

    input, select {
        height:35px;
    }
}

textarea.h-100 {
    height:150px;
    transition:1s;
    resize:auto;
    border:2px solid gray;
}

textarea.h-0 {
    height:0;
    transition:1s;
    resize:none;padding:0;
    border:2px solid transparent;
}

.item-display {
    display:flex;
    flex-direction:column;
}

input[type="submit"] {
    padding: 8px 25px;
    border-radius: 5px;
    background: #52bad5;
    color: white;
    margin-left: 20px;
    border:2px solid transparent;
    font-weight:bold;

    &:hover {
        cursor:pointer;
        background:white;
        border:2px solid #52bad5;
        color:#52bad5;
    }
}
`

class BannerTextOptions extends Component {

    static defaultProps = {
        id: null,
        edit: false,
        options: {
            content:'',
            font:'Arial',
            size:'20px',
            tag: 'span',
            color: '#000',
            class: 'omni',
            position:'left',
            style: ''
        },
        closeModal: () => {}
    }

    constructor(props) {

        super(props);

        let fonts = {
            'Arial': null, 
            'Arial Black': null,
            'Times New Roman': null,
            'Times': null,
            'Courier New':null,
            'Courier': null,
            'Tahoma': null,
            'Verdana': null,
            'Georgia' : null,
            'Palatino': null,
            'Garamond': null,
            'Bookman': null,
            'Impact': null,
            'Comic Sans': null
        }

        this.state = { 
            fonts: {...fonts, ...reactLocalStorage.getObject('fonts')},
            tags: ['div', 'span', 'h1', 'h2', 'h3', 'h4'],
            classes: ["top", "bottom", "left", "right"],
            positions: ["Left", "Right", "Center", "Justify"],
            fontSizes: ["6px", "12px", "14px", "16px", "18px", "20px", "25px", "30px", "40px", "50px", "60px"],
            displayColorPicker: false,
            toggleCustomCss: false,
            options: props.options
        }

    }

    toggleCustomCssArea = () => {
        this.setState(prevState => ({
            toggleCustomCss: !prevState.toggleCustomCss
        }))
    }

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    setTag = e => {
        const options = { ...this.state.options, tag: e.target.value}
        this.setState(() =>({options}));
    }
    
    setContent = e => {
        const options = { ...this.state.options, content: e.target.value}
        this.setState(() =>({options}));
    }
    
    setStyle = e => {
        const options = { ...this.state.options, style: e.target.value}
        this.setState(() =>({options}));
    }

    setFont = e => {
        const options = { ...this.state.options, font: e.target.value}
        this.setState(() =>({options}));
    }

    setSize = e => {
        const options = { ...this.state.options, size: e.target.value }
        this.setState(() =>({options}));
    }

    setColor = (color, event) => {
        const options = { ...this.state.options, color: color.hex }
        this.setState(() =>({options}));
        this.handleClose();
    }

    setPosition = e => {
        const options = { ...this.state.options, position: e.target.value}
        this.setState(() =>({options}));
    }

    setClass = e => {
        const options = { ...this.state.options, class: e.target.value}
        this.setState(() =>({options}));
    }

    setCustomFont = (files) => {

        var last = null;

        files.uploads.map((item) => {
            this.state.fonts[item.name] = item;
            last = item.name;
        })

        const options = { ...this.state.options, font: last}
        this.setState({fonts: this.state.fonts, options: options});
        
        fonts.replace(this.state.fonts);

    }
    
    onSubmit = (e) => {

        e.preventDefault();

        Dispatcher.dispatch({
            event: this.props.edit ? Events.UPDATE_BANNER_ELEMENT : Events.ADD_BANNER_ELEMENT,
            payload: {
                'id': this.props.id,
                'type': 'BannerTextItem',
                'options': this.state.options
            }
        })

        this.props.closeModal();
    }
    
    render() {

        const { state, props } = this;

        const { 
            onSubmit, 
            setTag, 
            setPosition, 
            setSize,
            setFont,
            setStyle, 
            setClass,
            setCustomFont,
            setContent,
        } = this;

          // Display the fiont size in px
          const renderTags = () => {
            return(
                <select name="text-tag" style={{ textTransform:'uppercase' }} className="custom-select" id="choose-font-size" value={state.options.tag} onChange={setTag}>
                    {state.tags.map((tag, index) => (<option key={index} value={tag}>{tag}</option>))}
                </select>
            )
        }

        // Display the fiont size in px
        const renderTextPositions = () => {
            return(
                <select style={{width: '100%'}} className="custom-select" name="text-position" id="text-position" value={this.state.options.position} onChange={setPosition}>
                    {state.positions.map((position) => (<option key={'p_'+position} value={position}>{position}</option>))}
                </select>
            )
        }
        

        // Display the fiont size in px
        const renderFontSizes = () => {
            return(
                <select name="text-size" className="custom-select" id="choose-font-size" value={state.options.size} onChange={setSize}>
                    {state.fontSizes.map((size, index) => (<option key={index} value={size}>{size}</option>))}
                </select>
            )
        }

        // Display the fiont size in px
        const renderFontList = () => {
            return(
                <select name="font-family" className="custom-select" id="text-font-size" value={state.options.font} onChange={setFont}>
                    {Object.keys({...state.fonts,...{'Custom': null}}).map((font,index) => (<option key={index} value={font}>{font}</option>))}
                </select>
            )
        }

        return (
            <BannerTextStyling>

                <form onSubmit={onSubmit}>
                    <div>
                        <label htmlFor="text-field"><b>Insert Text</b></label>
                        <textarea value={state.options.content} id="text-field" rows="1" cols="30" style={{resize: 'none'}} onChange={setContent} required></textarea>
                    </div>
                    
                    <div className="section-2">

                        <div className="item-display">
                            <label htmlFor="text-font-size"><b>Font Family</b></label>
                            { renderFontList() }
                        </div>
                        
                        { state.options.font === 'Custom' ? 
                        <div className="item-display">
                            <FileUploader 
                                multiple={true}
                                preview={false}
                                onChange={setCustomFont} 
                                label="Upload font"
                                persistent="true"
                                extensions=".ttf,.otf,.woff"
                            />
                        </div> : ''
                        }
                        
                        <div className="item-display">
                            <label htmlFor="choose-font-size"><b>Font Size</b></label>
                            { renderFontSizes() }
                        </div>
                       
                        <div className="item-display">
                            <label htmlFor="choose-font-size"><b>Font Color</b></label>

                            <ColorPickerBox 
                                color={state.options.color}
                                handleClose={this.handleClose} 
                                handleClick={this.handleClick} 
                                onChangeComplete={this.setColor}
                                displayColor={state.displayColorPicker}
                            />

                        </div>
                    </div>

                    <div className="section-3">

                    <div style={{display:'flex', flexDirection: 'column'}}>
                            <label htmlFor="text-textClass"><b>Text Tag</b></label>
                            { renderTags() }
                        </div>

                        <div>
                            <label htmlFor="text-mixer"><b>Mixer Identifier</b></label>
                            <div style={{display:'flex', 'alignItems': 'center'}}>
                                <input id="text-mixer" placeholder="omni" onChange={setClass} value={state.options.class} style={{width:'100%'}} />
                            </div>
                        </div>

                        <div style={{alignSelf: 'flex-start'}}>
                            <label htmlFor="text-mixer"><b>Text Position</b></label>
                            <div style={{display:'flex', 'alignItems': 'center'}}>
                                { renderTextPositions() }
                            </div>
                        </div>

                        <div>
                            <label htmlFor="text-mixer"><b>Custom CSS</b></label>
                            <div style={{display:'flex', 'alignItems': 'center'}}>
                                    <textarea 
                                        id="text-css"
                                        value={state.options.style}
                                        placeholder="background-color: red"
                                        onChange={setStyle} 
                                        style={{marginTop:'1rem'}} 
                                        rows="8" ></textarea>
                            </div>
                        </div>
                     
                    </div>
                    
                    <div className="section-4">

                        {/* <div style={{marginTop:'2rem'}}>
                            <a style={{display:'flex', 'alignItems': 'center'}} className="btn-default" onClick={this.toggleCustomCssArea}><b>Add Custom Css</b></a>
                        </div> */}

                        <div style={{display:'flex', justifyContent : 'flex-end', marginTop:'2rem'}}>
                           
                        </div>
                        
                    </div>

                    <div style={{display:'flex', justifyContent : 'flex-end', marginTop:'2rem'}}>
                        <div>
                            <input type="submit" value={ props.edit ? 'Update' : 'Add' }/>
                            <a className="gray-btn-modal" onClick={ props.closeModal }>Close</a>
                        </div>
                    </div>
                </form>

            </BannerTextStyling>
        )
   }
}


export default BannerTextOptions;