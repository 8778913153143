import styled from "styled-components";

export default styled.div`
    .accounts {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
        grid-gap: 20px;
        display: grid;
        max-height: 30vh;
        overflow-y: auto;
        @media (max-width: 700px) {
            grid-template-columns: 100%;
        }
  }
`;