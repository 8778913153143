import React, {useState} from "react";
import { useHistory } from 'react-router-dom'
import Title from "./styles/TitleStyle";
import FormStyles from "./styles/FormStyles";

const MixerModal = ({setBanner, extractCSRF, cid}) => {
    const [selectedArchive, setSelectedArchive] = useState(null);
    const [archive, setArchive] = useState(null);

    let formRef = React.createRef();
    const history  = useHistory();

    const handleArchiveDrop = e => {
        e.preventDefault();
        e.stopPropagation();
        const file = e.dataTransfer.items[0].getAsFile();
        if (!file) return;
        if (
            file.type !== "application/x-zip-compressed" &&
            file.type !== "application/octet-stream" &&
            file.type !== "application/x-zip-compressed" &&
            file.type !== "multipart/x-zip" &&
            file.type !== "application/zip"
        ) {
            console.log(`Invalid file type: ${file.type}`);
            return setBanner(true, "bad", "Wrong file type");
        }

        setArchive(file);
        setSelectedArchive({selectedArchive: {name: file.name, size: file.size}})
    };

    const handleArchiveSelect = e => {
        const file = e.target.files[0];
        if (!file) return;
        if (
            file.type !== "application/x-zip-compressed" &&
            file.type !== "application/zip" &&
            file.type !== "application/octet-stream" &&
            file.type !== "application/x-zip-compressed" &&
            file.type !== "multipart/x-zip"
        ) {
            return setBanner(true, "bad", "Wrong file type");
        }

        setArchive(file);
        setSelectedArchive({selectedArchive: {name: file.name, size: file.size}});
    };

    const handleFormSubmit = e => {
        e.preventDefault();
        if (!archive)
            return setBanner(true, "bad", "Cannot submit: No archive selected.");

        const data = new FormData(formRef.current);
        let bannerPrefix = data
            .get("name_prefix")
            .match(/[a-zA-Z0-9_]{0,}/g)
            .slice(0, 255)
            .join("");
        data.set("name_prefix", bannerPrefix);
        // data.append("archive", archive, selectedArchive.name);

        fetch(`/upload${cid ? `?cid=${cid}` : ""}`, {
            method: "POST",
            headers: {
                "xsrf-token": extractCSRF()
            },
            body: data
        })
            .then(res => {
                if (res.ok && res.status === 200) {
                    return res.json();
                } else {
                    return setBanner(true, "bad", res.statusText, false);
                }
            })
            .then(async data => {
                if (!data.error) {
                    // if (this.props.activeCampaign) {
                    //     try {
                    //         await this.props.fetchCampaigns(this.props.activeCampaign._id);
                    //     } catch (err) {
                    //         console.log("Error refreshing campaigns: " + err.message);
                    //     }
                    // }
                    return history.push(data.redirect, {
                        clientRules: data.clientRules,
                    });
                }
                throw new Error(data.message);
            })
            .catch(err =>
                setBanner(true, "bad", err.message, false)
            );
    };

    return (
        <FormStyles ref={formRef} id="mainForm" action="/upload" method="POST" encType="multipart/form-data" onSubmit={handleFormSubmit}>
            <Title>Mix Banners</Title>

            <div className="archiveDrop" style={{display:"flex"}} onDrop={handleArchiveDrop}>
                <div>
                    <p style={{fontSize: "16px"}}>Drop archive file or browse your system</p>
                    <label id="browseFiles">
                        <input
                            onChange={handleArchiveSelect}
                            type="file"
                            name="archive"
                            id="archive"
                            required
                            accept="application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip"
                            style={{marginTop: "15px"}}
                        />
                    </label>
                </div>

                <img id="svgWrapper" style={{width: "120px", marginTop: "20px", marginLeft: "130px"}} src="/folder.svg" alt="folder icon" />
            </div>

            <div className="group" style={{display:"flex", marginTop: "5px"}}>
                <label style={{marginRight: "10px"}}>Generate HTML files:</label>
                <input id="generate_html" name="generate_html" type="checkbox" />
                <label htmlFor="generate_html" style={{marginTop: "2px"}} />
            </div>

            <div style={{display:"flex", marginTop: "20px"}}>
                <div className="group" style={{marginRight: "20px"}}>
                    <label>Image Width</label>
                    <input
                        type="number"
                        name="image_width"
                        placeholder="max: 4096px"
                    />
                </div>

                <div className="group" style={{marginRight: "20px"}}>
                    <label>Image Height</label>
                    <input
                        name="image_height"
                        type="number"
                        placeholder="max: 2160px"
                    />
                </div>

                <div className="group">
                    <label>Max image file size (kb)</label>
                    <input
                        type="number"
                        name="image_size"
                        placeholder="Leave blank for no limit"
                    />
                </div>
            </div>

            <div style={{display:"flex", marginTop: "10px"}}>
                <div className="group" style={{marginRight: "20px"}}>
                    <label>Wait for this element to load:</label>
                    <input
                        type="text"
                        name="last_element"
                        placeholder=".last, #last_image, etc"
                    />
                </div>

                <div className="group">
                    <label>Prefix to name each generated image</label>
                    <input
                        type="text"
                        name="name_prefix"
                        placeholder="banner"
                        pattern="[a-zA-Z0-9_]{0,}"
                        title="Single word with no special characters. Stick to letters, numbers and _"
                    />
                </div>
            </div>

            <div className="button-wrapper" style={{marginTop: "30px"}}>
                <button type="submit" className={`confirm-button ${selectedArchive ? "" : ""}`} disabled={!selectedArchive}>Start Project</button>
            </div>
        </FormStyles>
    );
}

export default MixerModal;
