import React, {Component} from 'react';

import styled from "styled-components";
import Button from '../../../styles/Button';

import {Dispatcher, Events} from '../../../Events';

import FileUploader from '../../../FileUploader/FileUploader'
                                             
const BannerBgImgStyling = styled.div`

    input[type="submit"] {
        background:red;
    }

    label {
        cursor:pointer;
    }

    #output {
        display:block;
    }

`
        
class BannerBackgroundOptions extends Component {

    constructor(props) {

        super();

        this.sizes = [
            'auto',
            'contain',
            'cover'
        ];

        this.state = {
            file: null,
            uploader: null,
            size: 'cover',
            bgName: 'No file chosen'
        }
        
    }

    setSize = (e) => {
        this.setState({ 
            size: e.target.value
        });
    } 

    onChange = (uploader, e) => {
        this.setState({
           file: uploader.uploads[0],
           uploader: uploader,
           bgName:uploader.uploads[0].filename
       })
    }
    
    onSubmit = (e) => {

        e.preventDefault();

        Dispatcher.dispatch({
            event: Events.CHANGE_BANNER_BACKGROUND,
            payload: {
                'type': '',
                'file': this.state.file,
                'size': this.state.size
            }
        });

        this.props.closeModal();

    }

    render() {
        
        // bg dimensions
        const bgDimensions={
            width:'100%',
            height:'250px',
            objectFit: 'cover'
        }
        // Display the bg types
        // const renderBackgroundSizes = () => {
        //     return(
        //         <select id="renderBgSize" className="custom-select" name="background-sizes" value={this.state.size} onChange={this.setSize}>
        //             { this.sizes.map((size,index) => (<option key={index} value={size}>{size}</option>)) }
        //         </select>
        //     )
        // }
        
        return (
            <BannerBgImgStyling>
                <form onSubmit={this.onSubmit} action="">
                    <FileUploader dimensions={bgDimensions} uploads={[]} onChange={this.onChange} />
                   
                   <div style={{display:'flex', justifyContent:'space-between', marginTop: '2.5rem', borderTop: '1px solid #dadada', paddingTop: '12px'}}>
                        <button  className="btn-default">Set As Background</button>
                   </div>
                    
                </form>
            </BannerBgImgStyling>
        )
    }
}

export default BannerBackgroundOptions;