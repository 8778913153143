import React from "react";
import styled from "styled-components";

const Loader = styled.div`
  font-size: 2.2rem;
  background: ${props => props.theme.lightBlue};
  color: white;
  text-transform: uppercase;
  font-weight: 800;
  /* max-width: 80%; */
  text-align: center;
  margin: auto;
  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
  p {
    margin: 0;
    padding: 0.5rem 3rem;
    transform: skew(-7deg);
  }
  span {
    animation-name: blink;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    &:nth-child(2) {
      animation-delay: 0.5s;
    }
    &:nth-child(3) {
      animation-delay: 1s;
    }
  }
`;

export default () => (
  <Loader data-testid="loader">
    <p>
      Loading
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </p>
  </Loader>
);
