import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import CampaignNavStyles, { StyledLinks, Selected } from "./styles/CampaignNavStyles";
import CampaignContext from "./CampaignContext";
import CampaignList from "./CampaignsList";
import CampaignDrawerStyles from "./styles/CampaignDrawerStyles";
import SearchBox from "./styles/SearchBox";

const CampaignDrawer = (props) => (
    <CSSTransition in={props.showing} appear={true} mountOnEnter={true} unmountOnExit={true} timeout={{ enter: 200, exit: 200 }} classNames="drawer">
        <CampaignDrawerStyles data-testid="campaign-drawer">
            <SearchBox value={props.searchCampaigns} name="Search" onKeyUp={props.handleSearchCampaignsInput} />
            <CampaignList {...props} />
        </CampaignDrawerStyles>
    </CSSTransition>
);

const Add = styled.button`
    font-family: "Open Sans";
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    padding: 0 2rem;
    font-weight: 400;
    background: none;
    border: none;
    border-bottom: 2px solid ${(props) => props.theme.lightGrey};
    text-transform: uppercase;
    color: ${(props) => props.theme.black};
    cursor: pointer;
    min-width: 100px;
    position: relative;
    &:after {
        content: "";
        width: 0;
        height: 2px;
        background: ${(props) => props.theme.lightBlue};
        position: absolute;
        margin-top: 1em;
        transition: all 0.4s ease;
    }
    &:focus,
    &:hover {
        outline: none;
        &:after {
            width: calc(100% - 4rem);
        }
    }
    @media (max-width: 700px) {
        min-width: 80px;
        padding: 0 1rem;
    }
`;

class CampaignNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showingCampaignDrawer: !!this.props.showingCampaignDrawer,
        };
    }
    static contextType = CampaignContext;
    // componentDidMount() {
    //   console.log(this.props.showingCampaignDrawer);
    //   this.setState({
    //     showingCampaignDrawer: !!this.props.showingCampaignDrawer
    //   });
    // }

    toggleCampaignDrawer = (e) => {
        const current = this.state.showingCampaignDrawer;
        return this.setState({ showingCampaignDrawer: !current }, this.context.resetVisibles);
    };

    showCampaignDrawer = () => this.setState({ showingCampaignDrawer: true });
    hideCampaignDrawer = () => this.setState({ showingCampaignDrawer: false });
    
    render() {
        return (
            <CampaignContext.Consumer>
                {({ activeCampaign, addCampaign, setActiveCampaign, visibleCampaigns, handleSearchCampaignsInput, user }) => (
                    <CampaignNavStyles>
                        <Add
                            data-testid="add-campaign"
                            onClick={() => {
                                addCampaign();
                                this.hideCampaignDrawer();
                                this.props.history.push("/campaign");
                            }}
                        >
                            <span role="img" aria-label="plus sign">
                                ➕
                            </span>
                            <span> New Campaign </span>
                        </Add>
                        <Selected data-testid="active-campaign" onClick={this.toggleCampaignDrawer}>
                            {(activeCampaign && activeCampaign.name) || (activeCampaign && "Creating new campaign") || "Select a campaign"}
                        </Selected>
                        {activeCampaign && (
                            <StyledLinks>
                                <Link to="/campaign">Overview</Link>

                                {user.googleAccount && user.googleAccount.name && (
                                    <Link data-testid="google-link" to="/googleCampaign">
                                        Google
                                    </Link>
                                )}
                                {user.facebookAccount && user.facebookAccount.name && (
                                    <Link data-testid="fb-link" to="/facebookCampaign">
                                        Facebook
                                    </Link>
                                )}
                                {activeCampaign.projectID && (
                                    <>
                                        <Link data-testid="api-link" to="/generateScript">
                                            API
                                        </Link>
                                        <Link data-testid="gallery-link" to={`/gallery?id=${activeCampaign.projectID}`}>
                                            Gallery
                                        </Link>
                                    </>
                                )}
                            </StyledLinks>
                        )}
                        <CampaignDrawer
                            data-testid="campaign-drawer"
                            setActiveCampaign={setActiveCampaign}
                            visibleCampaigns={visibleCampaigns}
                            showing={this.state.showingCampaignDrawer}
                            toggleDrawer={this.toggleCampaignDrawer}
                            handleSearchCampaignsInput={handleSearchCampaignsInput}
                        />
                    </CampaignNavStyles>
                )}
            </CampaignContext.Consumer>
        );
    }
}
export default withRouter(CampaignNav);
