import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AIMainePage from "../styles/AIMainePageStyle";
import FileUploader from "../FileUploader/FileUploader";
import Modal from '@material-ui/core/Modal';
import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import Fade from "@material-ui/core/Fade/Fade";
import CloseBtnModal from "../styles/CloseBtnModal";
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SettingsIcon from '@material-ui/icons/Settings';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import styled from "styled-components";
import PropTypes from "prop-types";
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import {Link} from "react-router-dom";

const styles = theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex:'10000!important',
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width:600,
        border:'none',
        outline:'none',
        position: 'relative',
        height: '90%'
    },

    paper_small: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width:500,
        border:'none',
        outline:'none',
        position: 'relative',
        height:200,
    },

    content: {
        overflow: 'scroll',
        maxHeight: '90%',
        overflowX: 'hidden'
    },

    loader: {
        padding: '10px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    chip: {
        margin: 2,
        fontSize: "13px"
    },

    noLabel: {
        marginTop: theme.spacing(3),
    },
    select: {
        height: '50px',
        minWidth: '200px',
        overflow: 'auto'
    },

    root: {
        fontSize: "14px"
    },
    loader_form: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        position: 'absolute',
        background: 'rgba(255,255,255,0.5)'
    },
    relative: {
        position: 'relative'
    },
    submit_wrapper: {
        width: '150px',
        position: 'absolute',
        left: '170px',
        top: '18px'
    },
    divider_title: {

    },
    button: {

    }
});
const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
            width: 300,
            fontSize: '14px',
        }
    },
};

class AIMainPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            loading: true,
            form_loading: false,
            table_loading: false,
            refresh_uploads: false,
            has_next: false,
            has_prev: false,
            page: 1,
            pages: 0,
            per_page: 10,
            pagination: '',
            batches: [],
            selected_batches: [],
            uploads: null,
            selectedImage: null,
            grade_order_da: <UnfoldMoreIcon />,
            grade_order_d: 0,
            grade_order_aa: <UnfoldMoreIcon />,
            grade_order_a: 0,
            grade_order_ia: <UnfoldMoreIcon />,
            grade_order_i: 0,
            processed: {images: []},
            remove_image: null,
            remove_image_open: false
        };

        this.options = {
        };
    }

    componentDidMount() {
        this.getProcessedImages(false);
    }

    getProcessedImages = (table_loader) => {
        if(table_loader){
            this.setState({table_loading: true});
        }else{
            this.setState({loading: true});
        }
        var params = {};
        if(this.state.grade_order_d > 0){
            params['grade_type'] = 'deep_gaze'
            params['grade_order'] = this.state.grade_order_d === 1 ? 'ASC' : 'DESC'
        }else if (this.state.grade_order_i > 0){
            params['grade_type'] = 'icf'
            params['grade_order'] = this.state.grade_order_i === 1 ? 'ASC' : 'DESC'
        }else if (this.state.grade_order_a > 0){
            params['grade_type'] = 'average'
            params['grade_order'] = this.state.grade_order_a === 1 ? 'ASC' : 'DESC'
        }

        params['per_page'] = this.state.per_page;
        params['page'] = this.state.page;
        params['batch'] = this.state.selected_batches;

        fetch("/getFilesAI", {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "xsrf-token": this.props.extractCSRF
            },
            body: JSON.stringify(params)
        }).then(res => {

            if (res.ok) return res.json();

            throw new Error(res.status + " " + res.statusText);
        }).then(data => {
            if (data.success) {
                this.setState({
                    processed: data,
                    loading: false,
                    table_loading: false,
                    has_next: data.has_next,
                    has_prev: data.has_prev,
                    page: data.page,
                    per_page: data.per_page,
                    pages: data.pages,
                    batches: data.batches,
                }, () => {
                    this.setState({pagination: this.getPagination()});
                });
            }
        }).catch(

            err => {
                console.log(err.message)

            }
        );
    };


    handleFormSubmit = e => {
        e.preventDefault();
        let data = {'files':this.state.uploads};

        if(data){
            this.setState({form_loading: true});
            fetch("/sendFilesAI", {
                method: "POST",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                    "xsrf-token": this.props.extractCSRF
                },
                body: JSON.stringify(data)
            })
                .then(res => {
                    if (res.ok) return res.json();
                    throw new Error(res.status + " " + res.statusText);
                })
                .then(data => {
                    if (data.success) {
                       this.setState({
                           uploads: null,
                           form_loading: false
                       });
                       this.refreshUploadComponent();
                       this.getProcessedImages();
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }

    };

    onOpenModal = i => {
        this.setState({ open: true, selectedImage: i });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    removeConfirmation = (index) => {
        this.setState({remove_image: index, remove_image_open: true});
    };

    cancelRemoval = () => {
        this.setState({remove_image: null, remove_image_open: false});
    };

    removeImage = () => {
        var post = this.state.processed.images[this.state.remove_image];
        var params = {'image_id': post.id};
        this.setState({table_loading: true, remove_image: null, remove_image_open: false});
        fetch("/removeAIResults", {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "xsrf-token": this.props.extractCSRF
            },
            body: JSON.stringify(params)
        }).then(res => {
            if (res.ok) return res.json();
            throw new Error(res.status + " " + res.statusText);
        }).then(data => {
            if (data.success) {
                console.log(data)
            }
            this.getProcessedImages(true);
        }).catch(
            err => {
                console.log(err.message)

            }
        );
    };

    onChangeHandler = e => {
        this.setState({
            uploads: e.uploads
        })
    };

    getStatus = i => {
        switch (i) {
            case 0:
                return 'Received';
            case 1:
                return 'Pending';
            case 2:
                return 'Processing';
            case 3:
                return 'Finished';
            case 4:
                return 'Error';
            default:
                return 'Unknown Error';
        }
    };

    renderModal = () => {
        // Check to see if there's a selected post. If so, render it.
        if (this.state.selectedImage !== null) {
            const post = this.state.processed.images[this.state.selectedImage];
            return (
                <div>
                    <a href={this.state.processed.image_base + post.pdf} target="_blank"><PictureAsPdfIcon style={{ fontSize: 40 }}/></a>
                    <div>
                        <img style={{'width':'90%'}}
                            src={this.state.processed.image_base + post.accessible_location}
                        />
                    </div>
                    <div>
                        Batch Id: {post.batch_id} <br />
                        Campaign: {post.campaign} <br />
                        Image Id: {post.image_id} <br />
                        Status: {this.getStatus(post.status)} <br />
                        Average Grade: <strong>{post.average_grade}</strong>
                    </div>
                    <div>
                        <h2> Deep Gaze: </h2>
                        <div>
                            <img style={{'width':'45%'}}
                            src={this.state.processed.image_base  + post.heatmap_location}
                            />
                            <img style={{'width':'45%'}}
                            src={this.state.processed.image_base + post.heatmap_intense_location}
                            />
                        </div>
                        <div>
                            High Attention Area percentage: {post.area_attention} <br />
                            Average Attention Value: {post.avg_a_v} <br />
                            Attention span clusters: {post.clusters} <br />
                            Highest Attention Value: {post.highest_level} <br />
                            Total Pixels Analyzed: {post.total_p} <br />
                            Total High Attention Pixels: {post.high_p} <br />
                            <strong>Grade Algorithm: {post.grade}</strong> <br />
                        </div>
                        <h2> ICF - alg: </h2>
                        <div>
                            <img style={{'width':'45%'}}
                            src={this.state.processed.image_base + post.heatmap_icf_location}
                            />
                            <img style={{'width':'45%'}}
                            src={this.state.processed.image_base + post.heatmap_icf_intense_location}
                            />
                        </div>
                        <div>
                            High Attention Area percentage: {post.icf_area_attention} <br />
                            Average Attention Value: {post.icf_avg_a_v} <br />
                            Attention span clusters: {post.icf_clusters} <br />
                            Highest Attention Value: {post.icf_highest_level} <br />
                            Total Pixels Analyzed: {post.icf_total_p} <br />
                            Total High Attention Pixels: {post.icf_high_p} <br />
                            <strong>Grade Algorithm: {post.icf_grade}</strong> <br />
                        </div>
                    </div>
                    { post.template_results.length &&
                        <div>
                            <h2> Contextual Analysis: </h2>
                            { post.template_results.map((template, index) => (
                                <div>
                                    <div key={index+'template'}>
                                        <img style={{'width':'45%'}}
                                             src={this.state.processed.image_base + template.accessible_location}
                                        />
                                        <img style={{'width':'45%'}}
                                             src={this.state.processed.image_base + template.heatmap_location}
                                        />
                                    </div>
                                    <div>
                                        banner attention average: {template.average_attention}<br />
                                        template attention average: {template.total_average}<br />
                                        Division: {parseFloat(template.average_attention/template.total_average).toFixed(4)}
                                    </div>
                                    <hr />
                                </div>
                            ))}
                        </div>
                    }
                </div>
            );
        }
    };

    gradeOrder = (type) => (event) => {
        event.preventDefault();
        var orderDict = {
            grade_order_da: <UnfoldMoreIcon />,
            grade_order_d: 0,
            grade_order_aa: <UnfoldMoreIcon />,
            grade_order_a: 0,
            grade_order_ia: <UnfoldMoreIcon />,
            grade_order_i: 0,
        };
        switch(type){
            case 'd':
                if (this.state.grade_order_d === 0) {
                    orderDict['grade_order_d'] = 1;
                    orderDict['grade_order_da'] = <ArrowDropUpIcon />;
                }else{
                    if(this.state.grade_order_d === 1){
                        orderDict['grade_order_d'] = 2;
                        orderDict['grade_order_da'] = <ArrowDropDownIcon />;
                    } else{
                        orderDict['grade_order_d'] = 1;
                        orderDict['grade_order_da'] = <ArrowDropUpIcon />;
                    }
                }
                break;
            case 'i':
                console.log('icf order');
                if (this.state.grade_order_i === 0) {
                    orderDict['grade_order_i'] = 1;
                    orderDict['grade_order_ia'] = <ArrowDropUpIcon />;
                }else{
                    if(this.state.grade_order_i === 1){
                        orderDict['grade_order_i'] = 2;
                        orderDict['grade_order_ia'] = <ArrowDropDownIcon />;
                    } else{
                        orderDict['grade_order_i'] = 1;
                        orderDict['grade_order_ia'] = <ArrowDropUpIcon />;
                    }
                }
                break;
            case 'a':
                console.log('average order');
                if (this.state.grade_order_a === 0) {
                    orderDict['grade_order_a'] = 1;
                    orderDict['grade_order_aa'] = <ArrowDropUpIcon />;
                }else{
                    if(this.state.grade_order_a === 1){
                        orderDict['grade_order_a'] = 2;
                        orderDict['grade_order_aa'] = <ArrowDropDownIcon />;
                    } else{
                        orderDict['grade_order_a'] = 1;
                        orderDict['grade_order_aa'] = <ArrowDropUpIcon />;
                    }
                }
                break;
            default:
                console.log('default')
        }
        this.setState(orderDict, () => {
            this.getProcessedImages(true);
        });

    };

    pagination = (param) => (e) => {
        var pageDict = {};
        if(typeof(param)==="number"){
            if(param > 0 && param < this.state.pages+1){
                pageDict['page'] = parseInt(param);
            }
        }else{
            if(param === "next"){
                if(this.state.has_next){
                    pageDict['page'] = this.state.page+1;
                }
            }
            if(param === "previous"){
                if(this.state.has_prev){
                    pageDict['page'] = this.state.page-1;
                }
            }
        }
        this.setState(pageDict, () => {
                this.getProcessedImages(true);
        });
    };

    getPagination = () => {
        var prev_options = {};
        if (!this.state.has_prev){
            prev_options['disabled'] = 'disabled';
        }
        var next_options = {};
        if (!this.state.has_next){
            next_options['disabled'] = 'disabled';
        }

        // get the previous 3 and no more:
        var previous_page_btn = [];
        var previous_overflow = '';
        var max_prev = 0;
        for(var i=this.state.page-1; i > 0 && max_prev < 3; i--) {
            previous_page_btn.unshift(<button type="button" key={"page-prev-key-" + i}  onClick={this.pagination(i)}>{i}</button>);
            max_prev ++;
        }
        if(this.state.page-3 > 0){
            previous_overflow = <button>...</button>;
        }

        // get the next 3 and no more:
        var next_page_btn = [];
        var next_overflow = '';
        var max_next = 0;
        for(var j=this.state.page+1; j <= this.state.pages && max_next < 3; j++) {
            next_page_btn.push(<button type="button" key={"page-next-key-" + j} onClick={this.pagination(j)}>{j}</button>);
            max_next++;
        }
        if(this.state.pages - (this.state.page + 3) > 0){
            next_overflow = <button>...</button>;
        }

        return <div className="pagination-holder">
                    <button type="button" onClick={this.pagination('previous')} {...prev_options}><ArrowBackIosIcon /></button>
                    {previous_overflow}
                    {previous_page_btn}
                    <button type="button" className="current-active">{this.state.page}</button>
                    {next_page_btn}
                    {next_overflow}
                    <button type="button" onClick={this.pagination('next')} {...next_options}><ArrowForwardIosIcon /></button>
                </div>
    };

    handleChange = (event) => {
        this.setState({selected_batches: event.target.value}, () => {
                    this.getProcessedImages(true);
        });
    };

    refreshUploadComponent = () =>  this.setState({refresh_uploads: !this.state.refresh_uploads});

    render() {
        const { open, remove_image_open } = this.state;
        const {classes} = this.props;

        return(
            <AIMainePage>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    className={classes.modal}
                    onClose={this.onCloseModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}>
                    <Fade in={this.state.open}>
                        <div className={classes.paper} id="ModalContent">
                            <h2>Image results</h2>

                            <CloseBtnModal onClick={this.onCloseModal}>
                                x
                            </CloseBtnModal>
                            <div className={classes.content} >{this.renderModal()}</div>
                        </div>
                    </Fade>

                </Modal>

                <Modal
                    aria-labelledby="confirm-image-removal"
                    aria-describedby="confirm-image-removal-description"
                    open={remove_image_open}
                    className={classes.modal}
                    onClose={this.cancelRemoval}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}>
                    <Fade in={this.state.remove_image_open}>
                        <div className={classes.paper_small} id="ModalImageRemovalContent">
                            <h2>Are you sure you want to remove this image?</h2>

                            <CloseBtnModal onClick={this.cancelRemoval}>
                                x
                            </CloseBtnModal>

                            <div style={{display:'flex', justifyContent: 'space-between', width:'70%', marginLeft: '15%', marginTop: '30px'}}>
                                <button type="button" className="btn-default" onClick={this.cancelRemoval}>Cancel</button>
                                <button type="button" className="btn-default alert-btn" onClick={this.removeImage}>Remove Image</button>
                            </div>
                        </div>
                    </Fade>
                </Modal>

                <form
                    style={{width: '90%', marginLeft: '5%'}}
                    ref={this.formRef}
                    id="mainForm"
                    action="/ai_upload"
                    method="POST"
                    encType="multipart/form-data"
                    onSubmit={this.handleFormSubmit}
                    className={classes.relative}
                >
                    { this.state.form_loading &&
                        <div className={classes.loader_form}>
                            <CircularProgress />
                        </div>
                    }
                    <h3 className={classes.divider_title} style={{'position': 'relative'}}>SEND TO AI
                        <Link title="Edit Context template for AI" to="/ai-output-settings"
                              style={{'display': 'inline-flex', 'width': 'auto', 'margin': '2px', 'padding': '10px', 'position': 'absolute', 'right': 0, 'bottom': 0, 'border': 'none'}}>
                            <SettingsIcon fontSize="large"/>
                        </Link>
                    </h3>
                    <fieldset className={classes.relative}>
                        <label>Select one or more images to create a new batch</label>

                        <div className={classes.submit_wrapper}>
                            <button type="submit" disabled={!this.state.uploads && !this.state.form_loading}>
                                Send to A.I.
                            </button>
                        </div>

                        <FileUploader
                            onChange={this.onChangeHandler}
                            multiple
                            label="UPLOAD IMAGE(S)"
                            persistent={"True"}
                            refreshForm={this.state.refresh_uploads}
                            onRefreshed={this.refreshUploadComponent}/>
                    </fieldset>
                </form>

                <div>
                    { this.state.loading &&
                        <div className={classes.loader}>
                            <CircularProgress />
                        </div>
                    }

                    { !this.state.loading &&
                        <TableContent>
                           {this.state.table_loading && <div className="table-loader">  <CircularProgress /> </div> }
                            <h3>AI RESULTS</h3>
                            <fieldset>
                                <label>Filter by Batch ID</label>
                                <Select  id="batch-filters"
                                         multiple
                                         value={this.state.selected_batches}
                                         onChange={this.handleChange}
                                         input={<Input />}
                                         renderValue={(selected) =>
                                             <div className={classes.chips}>
                                                 {selected.map((value) => (
                                                     <Chip key={value} label={value} className={classes.chip} />
                                                 ))}
                                             </div>}
                                         MenuProps={MenuProps}
                                         className={classes.select}>
                                    {this.state.batches.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            <Checkbox checked={this.state.selected_batches.indexOf(name) > -1} />
                                            <span style={{fontSize:'13px'}}>{name}</span>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </fieldset>

                           <table>
                                <thead>
                                <tr>
                                    <th></th>
                                    <th>Image</th>
                                    <th>Batch ID</th>
                                    <th><span className="hover-active" onClick={this.gradeOrder('d')}>Deep Gaze Grade {this.state.grade_order_da}</span></th>
                                    <th><span className="hover-active" onClick={this.gradeOrder('i')}>ITK Grade {this.state.grade_order_ia}</span></th>
                                    <th><span className="hover-active" onClick={this.gradeOrder('a')}>Average Grade {this.state.grade_order_aa}</span></th>
                                    <th>Options</th>
                                    <th><button onClick={this.getProcessedImages}>refresh</button></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr><td colSpan="8"></td></tr>

                                {this.state.processed &&
                                this.state.processed.images.map((image, index) => (
                                    <tr key={index}>
                                        <td>{((this.state.page-1)*this.state.per_page) + index + 1}</td>
                                        <td>
                                            <img height="60px" src={this.state.processed.image_base + image.accessible_location} />
                                        </td>
                                        <td>
                                            {image.batch_id}
                                        </td>
                                        <td>
                                            {image.grade}
                                        </td>
                                        <td>
                                            {image.icf_grade}
                                        </td>
                                        <td>
                                            {image.average_grade}
                                        </td>
                                        <td colSpan="2">
                                            <div style={{'display':'flex'}}>
                                                <button style={{ "margin-right": "5px" }} onClick={() => { this.removeConfirmation(index) }}>Remove</button>
                                                <button onClick={() => { this.onOpenModal(index) }}>View</button>
                                            </div>
                                        </td>

                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            {this.state.pagination}

                        </TableContent>
                    }
               </div>
            </AIMainePage>
        )
    }
}

const TableContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    
    h3 {
        border-bottom: solid 2px #393939;
        width: 90%;
        text-align: left;
    }
    
    divider{
        display: block;
        width: 90%;
        background: #e1e1e1;
        height: 2px;
        margin: 0;
    }
    
    fieldset{
        width: 90%;
        text-align: right;
    }
    
    table {
        border: 2px solid #e1e1e1;
        border-radius: 10px;
        width: 90%;
         thead {
             tr{
                 th{
                    font-size: 14px;
                    text-align: left;
                    padding-bottom: 10px;
                 }
             }
         }
         tbody{
             tr:first-child{
                td{
                    border-top: 2px solid #e1e1e1;
                    padding-top: 20px;
                }
             }
             tr {
                 td{
                    padding-left:10px;
                    padding-right: 10px;
                }
            }
        }
    }
    .hover-active {
        cursor: pointer;
    }
    .table-loader{
        padding: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 100%;
        background-color: rgba(255,255,255,0.5);
    }
    
    .pagination-holder{
        display: flex;
        flex-direction: row;
        justify-content: center;
        
        button:disabled{
            color: #E1E1E1;
        }
        
        button.current-active{
            background: lightgray;
        }
    }
`;

AIMainPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AIMainPage);
