import React from "react";
import {MdZoomOutMap} from "react-icons/all";
import {MdClose} from "react-icons/md";

export default class Image extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: {active: false, image: null},
            loaded: false
        }
    }
    openModal = (e) => {
        e.stopPropagation();
        this.setState({
            modal: { active: true, image: e.currentTarget.dataset.image },
        });
    };
    closeModal = (e) => {
        e.stopPropagation();
        this.setState({ modal: { active: false } });
    };

    render() {
        return (
            <div style={this.state.loaded ? {} : {display: 'none'}}>
                {this.state.modal.active &&
                    <div className="modal" onClick={this.closeModal}>
                        <div style={{border: "2px solid #E1E1E1", borderRadius: "5px", position:"relative", lineHeight: "0"}}>
                            <p style={{position:"absolute", top: '0', right: "5px", fontWeight: "bold", fontSize: "12px", color: "#52bad5"}}>CLOSE</p>
                            <img src={this.state.modal.image} alt="" />
                        </div>

                    </div>
                }

                <div className={`photoContainer ${this.props.picked ? ("image-picked") : ("")}`}>
                    <div className="image-header">
                        {this.props.rejected ? (
                            <MdClose className="rejected-icon"/>
                        ) : this.props.colors ? (
                            <div className="color-icon-wrapper">
                                {
                                    this.props.colors.map((color, i) => (
                                        <div key={i} className="colorIcon" style={{backgroundColor: color}} />
                                    ))
                                }
                            </div>
                        ) : null}
                        {this.props.image.score && <span style={{lineHeight: "30px", fontSize: "14px", fontWeight: "bold"}}>{this.props.image.score}</span>}
                        <MdZoomOutMap className="zoom-icon" onClick={this.openModal} data-image={this.props.url} />
                    </div>
                    <img
                        src={this.props.url}
                        onClick={() => {this.props.pickImage(this.props.url)}}
                        className={`photo ${this.props.rejected ? "rejected" : ""}`}
                        alt=""
                        style={this.state.loaded ? {} : {display: 'none'}}
                        onLoad={() => this.setState({loaded: true})}
                    />
                </div>
            </div>
        );
    }
}

