import React, { Component } from "react";
import { NONAME } from "dns";

export class RulesBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rules: {},
      selectedRule: "",
      visible: false
    };
  }
  componentDidMount() {
    this.parseRules();
  }
  parseRules = () => {
    // Make rules a bit more usable
    const rules = {};
    this.props.rules.forEach(ruleObject => {
      rules[ruleObject.key] = rules[ruleObject.key] || [];
      rules[ruleObject.key].push(ruleObject.value);
    });
    return this.setState({ selectedRule: null, rules: rules || {} });
  };
  selectRule = e => {
    return this.setState({ selectedRule: e.target.name });
  };
  toggleVisibility = e => {
    return this.setState({ visible: !this.state.visible });
  };
  handleAction = e => {
    const determines = this.props.determines;
    const value = this.props.originalValue + ";" + e.target.dataset.value;
    const fakeEvent = {
      target: { name: determines, value }
    };
    return this.props.callback(fakeEvent);
  };

  render() {
    const ruleValues = this.state.rules[this.state.selectedRule];
    return (
      <div className="rulesBox">
        {this.state.visible ? (
          <>
            <button
              className="visibilityButton"
              onClick={this.toggleVisibility}
            >
              Hide Brand Guidelines
            </button>
            {!this.state.selectedRule &&
              Object.keys(this.state.rules).map(ruleName => (
                <button name={ruleName} onClick={this.selectRule}>
                  {ruleName}
                </button>
              ))}
            {this.state.selectedRule && (
              <>
                <button onClick={() => this.setState({ selectedRule: "" })}>
                  ⬅ Back | {this.state.selectedRule}
                </button>
                <ul>
                  {ruleValues.map(ruleValue => {
                    return (
                      <li>
                        {ruleValue}{" "}
                        <button
                          className="addButton"
                          onClick={this.handleAction}
                          data-value={ruleValue}
                        >
                          Add
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </>
            )}
          </>
        ) : (
          <button className="visibilityButton" onClick={this.toggleVisibility}>
            Show Brand Guidelines
          </button>
        )}
      </div>
    );
  }
}

export default RulesBox;
