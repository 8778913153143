import React, {Component} from 'react';

import BannerTextButton from './Elements/BannerText/BannerTextButton';
import BannerImageButton from './Elements/BannerImage/BannerImageButton';
import BannerBackgroundButton from './Elements/BannerBackground/BannerBackgroundButton';
import Title from "../styles/TitleStyle";
import CampaignPicker from "./Elements/CampaignPicker";

class BannerCreatorSidebar extends Component {
    render() {
        return (
            <>
                <Title>Banner Elements</Title>
                <div className="banner-buttons">
                    <BannerTextButton/>
                    <BannerImageButton/>
                    <BannerBackgroundButton/>
                </div>

                <CampaignPicker extractCSRF={this.props.extractCSRF}/>
            </>
        )
    }
}

export default BannerCreatorSidebar;