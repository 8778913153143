import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import PropTypes from 'prop-types';

import Button from '../styles/Button';
import CloseBtnModal from '../styles/CloseBtnModal';

import './Styles/forms.scss';
import {MdClose} from "react-icons/md";

const styles = theme => ({

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex:'10000!important',
    },
    
    paper: {
        backgroundColor: theme.palette.background.paper,
        // boxShadow: theme.shadows[5],
        boxShadow: "0 4px 15px rgb(0 0 0 / 7%), 0 1px 3px rgb(0 0 0 / 7%)",
        padding: theme.spacing(2, 4, 3),
        width:600,
        border:'none',
        outline:'none',
        position: 'relative',
    }

});

class BannerModal extends Component {

    constructor(props) {
        super(props);
    }
    
    handleOpen = () => {
        this.setState({open:true});
    }

    handleClose = () => {
        this.setState({open:false});
    }

    render() {
        
        const { classes, handleClose, handleOpen } = this.props;
        const { state, props } = this;

        return (     
            <>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={props.open}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 500 }}
                    styles={{ overlay: { background: "#FFFF00" } }}
                >
                    <Fade in={props.open}>
                    <div className={classes.paper} style={{borderRadius: "5px", borderColor: "1px solid #e0e0e0"}}>
                        <div onClick={handleClose} className="modal-close" style={{textAlign: "right", margin: "-10px -10px -36px 0"}}>
                            <MdClose className="action-button button-delete" />
                        </div>
                        <h2 id="transition-modal-title">{props.title}</h2>

                        <CloseBtnModal onClick={handleClose}>x</CloseBtnModal>
                        <div className="modal-content">
                            {props.children}
                        </div>
                    </div>
                    </Fade>
                </Modal>
            </>
        )
    }
}

export default withStyles(styles)(BannerModal);