import React, { Component } from "react";
import { Frame } from "scenejs";

import { Dispatcher, Events } from '../../Events';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

import "../styles.scss";

class BannerElement extends Component {
    defaults = {
        width: "150px",
        height: "150px",
        left: "0px",
        top: "0px",
        transform: {
            rotate: "0deg",
            scaleX: 1,
            scaleY: 1,
            matrix3d: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
            translate: [0, 0]
        }
    };

    state = {
        target: null
    };

    getClassName() {
        return this.type;
    }

    componentDidUpdate() {

        if(this.target && this.frame) {
            this.frame.set('z-index', this.props.zindex);
            this.setTransform(this.target);
        }

    }

    componentDidMount() {

        this.target = document.getElementById(this.props.id);
        this.el = document.getElementById(`${this.props.id}_el`);
        this.dimensions = this.props.options.dimensions;

        // Detect dimensions and set it to the frame size
        if(this.props.type === "BannerImageItem" && this.dimensions){

            if(this.dimensions.width > parseFloat(this.props.offsetW))
                this.dimensions.width = parseFloat(this.props.offsetW);

            if(this.dimensions.height > parseFloat(this.props.offsetH))
                this.dimensions.height = parseFloat(this.props.offsetH);

            this.defaults.width  =  this.dimensions.width + "px";
            this.defaults.height =  this.dimensions.height + "px";

        }
    
        this.frame = new Frame(this.props.frame ? this.props.frame : this.defaults);

        if(this.target && this.frame) {

            console.log(this.frame.width); // hi

            if(this.props.autosize && this.props.autosize === true && !this.props.frame){
                this.frame.set('width', this.target.clientWidth + "px");
                this.frame.set('height', this.target.clientHeight + "px");
            }

        }

        this.setState({target: this.target });
        this.setTransform(this.target);

    }

    componentWillUnmount() {
        // nothing
    }

    setTransform(target) {
        target.style.cssText = this.frame.toCSS();
    }
    
    setLabel(clientX, clientY, text) {
        this.label.style.cssText = `display: block; transform: translate(${clientX}px, ${clientY - 10}px) translate(-100%, -100%) translateZ(-100px);`;
        this.label.innerHTML = text;
    }

    onDrag = ({ target, clientX, clientY, top, left, isPinch }) => {

        this.frame.set("left", `${left}px`);
        this.frame.set("top", `${top}px`);
       
        this.setTransform(target);
        
        if (!isPinch) {
            this.setLabel(clientX, clientY, `X: ${left.toFixed(0)}px<br/>Y: ${top.toFixed(0)}px`);
        }
    };

    onEdit = (e) => {

        Dispatcher.dispatch({
            event: Events.EDIT_BANNER_ELEMENT,
            payload: {
                'id': this.props.id,
                'type': this.props.type,
                'options': this.props.options
            }
        })

    }

    onTrash = (e) => {

        if(!window.confirm(`Are you shure you want to delete '${this.props.id}' ? `))
            return;
    
        Dispatcher.dispatch({
            event: Events.REMOVE_BANNER_ELEMENT,
            payload: {
                'id': this.props.id
            }
        })

    }
    
    onScale = ({ target, delta, clientX, clientY, isPinch }) => {

        const scaleX = this.frame.get("transform", "scaleX") * delta[0];
        const scaleY = this.frame.get("transform", "scaleY") * delta[1];

        this.frame.set("transform", "scaleX", scaleX);
        this.frame.set("transform", "scaleY", scaleY);
        
        this.setTransform(target);

        if (!isPinch) {
            this.setLabel(
                clientX,
                clientY,
                `S: ${scaleX.toFixed(2)}, ${scaleY.toFixed(2)}`
            );
        }

    };

    onRotate = ({ target, clientX, clientY, beforeDelta, isPinch }) => {
        
        const degrees = parseFloat(this.frame.get("transform", "rotate")) + beforeDelta;

        this.frame.set("transform", "rotate", `${degrees}deg`);
        this.setTransform(target);

        if (!isPinch) {
            this.setLabel(clientX, clientY, `R: ${degrees.toFixed(1)}`);
        }
    };
    
    onResize = ({ target, clientX, clientY, width, height, isPinch }) => {

        this.frame.set("width", `${width}px`);
        this.frame.set("height", `${height}px`);

        this.setTransform(target);

        if (!isPinch) {
            this.setLabel(clientX, clientY, `W: ${width}px<br/>H: ${height}px`);
        }
    };

    onWarp = ({ target, clientX, clientY, delta, multiply }) => {

        this.frame.set(
            "transform",
            "matrix3d",
            multiply(this.frame.get("transform", "matrix3d"), delta)
        );

        this.setTransform(target);
        this.setLabel(clientX, clientY, `X: ${clientX}px<br/>Y: ${clientY}px`);
    };
    
    onEnd = ({ target, delta, clientX, clientY, isPinch }) => {
        
        // Save position chords

        this.props.onChanged({
            frame: this.frame.properties,
            clientX: clientX,
            clientY: clientY,
            isPinch: isPinch
        });

        this.label.style.display = "none";
    };

    onRenderStart = (e) => {
       // console.log(e);
    }

    render() {

        const { id, locked, onToggleLocked, children, bringToFront, sendToBack } = this.props;

        const { 
            onEdit,
            onTrash,
            // onLockAndUnlock
        } = this;

        return (
          <>
            <ContextMenuTrigger attributes={{'className': 'banner-element-holder'}} id={id + "_menu"}>
                   {children}
            </ContextMenuTrigger>
            <ContextMenu name={id} id={id + "_menu"}>
                <MenuItem disabled>
                    {id}
                </MenuItem>
                <MenuItem divider />
               {!locked && <MenuItem onClick={onToggleLocked}>
                    Lock
                </MenuItem>}

                {locked && <MenuItem onClick={onToggleLocked}>
                    Unlock
                </MenuItem>}
                
                <MenuItem onClick={bringToFront}>
                    Bring to front
                </MenuItem>

                <MenuItem onClick={sendToBack}>
                    Send to back
                </MenuItem>

                <MenuItem divider />

                <MenuItem onClick={onEdit}>
                    Edit
                </MenuItem>
            
                <MenuItem onClick={onTrash}>
                    Trash
                </MenuItem>

                

            </ContextMenu>
          </>
        );
      }
    
}

export default BannerElement;



