import React, { useState, createContext } from "react";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export const GeneralModalContext = createContext();

const GeneralModalProvider = ({ children }) => {
    const [showModal, setShowModal] = useState(false);

    showModal ? disableBodyScroll(document) : enableBodyScroll(document)

    const handleShowModal = () => {
        window.scrollTo(0, 0);
        setShowModal(true);

        window.addEventListener("keydown", handleKeyPressCloseModal);
    };

    const handleKeyPressCloseModal = (e) => {
        if (e.keyCode === 27) {
            handleCloseModal();
        }
    };

    const handleCloseModal = (e) => {
        setShowModal(false);

        window.removeEventListener("keydown", handleKeyPressCloseModal);
    };

    return (
        <GeneralModalContext.Provider
            value={{
                handleCloseModal: handleCloseModal,
                handleShowModal: handleShowModal,
                showModal: showModal,
            }}
        >
            {children}
        </GeneralModalContext.Provider>
    );
};

export default GeneralModalProvider;
