import styled from "styled-components";

export default styled.div`
    .group-list{
        margin: 0;
        padding: 0;
        
        .group-wrapper{
            display: flex;
            justify-content: space-between;
            padding: 5px 0px 5px 10px;
            
            .group{
                margin-bottom: 0;
            }
            
            .action-button{
                border: 0;
            }
            
            &:hover{
                box-shadow: 0 1px 3px rgb(0 0 0 / 7%), 0 1px 3px rgb(0 0 0 / 7%);
            }
        }
    }
    
    .add-group-wrapper{
        display:flex;
        justify-content: space-between;
        margin-top: 20px;
           
        input{
            width:130px;
            height: 32px;
        }
        
        .confirm-button{
            font-size:12px;
            padding:0;
            width:120px;
        }
    }
    
    .button-wrapper{
        margin-top:30px;
    }
`;