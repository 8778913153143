// Reusable Banner component

// Accepts bannerType = "good", "bad"; bannerText = String; bannerShowing = Boolean as props

import React from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import BannerStyles from "./styles/BannerStyles";

const Banner = (props) => {
    if (!props.bannerText) return null;
    
    return (
        <CSSTransition
            in={props.bannerShowing}
            appear={true}
            mountOnEnter={true}
            unmountOnExit={true}
            timeout={{ enter: 450, exit: 450 }}
            classNames="banner"
        >
            <BannerStyles data-testid="banner-text" id="stateBanner" className={`__${props.bannerType}`}>
                {props.bannerText}
            </BannerStyles>
        </CSSTransition>
    );
};

Banner.propTypes = {
    bannerShowing: PropTypes.bool.isRequired,
    bannerType: PropTypes.string,
    bannerText: PropTypes.string.isRequired,
};
export default Banner;

// setBanner function to make it easy to set banner as a callback and dismiss banner after a timeout
// Needs to be binded to parent component state:
// import {setBanner} from ./Banner;
// this.setBanner = setBanner.bind(this)

// export function setBanner(
//     showing = true,
//     type,
//     text,
//     permanent = false,
//     timeoutDuration = 3000
// ) {
//     clearTimeout(this.bannerTimeout);
//     this.setState(
//         {bannerShowing: showing, bannerType: type, bannerText: text},
//         () => {
//             if (permanent) return;
//             this.bannerTimeout = setTimeout(() => {
//                 this.setState({
//                     bannerShowing: false,
//                     bannerType: null,
//                     bannerText: ""
//                 });
//             }, timeoutDuration);
//         }
//     );
// }
