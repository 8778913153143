import React, {Component} from "react";
import DashboardStyles from "./styles/DashboardStyles";
import Loader from "./styles/Loader";
import Title from "./styles/TitleStyle";
import ExtendedWrapperFromModalStyles from "./styles/ExtendedWrapperFromModalStyles";
import FormStyles from "./styles/FormStyles";

export default class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            rules: {},
            stringifiedRules: ""
        };
    }

    componentDidMount() {
        this.getRules();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.user.sudo !== this.props.user.sudo && this.props.user.sudo) {
            this.getRules();
        }
    }

    saveChanges = ev => {
        ev.preventDefault();
        const users = [...this.state.users];
        const [user] = users.filter(user => user._id === ev.target.dataset.id);
        const xsrfToken = this.props.extractCSRF();
        return fetch("/updateApprovalAndPerms", {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "xsrf-token": xsrfToken
            },
            body: JSON.stringify(user)
        })
            .then(res => {
                if (res.ok) {
                    return res.json();
                }
                throw new Error(res.status + " " + res.statusText);
            })
            .then(data => {
                if (data.success)
                    return this.props.setBanner(
                        true,
                        "good",
                        "Changes saved successfully"
                    );
                throw new Error(data.message);
            })
            .catch(err => this.props.setBanner(true, "bad", err.message));
    };

    handleChange = ev => {
        const users = [...this.state.users];
        const [id, property] = ev.target.name.split("__");
        const [user] = users.filter(user => user._id === id);
        user[property] =
            ev.target.type === "checkbox"
                ? ev.target.checked
                : parseInt(ev.target.value);

        this.setState({users});
    };

    getRules = () => {
        const xsrfToken = this.props.extractCSRF();
        return fetch("/getCombinationRulesAndDictionary", {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "xsrf-Token": xsrfToken
            }
        })
            .then(res => {
                if (res.ok) return res.json();
                throw new Error(res.status + " " + res.statusText);
            })
            .then(data => {
                if (data.error) throw new Error(data.message);
                return this.setState({
                    rules: data,
                    stringifiedRules: JSON.stringify(data, null, 2),
                    loading: false,
                });
            })
            .catch(err => {
                this.props.setBanner(true, "bad", err.message);
            });
    };
    saveRules = ev => {
        try {
            JSON.parse(this.state.stringifiedRules);
        } catch (err) {
            return this.props.setBanner(
                true,
                "bad",
                "JSON parsing failed. Check your input and try again"
            );
        }
        const xsrfToken = this.props.extractCSRF();
        return fetch("/updateGeneralRules", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "xsrf-Token": xsrfToken
            },
            body: JSON.stringify({
                newRules: JSON.parse(this.state.stringifiedRules)
            })
        })
            .then(res => {
                if (!res.ok) throw new Error(res.status + " " + res.statusText);
                return res.json();
            })
            .then(data => {
                if (data.error) throw new Error(data.message);
                if (data.success)
                    return this.props.setBanner(
                        true,
                        "good",
                        "Options saved succesfully"
                    );
            })
            .catch(err => this.props.setBanner(true, "bad", err.message));
    };
    resetRules = () => {
        const confirm = window.confirm(
            "Do you want to reset the rules to defaults? All changes are lost. Are you sure?"
        );
        if (!confirm) return;
        const xsrfToken = this.props.extractCSRF();
        return fetch("/resetGeneralRules", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "xsrf-Token": xsrfToken
            }
        })
            .then(res => {
                if (!res.ok) throw new Error(res.status + " " + res.statusText);
                return res.json();
            })
            .then(data => {
                if (data.error) throw new Error(data.message);
                if (data.success) return this.getRules();
            })
            .catch(err => this.props.setBanner(true, "bad", err.message));
    };
    handleInput = ev => this.setState({[ev.target.name]: ev.target.value});

    render() {
        return (
            <>
                {this.state.loading ? (
                    <Loader/>
                ) : (
                    <ExtendedWrapperFromModalStyles style={{marginTop: "40px", width:"680px"}}>
                        <Title>General Rules</Title>
                        <FormStyles method="POST" onSubmit={this.updateUser}>
                            <div className="options">
                                <textarea
                                    data-testid="rules"
                                    name="stringifiedRules"
                                    value={this.state.stringifiedRules}
                                    onChange={this.handleInput}
                                    style={{height: "500px"}}
                                />
                                <div className="button-wrapper" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "30px" }}>
                                    <button
                                        data-testid="rules-reset"
                                        className="inactive-button"
                                        onClick={this.resetRules}
                                        title="Things went wrong? Reset rules to default"
                                    >
                                        Reset
                                    </button>
                                    <button
                                        data-testid="rules-save"
                                        type="submit"
                                        onClick={this.saveRules}
                                        className="confirm-button"
                                    >
                                        Save options
                                    </button>
                                </div>
                            </div>
                        </FormStyles>
                    </ExtendedWrapperFromModalStyles>
                )}
            </>
        );
    };
}
