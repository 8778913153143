import styled from "styled-components";

const CampaignPageStyles = styled.div``;
const Nav = styled.div`
  font-size: 2rem;
`;
const MenuStyles = styled.div``;

export default CampaignPageStyles;
export { Nav, MenuStyles };
