import React, { Component } from "react";

// import styles

import BannerCreatorRoot from "../styles/BannerCreator";

import BannerCreatorPreview from "./BannerCreatorPreview";
import BannerCreatorSidebar from "./BannerSidebar";
import DefaultLayout from "../DefaultLayout";
import Title from "../styles/TitleStyle";
import ExtendedWrapperFromModalStyles from "../styles/ExtendedWrapperFromModalStyles";

class BannerCreator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dialogs: {
                BannerTextOptions: {
                    visible: false,
                    options: {},
                    onClose: () => {},
                },
            },
        };
    }

    render() {
        return (
            <BannerCreatorRoot>
                <ExtendedWrapperFromModalStyles style={{width: "300px", margin: "0px 40px 0px 0px", boxShadow: "12px 0px 31px -24px #000", position:"relative"}}>
                    <BannerCreatorSidebar extractCSRF={this.props.extractCSRF} />
                </ExtendedWrapperFromModalStyles>

                <ExtendedWrapperFromModalStyles style={{width:"auto", margin:"0", boxShadow: "12px 0px 31px -24px #000"}}>
                    <BannerCreatorPreview extractCSRF={this.props.extractCSRF}/>
                </ExtendedWrapperFromModalStyles>

                <div id="modal-holder"></div>
            </BannerCreatorRoot>
        );
    }
}

export default BannerCreator;
