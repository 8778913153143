import React from "react";
import GooglePageStyles from "./styles/GooglePageStyles";
import Loader from "./styles/Loader";
import Form from "./styles/Form";
import Title from "./styles/TitleStyle";
import ExtendedWrapperFromModalStyles from "./styles/ExtendedWrapperFromModalStyles";
import FormStyles from "./styles/FormStyles";
import FacebookStyles from "./styles/FacebookStyles";

export default class FacebookAdsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            adAccounts: null,
            adCampaigns: null,
            adSets: null,
            activeAdAccount: null,
            activeAdCampaign: null,
            activeAdSet: null,
            adData: {},
            creatingNewAdSet: false,
            projectGroups: [],
            userPages: [],
            newAdSet: {
                name: "my ad set fb target",
                billing_event: "IMPRESSIONS",
                bid_amount: 100,
                daily_budget: 1000,
                targeting: {
                    geo_locations: {countries: ["US"]},
                    publisher_platforms: ["facebook"]
                },
                optimization_goal: "REACH"
            },
            loading: true,
            facebookErrors: null
        };
        this.campaignId = this.props.match.match.params.id;
    }

    componentDidMount() {
        if (
            !this.props.user.facebookAccount ||
            !this.props.user.facebookAccount.name
        ) {
            return;
        }

        this.fetchAccounts();
        this.fetchUserGroups();
        this.fetchUserPages();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user) {
            if (this.props.user.facebookAccount) {
                this.fetchAccounts();
                this.fetchUserPages();
            }
        }

        // if (
        //     this.props.activeCampaign &&
        //     prevProps.activeCampaign !== this.props.activeCampaign
        // ) {
        //     console.log("Different");
        //     this.fetchUserGroups();
        // }
    }

    fetchAccounts = () => {
        this.setState({loading: true});
        const xsrfToken = this.props.extractCSRF();
        return fetch("/getFacebookAdAccounts", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "xsrf-token": xsrfToken
            }
        })
            .then(res => {
                if (res.ok) return res.json();
                throw new Error(res.status + " " + res.statusText);
            })
            .then(data => {
                if (!data.error)
                    return this.setState({adAccounts: data.adAccounts, loading: false});
                if (
                    data.message &&
                    data.message.includes("Error validating access token")
                ) {
                    this.props.setBanner(
                        true,
                        "bad",
                        "Session expired. Please log into Facebook again on your account page.",
                        true
                    );
                    return this.setState({loading: false});
                }
                throw new Error(data.message);
            })
            .catch(err => {
                this.props.setBanner(true, "bad", err.message);
                this.setState({loading: false});
            });
    };
    selectAdAccount = ev => {
        const adAccounts = [...this.state.adAccounts];
        let activeAdAccount = {...this.state.activeAdAccount};

        let accountId = ev.target.options[ev.target.selectedIndex].dataset.id;

        if (!accountId) {
            this.setState({
                activeAdAccount: null,
                adCampaigns: null,
                adSets: null,
                loading: false
            });
            return;
        }

        // get current check if same return
        if (activeAdAccount && activeAdAccount.id === accountId)
            return;
        activeAdAccount = adAccounts.filter(account => {
            return account.id === accountId
        })[0];


        // get campaigns
        this.setState({loading: true});
        const xsrfToken = this.props.extractCSRF();
        fetch("/getFacebookAdCampaigns", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "xsrf-token": xsrfToken
            },
            body: JSON.stringify({
                adAccountId: activeAdAccount.id,
                cid: this.campaignId
            })
        })
            .then(res => {
                if (res.ok) return res.json();
                throw new Error(res.status + " " + res.statusText);
            })
            .then(data => {
                if (data.error) throw new Error(data.message);
                const {adCampaigns} = data;

                if (data.adCampaigns.length > 0) {
                    this.setState({
                        activeAdAccount,
                        adCampaigns,
                        loading: false
                    });
                } else {

                    this.setState({
                        activeAdAccount,
                        adCampaigns: null,
                        adSets: null,
                        loading: false
                    });
                }

            })
            .catch(err => {
                this.setState({loading: false});
                this.props.setBanner(true, "bad", err.message);
            });
    };
    selectAdCampaign = ev => {
        const adCampaigns = [...this.state.adCampaigns];
        let activeAdCampaign = {...this.state.activeAdCampaign};

        let campaignId = ev.target.options[ev.target.selectedIndex].dataset.id;

        if (!campaignId) {
            this.setState({
                activeAdCampaign: null,
                adSets: null,
            });

            return;
        }

        // get current check if same return
        if (activeAdCampaign && activeAdCampaign.id === campaignId) {
            return;
        }

        activeAdCampaign = adCampaigns.filter(
            campaign => campaign.id === campaignId
        )[0];

        this.setState({activeAdCampaign, loading: true});

        // get Ad sets based on selected campaingn

        const xsrfToken = this.props.extractCSRF();
        fetch("/getFacebookAdSets", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "xsrf-token": xsrfToken
            },
            body: JSON.stringify({
                campaign_id: activeAdCampaign.id
            })
        })
            .then(res => {
                if (res.ok) return res.json();
                throw new Error(res.status + " " + res.statusText);
            })
            .then(data => {
                if (data.error) throw new Error(data.message);

                const {adSets} = data;

                this.setState({
                    adSets,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({loading: false});
                this.props.setBanner(true, "bad", err.message);
            });


    };
    selectAdSet = ev => {
        const adSets = [...this.state.adSets];
        let activeAdSet = {...this.state.activeAdSet};

        let adId = ev.target.options[ev.target.selectedIndex].dataset.id;

        if (adId === "new") {
            this.setState({creatingNewAdSet: true, activeAdSet: null});
        } else {
            // get current check if same return
            if (activeAdSet && activeAdSet.id === adId) {
                return;
            }

            activeAdSet = adSets.filter(adSet => adSet.id === adId)[0];

            this.setState({activeAdSet, creatingNewAdSet: false});
        }


    };
    handleFormInput = ev => {
        if (!ev.target.name) return;
        const [obj, prop] = ev.target.name.split("__");
        if (!prop) return;
        if (obj === "ad") {
            const adData = {...this.state.adData};
            adData[prop] = ev.target.value;
            if (adData[prop] === "null") adData[prop] = null;
            return this.setState({adData});
        } else if (obj === "newAdSet") {
            const newAdSet = {...this.state.newAdSet};
            newAdSet[prop] = ev.target.value;
            return this.setState({newAdSet: newAdSet});
        }
    };
    createAds = ev => {

        ev.preventDefault();

        // return console.log(
        //   "This is disabled as to not mess up API error rates until approval"
        // );

        const adData = {...this.state.adData};
        adData.adCampaignId = this.state.activeAdCampaign
            ? this.state.activeAdCampaign.id
            : null;
        adData.adAccountId = this.state.activeAdAccount.id;
        adData.adSetId = this.state.activeAdSet.id;
        adData.object_url = `${this.state.adData.lp_url || ""}?cid={{CampaignID}}&cn={{BannerID}}&${
            this.state.adData.utm_source
                ? "utm_source=" + this.state.adData.utm_source + "&"
                : ""
        }${
            this.state.adData.utm_medium
                ? "utm_medium=" + this.state.adData.utm_medium + "&"
                : ""
        }${
            this.state.adData.utm_campaign
                ? "utm_campaign=" + this.state.adData.utm_campaign
                : ""
        }${
            this.state.adData.projectGroup ? "-" + this.state.adData.projectGroup : ""
        }-{{ImageName}}`;

        

        const xsrfToken = this.props.extractCSRF();
        this.props.setBanner(true, "good", "Posting images...", true);
        fetch("/createFacebookAds", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "xsrf-token": xsrfToken
            },
            body: JSON.stringify({adData, cid: this.campaignId})
        })
            .then(res => {
                if (res.ok) return res.json();
                throw new Error(res.status + " " + res.statusText);
            })
            .then(data => {
                if (data.error) {
                    if (data.errors) {
                        this.props.setBanner(
                            true,
                            "bad",
                            "Something went wrong. Check error log"
                        );
                        return this.setState({facebookErrors: data.errors});
                    }
                    throw new Error(data.message);
                }
                return this.props.setBanner(true, "good", "All Images posted.");
            })
            .catch(err => this.props.setBanner(true, "bad", err.message));
    };

    createNewAdSet = ev => {
        ev.preventDefault();
        // add campaign id to adset object
        const adSet = {...this.state.newAdSet};
        adSet.campaign_id = this.state.activeAdCampaign.id;
        const xsrfToken = this.props.extractCSRF();
        fetch("/createFacebookAdSet", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "xsrf-token": xsrfToken
            },
            body: JSON.stringify({
                adSet,
                cid: this.campaignId,
                adAccountId: this.state.activeAdAccount.id
            })
        })
            .then(res => {
                if (res.ok) return res.json();
                throw new Error(res.status + " " + res.statusText);
            })
            .then(data => {
                if (!data.error)
                    return this.props.setBanner(true, "good", "Success. Ad Set created");
                throw new Error(data.message);
            })
            .catch(err => this.props.setBanner(true, "bad", err.message));
    };
    fetchUserGroups = () => {
        if (!this.campaignId)
            return this.props.setBanner(
                true,
                "bad",
                "Please select a campaign",
                true
            );
        const xsrfToken = this.props.extractCSRF();
        return fetch("/getProjectGroups", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "xsrf-token": xsrfToken
            },
            body: JSON.stringify({cid: this.campaignId})
        })
            .then(res => {
                if (res.ok) return res.json();
                throw new Error(res.status + ": " + res.statusText);
            })
            .then(data => {
                if (data.error) return this.props.setBanner(true, "bad", data.message);
                return this.setState({projectGroups: data.groups});
            })
            .catch(err => this.props.setBanner(true, "bad", err.message));
    };
    fetchUserPages = () => {
        const xsrfToken = this.props.extractCSRF();
        fetch("/getFacebookUserPages", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",

                "xsrf-token": xsrfToken
            }
        })
            .then(res => {
                if (res.ok) return res.json();

                throw new Error(res.status + " " + res.statusText);
            })
            .then(data => this.setState({userPages: data.accounts}))
            .catch(err => this.props.setBanner(true, "bad", err.message));
    };
    dismissErrors = () => {
        this.setState({facebookErrors: []});
    };

    render() {
        return (
            <>
                {this.state.loading && <Loader/>}
                <FacebookStyles>
                    <ExtendedWrapperFromModalStyles style={{width: "900px", marginTop: "40px", marginBottom: "40px"}}>
                        <Title>Generate Ad Set</Title>
                        {!this.campaignId || !this.props.user ? (
                            <h2>Select a campaign and log into Facebook.</h2>
                        ) : (
                            <>
                                <FormStyles className="accounts">
                                    <div className="listContainer adAccounts">
                                        <h3>Ad Accounts</h3>
                                        <select onChange={this.selectAdAccount}>
                                            <option>Select Ad Account</option>
                                            {this.state.adAccounts
                                                ? this.state.adAccounts.map((account, i) => (
                                                    <option
                                                        key={i}
                                                        className={`adAccountNameListItem ${
                                                            this.state.activeAdAccount &&
                                                            account.id === this.state.activeAdAccount.id
                                                                ? "selected"
                                                                : ""
                                                        }`}
                                                        data-id={account.id}
                                                    >
                                                        {account.name}
                                                    </option>
                                                ))
                                                : ""}

                                        </select>
                                    </div>
                                    <div className="listContainer adCampaigns">
                                        <h3>Campaigns</h3>
                                        {!this.state.adCampaigns ? (
                                            ""
                                        ) : (
                                            <select onChange={this.selectAdCampaign}
                                                    data-testid="campaigns-list"
                                                    className="adAccountNameList"
                                            >
                                                <option>Select Campaign</option>
                                                {this.state.adCampaigns
                                                    ? this.state.adCampaigns.map((campaign, i) => (
                                                        <option
                                                            key={i}
                                                            className={`adAccountNameListItem ${
                                                                this.state.activeAdCampaign &&
                                                                campaign.id === this.state.activeAdCampaign.id
                                                                    ? "selected"
                                                                    : ""
                                                            }`}
                                                            data-id={campaign.id}
                                                        >
                                                            {campaign.name}
                                                        </option>
                                                    ))
                                                    : ""}
                                            </select>
                                        )}
                                    </div>
                                    <div className="listContainer adSets">
                                        <h3>Ad Sets</h3>
                                        {!this.state.adSets ? (
                                            ""
                                        ) : (
                                            <select onChange={this.selectAdSet} data-testid="adsets-list" className="adAccountNameList">
                                                {this.state.activeAdCampaign ? (
                                                    <>
                                                        <option>Select Ad Set</option>
                                                        <option
                                                            className="adAccountNameListItem"
                                                            data-id="new"
                                                        >
                                                            Create new Ad Set +
                                                        </option>
                                                    </>
                                                ) : null}
                                                {this.state.adSets
                                                    ? this.state.adSets.map((adSet, i) => (
                                                        <option
                                                            key={i}
                                                            className={`adAccountNameListItem ${
                                                                this.state.activeAdSet &&
                                                                adSet.id === this.state.activeAdSet.id
                                                                    ? "selected"
                                                                    : ""
                                                            }`}
                                                            data-id={adSet.id}
                                                        >
                                                            {adSet.name}
                                                        </option>
                                                    ))
                                                    : ""}
                                            </select>
                                        )}
                                    </div>
                                </FormStyles>
                                {this.state.creatingNewAdSet ? (
                                    <div>
                                        <h3>Create a new Ad Set</h3>
                                        <FormStyles
                                            data-testid="adsets-new"
                                            onSubmit={this.createNewAdSet}
                                        >
                                            <div className="group" style={{display:"flex"}}>
                                                <div style={{width: "33.33%", paddingRight:"20px", boxSizing:"border-box"}}>
                                                    <label>Name:</label>
                                                    <input
                                                        type="text"
                                                        name="newAdSet__name"
                                                        value={this.state.newAdSet.name || ""}
                                                        onChange={this.handleFormInput}
                                                    />
                                                </div>

                                                <div style={{width: "33.33%", paddingRight:"20px", boxSizing:"border-box"}}>
                                                    <label>Bid amount:</label>
                                                    <input
                                                        onChange={this.handleFormInput}
                                                        type="number"
                                                        name="newAdSet__bid_amount"
                                                        value={this.state.newAdSet.bid_amount || 0}
                                                    />
                                                </div>

                                                <div style={{width: "33.33%"}}>
                                                    <label>Daily budget:</label>
                                                    <input
                                                        onChange={this.handleFormInput}
                                                        type="number"
                                                        name="newAdSet__daily_budget"
                                                        value={this.state.newAdSet.daily_budget || 0}
                                                    />
                                                </div>
                                            </div>
                                            <p style={{margin:"20px 0 0"}}><b>Campaign:</b> {this.state.activeAdCampaign.name}</p>
                                            <p style={{margin:"0"}}>
                                                <b>Targeting:</b>{" "}
                                                {JSON.stringify(
                                                    this.state.newAdSet.targeting,
                                                    null,
                                                    2
                                                )}
                                            </p>
                                            <p style={{margin:"0"}}>
                                                <b>Billing event:</b> {this.state.newAdSet.billing_event}
                                            </p>
                                            <p style={{margin:"0"}}>
                                                <b>Optimization Goal:</b>{" "}
                                                {this.state.newAdSet.optimization_goal}
                                            </p>

                                            <div className="button-wrapper" style={{marginTop: "40px"}}>
                                                <button type="submit" className="confirm-button">Create new Ad Set</button>
                                            </div>
                                        </FormStyles>
                                    </div>
                                ) : null}
                                {!this.state.activeAdSet ? (
                                    ""
                                ) : (
                                    <div>
                                        <h3>Create a new Ad</h3>
                                        <FormStyles data-testid="ad-new" onSubmit={this.createAds}>
                                            <div className="group" style={{display:"flex"}}>
                                                <div style={{width: "50%", paddingRight:"20px", boxSizing:"border-box"}}>
                                                    <label>Ad Name<small>*Only visible on Facebook Dashboard</small></label>
                                                    <input
                                                        required
                                                        className="formInput"
                                                        type="text"
                                                        name="ad__name"
                                                        value={"{{ImageName}}"}
                                                        disabled
                                                    />
                                                </div>

                                                <div style={{width: "50%"}}>
                                                    <label>FINAL URL</label>
                                                    <input
                                                        required
                                                        className="formInput"
                                                        type="text"
                                                        name="ad__object_url"
                                                        readOnly={true}
                                                        value={`${this.state.adData.lp_url ||
                                                        ""}?cid={{CampaignID}}&cn={{BannerID}}&${
                                                            this.state.adData.utm_source
                                                                ? "utm_source=" +
                                                                this.state.adData.utm_source +
                                                                "&"
                                                                : ""
                                                        }${
                                                            this.state.adData.utm_medium
                                                                ? "utm_medium=" +
                                                                this.state.adData.utm_medium +
                                                                "&"
                                                                : ""
                                                        }${
                                                            this.state.adData.utm_campaign
                                                                ? "utm_campaign=" +
                                                                this.state.adData.utm_campaign
                                                                : ""
                                                        }${
                                                            this.state.adData.projectGroup
                                                                ? "-" + this.state.adData.projectGroup
                                                                : ""
                                                        }-{{ImageName}}`}
                                                    />
                                                </div>
                                            </div>

                                            <div className="group" style={{display:"flex"}}>
                                                <div style={{width: "33.33%", paddingRight:"20px", boxSizing:"border-box"}}>
                                                    <label>Ad Title</label>
                                                    <input
                                                        required
                                                        className="formInput"
                                                        type="text"
                                                        name="ad__title"
                                                        onChange={this.handleFormInput}
                                                    />
                                                </div>
                                                <div style={{width: "33.33%", paddingRight:"20px", boxSizing:"border-box"}}>
                                                    <label>
                                                        Ad body
                                                    </label>
                                                    <input
                                                        required
                                                        className="formInput"
                                                        type="text"
                                                        name="ad__body"
                                                        onChange={this.handleFormInput}
                                                    />
                                                </div>
                                                <div style={{width: "33.33%"}}>
                                                    <label>Ad Description</label>
                                                    <input
                                                        required
                                                        className="formInput"
                                                        type="text"
                                                        name="ad__description"
                                                        onChange={this.handleFormInput}
                                                    />
                                                </div>
                                            </div>
                                            <div className="group" style={{display:"flex"}}>
                                                <div style={{width: "33.33%", paddingRight:"20px", boxSizing:"border-box"}}>
                                                    <label>UTM Source</label>
                                                    <input
                                                        required
                                                        className="formInput"
                                                        type="text"
                                                        name="ad__utm_source"
                                                        onChange={this.handleFormInput}
                                                    />
                                                </div>
                                                <div style={{width: "33.33%", paddingRight:"20px", boxSizing:"border-box"}}>
                                                    <label>UTM Medium</label>
                                                    <input
                                                        required
                                                        className="formInput"
                                                        type="text"
                                                        name="ad__utm_medium"
                                                        onChange={this.handleFormInput}
                                                    />
                                                </div>
                                                <div style={{width: "33.33%"}}>
                                                    <label>UTM Campaign</label>
                                                    <input
                                                        required
                                                        className="formInput"
                                                        type="text"
                                                        name="ad__utm_campaign"
                                                        onChange={this.handleFormInput}
                                                    />
                                                </div>
                                            </div>
                                            <div className="group" style={{display:"flex"}}>
                                                <div style={{width: "50%"}}>
                                                    <label>
                                                        Landing URL
                                                    </label>
                                                    <input
                                                        required
                                                        className="formInput"
                                                        type="text"
                                                        name="ad__lp_url"
                                                        onChange={this.handleFormInput}
                                                    />
                                                </div>
                                            </div>
                                            <div className="group" style={{display:"flex"}}>
                                                <div style={{width: "50%", paddingRight:"20px", boxSizing:"border-box"}}>
                                                    <label>Associated Pages</label>
                                                    <select
                                                        defaultValue="null"
                                                        onChange={this.handleFormInput}
                                                        name="ad__page_id"
                                                        data-testid="ad-fbpages"
                                                    >
                                                        <option value="null">None</option>
                                                        {this.state.userPages.map((page, i) => (
                                                            <option value={page.id} key={i}>
                                                                {page.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div style={{width: "50%"}}>
                                                    <label>Select an image group</label>
                                                    <select
                                                        data-testid="ad-imagegroup"
                                                        defaultValue="null"
                                                        onChange={this.handleFormInput}
                                                        name="ad__projectGroup"
                                                    >
                                                        <option value="null">None</option>
                                                        <option value="__allimages">All Images</option>
                                                        {this.state.projectGroups.map((group, i) => (
                                                            <option value={group.name} key={i}>
                                                                {group.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="button-wrapper" style={{marginTop: "40px"}}>
                                                <button type="submit" className="confirm-button" onChange={this.handleFormInput}>Create ad</button>
                                            </div>
                                        </FormStyles>
                                    </div>
                                )}
                                {this.state.facebookErrors && this.state.facebookErrors.length ? (
                                    <div className="listContainer">
                                        <div className="dismissButtonGrouping">
                                            <h3>Error log</h3>
                                            <button
                                                title="Dismiss"
                                                type="button"
                                                onClick={this.dismissErrors}
                                            >
                                                ✖
                                            </button>
                                        </div>
                                        {this.state.facebookErrors.map(error => (
                                            <p>{JSON.stringify(error)}</p>
                                        ))}
                                    </div>
                                ) : null}
                            </>
                        )}
                    </ExtendedWrapperFromModalStyles>
                </FacebookStyles>
            </>
        );
    }
}
