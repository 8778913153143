import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import zxcvbn from "zxcvbn";
import Form from "./styles/Form";
import styled from "styled-components";
import Loader from "./styles/Loader";

const ResetPageStyles = styled.div`
  font-family: "Open Sans";
  font-size: 1.7rem;
  display: flex;
  max-width: 1300px;
  margin: auto;
  align-items: center;
  justify-content: center;
  padding: 1rem 1rem;
  form {
    width: 50%;
  }
`;
class ResetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      success: false,
      password: "",
      confirm: "",
      strength: 0
    };
  }
  componentDidMount() {
    // Check the magic link is actually correct
    const xsrfToken = this.props.extractCSRF();
    const parts = this.props.location.pathname.split("/");
    const resetToken = parts[parts.length - 1];
    fetch("/checkResetToken", {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "xsrf-token": xsrfToken
      },
      body: JSON.stringify({ resetToken })
    })
      .then(res => {
        if (res.ok) return res.json();
        throw new Error(res.status + " " + res.statusText);
      })
      .then(data => {
        this.setState({ success: data.success, loading: false });
        if (data.message) throw new Error(data.message);
      })
      .catch(err => {
        this.setState({ success: false, loading: false });
        this.props.setBanner(true, "bad", err.message);
      });
  }
  handleInput = ev => {
    let strength = this.state.strength;
    if (ev.target.name === "password") strength = zxcvbn(ev.target.value).score;
    this.setState({ [ev.target.name]: ev.target.value, strength });
  };

  submit = ev => {
    ev.preventDefault();
    if (!this.state.password || !this.state.confirm)
      return this.props.setBanner(true, "bad", "Please fill out both fields");
    if (this.state.password !== this.state.confirm)
      return this.props.setBanner(true, "bad", "Passwords do not match");
    if (this.state.password.length < 6)
      return this.props.setBanner(
        true,
        "bad",
        "Password must be at least 6 characters long"
      );
    if (this.state.strength < 2) {
      return this.props.setBanner(true, "bad", "Password is too weak");
    }
    const xsrfToken = this.props.extractCSRF();
    const parts = this.props.location.pathname.split("/");
    const resetToken = parts[parts.length - 1];
    fetch("/finishPasswordReset", {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "xsrf-token": xsrfToken
      },
      body: JSON.stringify({
        resetToken,
        password: this.state.password,
        confirm: this.state.confirm
      })
    })
      .then(res => {
        if (res.ok) return res.json();
        throw new Error(res.status + " " + res.statusText);
      })
      .then(data => {
        if (data.success) {
          this.props.setBanner(
            true,
            "good",
            "Sucess. You'll be redirected to the login page"
          );
          setTimeout(() => this.props.history.push("/"), 2000);
        }
        if (data.message) throw new Error(data.message);
      })
      .catch(err => {
        this.setState({ success: false, loading: false });
        this.props.setBanner(true, "bad", err.message);
      });
  };
  render() {
    return (
      <ResetPageStyles>
        {this.state.loading ? (
          <Loader />
        ) : (
          <>
            {!this.state.success ? (
              <h3>This reset token is invalid or has expired</h3>
            ) : (
              <Form method="POST" onSubmit={this.submit}>
                <h2>Reset password</h2>
                <div className="fancyInput">
                  <input
                    type="password"
                    name="password"
                    onChange={this.handleInput}
                    required
                    value={this.state.password}
                  />
                  <label htmlFor="resetPass_email">Password</label>
                  <span />
                </div>
                <div className="fancyInput">
                  <input
                    type="password"
                    name="confirm"
                    onChange={this.handleInput}
                    value={this.state.confirm}
                    required
                  />
                  <label htmlFor="resetPass_email">Confirm password</label>
                  <span />
                </div>
                <h3 style={{ padding: "0 1rem", margin: 0 }}>
                  {`Strength: ${this.state.strength} / 4 ${
                    this.state.password
                      ? this.state.strength < 2
                        ? "❌"
                        : "✔"
                      : ""
                  }`}
                </h3>
                <button type="submit">Reset</button>
              </Form>
            )}
          </>
        )}
      </ResetPageStyles>
    );
  }
}

export default withRouter(ResetPasswordPage);
