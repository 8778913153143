import styled from "styled-components";

export default styled.div`
  font-family: "Open Sans";
  font-size: 1.7rem;
  text-align:center;
  @media(max-width:700px){
    font-size: 1.2rem;

  }
  .options{
    margin:auto;
    max-width: 1300px;
    text-align:center;
    textarea{
      width: 80%;
      height: 50vh;
      margin:auto;
    }
    button{
      max-width:80%;
      margin:auto;
      &.small{
        width: auto;
      }
    }

  }
  .table{
    max-height: 200px;
    overflow-Y: auto;
  }
  table {
    max-width: 1300px;
    margin: auto;

  }
  td {
    padding: 1rem 1rem;
  }

  input[type="checkbox"] {
    display: none;
    & + label {
      width: 66px;
      height: 2.3rem;
      cursor: pointer;
      border: solid 2px ${props => props.theme.lightGrey};
      display: block;
      position: relative;
      transform: skew(-7deg);
      &:before {
        content: "";
        position: absolute;
        border: solid 2px white;
        left: 0;
        width: 32px;
        height: calc(2.3rem - 4px);
        background: ${props => props.theme.lightGrey};
        transition: all 0.2s ease-out;
      }
    }
  }
  input[type="checkbox"]:checked {
    & + label {
      &:before {
        background: ${props => props.theme.lightBlue};
        left: 30px;
      }
    }
  }
  a,
  button {
    font-family: "Open Sans";
    font-size: 1em;
    font-weight: 600;
    text-transform: uppercase;
    background: white;
    cursor: pointer;
    box-shadow: none;
    padding: 0.5rem 2rem;
    border: none;
    color: ${props => props.theme.black};
    border: solid 2px ${props => props.theme.lightGrey};
    display: flex;
    align-items: center;
    width: 100%;
    transition: all 0.2s ease-out;
    &:hover,
    &:active {
      /* color: white; */
      background: ${props => props.theme.lightGrey};
      border: solid 2px white;
    }
  }
  input,
  textarea,
  select {
    padding: 1rem 1rem;
    font-size: 1em;
    font-family: "Open Sans";
    border: solid 2px ${props => props.theme.lightGrey}
    &:focus {
      outline: 0;
    }
  }

  button[type="submit"],
  input[type="submit"] {
    text-transform: uppercase;
    cursor: pointer;
    color: white;
    display: flex;
    justify-content: center;

    &:disabled {
      background: ${props => props.theme.lightGrey};
    }
    &:enabled {
      background: ${props => props.theme.lightBlue};
    }
    &:hover,
    &:active {
      background: ${props => props.theme.lightBlue};
      &:disabled {
        background: ${props => props.theme.lightGrey};
      }
    }
  }
`;
