import React,{ Component} from 'react';
import ReactDOM from 'react-dom';

import { withStyles } from '@material-ui/core/styles';
import Button from '../../../styles/Button';
import PropTypes from 'prop-types';
import BannerModal from '../../BannerModal';

// text options
import BannerTextOptions from './BannerTextOptions';

import {Dispatcher, Events} from '../../../Events';

class BannerTextButton extends Component {

    constructor(props) {

        super(props);

        this.state = this.defaultState = {
            id: null,
            open: false,
            edit: false,
            title: 'Add text element',
            options: undefined
        };

        Dispatcher.listen(Events.EDIT_BANNER_ELEMENT, this.onEdit.bind(this));

    }

    onEdit = (payload, event) => {

        if(payload.type == 'BannerTextItem'){
            this.setState({ 
                open: true, 
                id: payload.id,
                title: 'Edit text ' + payload.id,
                edit: true,
                options: payload.options 
            });
        }

    }
    
    handleOpen = () => {
        this.setState({...this.defaultState,...{ open:true }});
    }

    handleClose = () => {
        this.setState({open:false});
    }

    render() {
        
        const { state } = this;

        const { 
            handleOpen, 
            handleClose 
        } = this;

        return(     
            <>
                <button className="confirm-button" onClick={handleOpen}>
                    Add Text Element
                </button>

                <BannerModal title={state.title} open={state.open} handleClose={handleClose}>
                    <BannerTextOptions
                        id={state.id}
                        edit={state.edit}
                        options={state.options}
                        closeModal={handleClose}
                    />
                </BannerModal>
                
            </>
        )
    }
}

export default BannerTextButton;