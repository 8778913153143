import styled from "styled-components";
const CombinationsPageStyles = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  font-family: "Open Sans";
  font-size: 1.7rem;
  padding: 1rem 1rem;
  color: ${props => props.theme.black};
  h3 {
    align-self: start;
    border-bottom: solid 2px ${props => props.theme.black};
  }

  h4 {
    transition: all 0.3s ease;
  }
  h4.bigger {
    font-size: 1.2em;
  }

  @media (max-width: 1300px) {
    font-size: 1.5rem;
  }
  .controls {
  }
  .preview {
    padding-left: 2rem;
    iframe {
      width: 100%;
      min-height: 75vh;
      border: none;
    }
  }
  label {
    font-size: 1em;
    font-weight: 400;
    background: ${props => props.theme.lightGrey};
    display: block;
    text-align: center;
    margin-top: 0.5rem;
  }

  input,
  textarea,
  select {
    padding: 1rem 1rem;
    font-size: 1em;
    font-family: "Open Sans";
    border: none;
    width: 100%;
    &:focus {
      outline: 0;
    }
  }

  textarea {
    border: solid 2px ${props => props.theme.lightGrey};
    &:active,
    &:focus {
      outline: solid 2px ${props => props.theme.lightBlue};
    }
  }
  a,
  button {
    font-family: "Open Sans";
    font-size: inherit;
    font-weight: 600;
    text-transform: uppercase;
    background: white;
    cursor: pointer;
    box-shadow: none;
    padding: 1rem 2rem;
    border: none;
    color: ${props => props.theme.black};
    border: solid 2px ${props => props.theme.lightGrey};
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    transition: all 0.2s ease-out;
    &:hover,
    &:active {
      /* color: white; */
      background: ${props => props.theme.lightGrey};
      border: solid 2px white;
    }
  }

  input[type="checkbox"] {
    display: none;
    & + label {
      width: 66px;
      height: 2.3rem;
      cursor: pointer;
      border: solid 2px ${props => props.theme.lightGrey};
      display: block;
      position: relative;
      transform: skew(-7deg);
      &:before {
        content: "";
        position: absolute;
        border: solid 2px white;
        left: 0;
        width: 32px;
        height: calc(2.3rem - 4px);
        background: ${props => props.theme.lightGrey};
        transition: all 0.2s ease-out;
      }
    }
  }

  input[type="checkbox"]:checked {
    & + label {
      &:before {
        background: ${props => props.theme.lightBlue};
        left: 30px;
      }
    }
  }
  button[type="submit"],
  input[type="submit"] {
    text-transform: uppercase;
    cursor: pointer;
    color: white;
    display: flex;
    justify-content: center;
    &:disabled {
      background: ${props => props.theme.lightGrey};
    }
    &:enabled {
      background: ${props => props.theme.lightBlue};
    }
    &:hover,
    &:active {
      background: ${props => props.theme.lightBlue};
      &:disabled {
        background: ${props => props.theme.lightGrey};
      }
    }
  }
  .dangerButton:hover,
  .dangerButton:active {
    color: white;
    background: ${props => props.theme.red};
  }
  input[type="color"] {
    padding: 3px 3px;
    -webkit-appearance: none;
    background-color: white;
    border-color: ${props => props.theme.lightGrey};
    border-width: 2px;
    border-style: solid;
    margin: 0px 5px;
    width: 50%;
    height: 2.7em;
  }
  .addColorButton {
    max-width: 50%;
    margin: 0;
    border-width: 2px;
    justify-content: center;
  }
  .colorRow {
    display: flex;
    margin: 1rem;
    align-items: center;
  }
  .optionBubble {
    font-size: 1em;
    background: green;
    color: white;
    margin: 5px;
    border-radius: 5px;
  }

  .optionsBlock {
    .rulesBox {
      position: relative;
      top: -1rem;
    }
  }
  .rulesBox {
    font-weight: 400;
    font-size: 0.9em;
    border-color: ${props => props.theme.lightGrey};
    border-width: 2px;
    border-style: solid;
    border-top: none;

    button {
      border: none;
      &:focus {
        outline: none;
      }
    }
    .addButton {
      width: auto;
    }
    .visibilityButton {
      margin-top: 0;
    }
    ul {
      list-style: none;
      padding: 1rem 2rem;
      margin: 0;
    }
    li {
      display: flex;
      align-items: baseline;
    }
  }
`;

export default CombinationsPageStyles;
