import {reactLocalStorage} from 'reactjs-localstorage';

class Fonts {

    constructor() {
        this.refresh();
    }

    refresh() {
        this.fonts = {...reactLocalStorage.getObject('fonts'), ...this.fonts};
        return this;
    }

    load(name) {

    //    const self = this;

       let item = this.get(name);

       if(item && item.loaded)
        return name;

       // Mabye it's not loaded
       if(!item)
           item = this.refresh().get(name);

       if(item){
        let fontFace = new FontFace(name, `url(${item.path})`)
        fontFace.load().then(function(loaded_face) {
            document.fonts.add(loaded_face);
            item.loaded = true;
        }).catch(function(error) {
            console.error(error);
        });
       }

       return name;

    }

    get(name){
        return this.fonts[name];
    }

    replace(fonts){
        reactLocalStorage.setObject('fonts', fonts);
    }

    all(loaded) {

        if(loaded){
            let output = [];
            for (const [ name, font ] of Object.entries(this.fonts)) {
                if(font && font.loaded && font.loaded === true){
                    output.push(font);
                }
          }
          return output;
        }

        return this.fonts;
        
    }

}

export default new Fonts;