import styled from "styled-components";

export const ClientDetailsStyle = styled.div`
    .rule-wrapper{
        display: flex;
        align-items: start;
        margin-bottom: 10px;
        
        .rule-key{
            line-height: 22px;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 0.5px;
        }
        
        .rule-value{
            margin-left: 10px;
            flex-grow: 1;
            line-height:20px;
        }
        
        .button-delete{
            margin-top: -5px;
        }
    }
    
    .button-wrapper{
        margin-top:30px;
    }
`;