import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Logo, StyledHeader, StyledNav, SubMenu } from "./styles/HeaderStyles";
import OmniperformLogo from "../images/omniperform-adrian-enache.svg"

const Header = ({ user, logout }) => {
    const [openDropdown, setOpenDropdown] = useState(false);
    const [activeColor, setActiveColor] = useState(false);

    const location = useLocation();

    useEffect(() => {
        if (location.pathname === "/profile" || location.pathname === "/socials") {
            setActiveColor(true);
        } else {
            setActiveColor(false);
        }
    }, [location]);

    return (
        <StyledHeader>
            <Logo data-testid="logo-link">
                <Link to="/"><img src={OmniperformLogo} alt="Omniperfom Logo"/></Link>
            </Logo>
            <StyledNav data-testid="header-nav-list">
                {user ? (
                    <>
                        <NavLink to="/clients">Clients</NavLink>
                        {/*<NavLink to="/campaigns-old">Campaigns</NavLink>*/}
                        <NavLink to="/mixer">Mixer</NavLink>
                        <NavLink to="/banner-creator">Banner Creator</NavLink>
                        <NavLink to="/ai-output">A.I.</NavLink>
                        {user.sudo && <NavLink to="/manage-users">Manage Users</NavLink>}

                        <span
                            className={`submenu-link-wrapper ${activeColor ? "active" : ""}`}
                            style={{ position: "relative" }}
                            to="#"
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                            onMouseEnter={(e) => {
                                setOpenDropdown(true);
                            }}
                            onMouseLeave={(e) => {
                                setOpenDropdown(false);
                            }}
                        >
                            My Profile
                            {user.profileImage &&
                                <span style={{width: "28px", marginLeft: "14px", borderRadius: "50%", height: "28px", background: `url(${user.profileImage}) no-repeat center center`, backgroundSize: "cover"}}/>
                            }
                            {openDropdown && (
                                <SubMenu className="dropdown-menu">
                                    <NavLink className="sublink" to="/profile">
                                        Edit Profile
                                    </NavLink>

                                    {user.sudo && (
                                        <NavLink className="sublink" data-testid="dashboard-link" to="/dashboard">
                                            Settings
                                        </NavLink>
                                    )}

                                    <NavLink className="sublink" to="/log-out" onClick={logout}>
                                        Sign out
                                    </NavLink>
                                </SubMenu>
                            )}
                        </span>
                    </>
                ) : null}
            </StyledNav>
        </StyledHeader>
    );
};
export default Header;
