import React, { Component } from "react";
import { Frame } from "scenejs";

import { Dispatcher, Events } from '../../Events';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

import "../styles.scss";
import FormStyles from "../../styles/FormStyles";

class CampaignPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cid: new URLSearchParams(window.location.search).get("cid") || false,
            clients: [],
            campaigns: [],
            selectedClient: false,
            selectedCampaign: false,
        };
    };


    componentDidMount = () => {
        if (this.state.cid) {
            this.fetchCampaign();
        }
        this.fetchClients();
    };

    fetchClients = async () => {
        try {
            const res = await fetch("/getClients", {
                headers: {
                    "Content-Type": "application/json"
                },
            });
            if (!res.ok) {
                throw new Error(res.statusText);
            }
            const data = await res.json();

            if (!data.error) {
                this.setState({clients: data.clients});
            } else {
                throw new Error(data.message);
            }
        } catch (err) {
            // this.props.setBanner(true, "bad", err.message);
        }
    };

    selectClient = (event) => {
        const selectedIndex = event.target.options.selectedIndex;
        const key = event.target.options[selectedIndex].getAttribute('data-key');

        if(!key){
            this.setState({
                selectedClient: false,
                selectedCampaign: false
            }, () => {
                this.fetchCampaigns();
            });
        } else {
            let selectedClient = this.state.clients.filter((client) => {
                return client._id === key;
            })

            this.setState({
                selectedClient: selectedClient[0],
                selectedCampaign: false
            }, () => {
                this.fetchCampaigns();
            });
        }
    };

    fetchCampaigns = async () => {
        try {
            const res = await fetch("/getClientCampaigns", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "xsrf-token": this.props.extractCSRF(),
                },
                body: JSON.stringify({
                    id: this.state.selectedClient,
                    order: "campaign-first" // create an option how the campaign list should be populated, campaign first || campaign last ...
                }),
            });
            if (!res.ok) throw new Error(res.statusText);
            const data = await res.json();

            if (data.error) {
                throw new Error(data.message);
            }

            this.setState({campaigns: data});
        } catch (err) {
            // this.props.setBanner(true, "bad", err.message);
        }
    };

    fetchCampaign = async () => {
        try {
            const res = await fetch(`/getCampaign/${this.state.cid}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "xsrf-token": this.props.extractCSRF(),
                },
            });
            if (!res.ok) throw new Error(res.statusText);
            const data = await res.json();

            if (data.error) {
                throw new Error(data.message);
            }

            this.setState({
                selectedClient: data.client,
                selectedCampaign: data
            }, () => {
                this.fetchCampaigns();
            });
        } catch (err) {
            // this.props.setBanner(true, "bad", err.message);
        }
    };

    selectCampaign = (event) => {
        
        const selectedIndex = event.target.options.selectedIndex;
        const key = event.target.options[selectedIndex].getAttribute('data-key');

        if(!key){
            this.setState({
                selectedCampaign: false,
            });
        } else {
            let selectedCampaign = this.state.campaigns.filter((campaign) => {
                return campaign._id === key;
            })

            this.setState({
                selectedCampaign: selectedCampaign[0],
            });
        }

        Dispatcher.dispatch({
            event: Events.CAMPAIGN_SELECT,
            payload: {
                id: key,
            },
        });
    }

    sendToMixer = (e) => {
        e.preventDefault();
        Dispatcher.dispatch({
            event: Events.SEND_TO_MIXER,
        });
    }

    render() {
        return (
            <FormStyles style={{position: "absolute", bottom: "40px", width: "244px"}}>
                <div className="group">
                    <label>Select a client</label>
                    <select
                        value={this.state.selectedClient.name}
                        name="key"
                        onChange={this.selectClient}
                        data-testid="rule-key"
                    >
                        <option>Select One</option>
                        {this.state.clients.map((client) => (
                            <option data-testid="option" key={client._id} data-key={client._id} value={client.name}>
                                {client.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="group">
                    <label>Select a campaign</label>
                    <select
                        value={this.state.selectedCampaign.name}
                        name="key"
                        onChange={this.selectCampaign}
                        data-testid="rule-key"
                    >
                        <option>Select One</option>
                        {this.state.campaigns.map((campaign) => (
                            campaign.isCampaignGroup &&
                                <optgroup label={campaign.name}>
                                    {campaign.children.map(child => <option data-testid="option" key={child._id} data-key={child._id} value={child.name}>
                                        {child.name}
                                        </option>
                                    )}
                                </optgroup> ||
                                <option data-testid="option" key={campaign._id} data-key={campaign._id} value={campaign.name}>
                                {campaign.name}
                            </option>    
                        ))}
                    </select>
                </div>
                <button className="confirm-button" onClick={this.sendToMixer}>Send to Mixer</button>
            </FormStyles>
        );
      }
    
}

export default CampaignPicker;



