import styled from "styled-components";

export default styled.div`
  max-width: 1300px;
  padding: 1rem 1rem;
  margin: auto;
  font-family: "Open Sans";
  font-size: 1.7rem;
  
  .pickElementContainer{
    display: flex;
    align-content: center
  }
  .pickElementBtn{
    width: 45px;
    margin-top: 0 !important;
  }
  .inputContainer{
    margin-bottom: 20px;
  }
  .previewElementImage{
    border: 0.5px solid #BDBDBD;
    padding:5px;
    border-radius:5px;
    margin-top: 5px;
  }
  
  .documentation {
    padding: 1rem 1rem;
  }

  .imgPreview {
    max-height: 100px;
    margin: 10px;
    display: block;
    width: auto;
    max-width: 100%;
  }

  .combiContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 20px;
    justify-content: center;
    @media (max-width: 1000px) {
      grid-template-columns: 100%;
    }
  }

  .preview {
    #iframe-container {
      width: 100%;
      max-width: 800px;
      min-width: 400px;
      height: 500px;
      overflow: hidden;
      position: relative;
      iframe {
        width: 100%;
        height: 100%;
        border: none;
        transform-origin: top left;
      }
    }
  }

  .previewLP {
      width: 100%;
      min-height: 85vh;
      border: none;
  }

  .codeContainer {
    padding: 1rem 1rem;
    margin-top: 2rem;
  }

  .code {
    resize: none;
    padding: 1rem 1rem;
    font-size: 1.2rem;
    background: lightgrey;
    margin-top: 2rem;
    cursor: pointer;
    height: 8rem;
  }

  input,
  textarea,
  select {
    padding: 1rem 1rem;
    font-size: 1.7rem;
    font-family: "Open Sans";
    border: solid 1px ${props => props.theme.lightGrey};
    width: 100%;
    &:focus {
      outline: 0;
    }
  }

  button {
    font-family: "Open Sans";
    font-size: 1.7rem;
    font-weight: 600;
    text-transform: uppercase;
    background: white;
    cursor: pointer;
    box-shadow: none;
    padding: 1rem 2rem;
    border: none;
    color: ${props => props.theme.black};
    border: solid 2px ${props => props.theme.lightGrey};
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    transition: all 0.2s ease-out;

    &:hover,
    &:active {
      background: ${props => props.theme.lightGrey};
      border: solid 2px white;
    }
  }

  button[type="submit"],
  input[type="submit"] {
    text-transform: uppercase;
    cursor: pointer;
    color: white;
    display: flex;
    justify-content: center;

    &:disabled {
      background: ${props => props.theme.lightGrey};
    }

    &:enabled {
      background: ${props => props.theme.lightBlue};
    }

    &:hover,
    &:active {
      background: ${props => props.theme.lightBlue};

      &:disabled {
        background: ${props => props.theme.lightGrey};
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
    flex-wrap: wrap;
    align-content: space-evenly;
    justify-content: space-around;
  }

  li {
    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #dfd7d7;
  }

  input,
  label,
  span {
    margin: 0;
  }

  .optionGrouper {
    display: flex;
  }

  .optionName,
  .elementName {
    font-size: 1.3rem;
    font-weight: 600;
    padding-right: 5px;
  }

  .elementName {
    display: block;
    border-bottom: solid 2px ${props => props.theme.lightGrey};
    width: 100%;
    font-size: 1.8rem;
  }

  input[type="checkbox"] {
    display: none;

    & + label {
      width: 50px;
      height: 2.3rem;
      cursor: pointer;
      border: solid 2px ${props => props.theme.lightGrey};
      display: block;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        border: solid 2px white;
        left: 0;
        width: 25px;
        height: calc(2.3rem - 4px);
        background: ${props => props.theme.lightGrey};
        transition: all 0.2s ease-out;
      }
    }
  }

  input[type="checkbox"]:checked {
    & + label {
      &:before {
        background: ${props => props.theme.lightBlue};
        left: 22px;
      }
    }
  }
  .editableSelectContainer {
    .MuiAutocomplete-input{
      border:0 !important;
    }
    .MuiButtonBase-root{
      padding: 3px !important;
      margin-top: 0 !important;
    }
    .MuiInputBase-input{
      padding: 4px !important;
    }
    .MuiAutocomplete-root{
      background: white;
      border: solid 1px ${props => props.theme.lightGrey};
      &:focus {
        outline: 0;
      }
    }
    .MuiInput-underline:before{
      border:none !important;
    }
    .MuiInput-underline:after{
      border:none !important;
    }
  }

  .titleAndSubtitle{
    text-align: center;
    p {
      color: #828282;
      margin-top: 0;
    }
    h1 {
      margin-bottom: 0;
    }
  }
  .noAvailablePreview{
    min-height: 200px;
  }
  .iframeButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom:20px;
    
    button{
      width: 110px;
      padding: 3px;
    }
  }
  .wizardButtons{
    display: flex;
    justify-content: left;
    align-items: center; 
    margin-bottom: 20px;
  }
`;
