import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '../../../styles/Button';
import BannerModal from '../../BannerModal';

import BannerImageOptions from './BannerImageOptions';
import {Dispatcher, Events} from '../../../Events';

class BannerImageButton extends Component {
    
    constructor(props) {

        super(props);

        this.state = this.defaultState = {
            open: false,
            id: null,
            edit: false,
            title: 'Add image element',
            options: undefined
        };

        Dispatcher.listen(Events.EDIT_BANNER_ELEMENT, this.onEdit.bind(this));

    }


    onEdit = (payload, event) => {

        if(payload.type == 'BannerImageItem'){
            this.setState({ 
                open: true, 
                id: payload.id,
                edit: true,
                title: 'Edit image ' + payload.id,
                options: payload.options 
            });
        }

    }
    
    handleOpen = () => {
        this.setState({...this.defaultState,...{ open:true }});
    }

    handleClose = () => {
        this.setState({open:false});
    }
    
    render() {
        
        const { state } = this;

        const { 
            handleOpen, 
            handleClose 
        } = this;

        return(

            <>

                <button className="confirm-button" onClick={handleOpen}>
                    Add Image Element
                </button>

                <BannerModal title={state.title}  open={state.open} handleClose={handleClose}>
                    <BannerImageOptions
                        id={state.id}
                        edit={state.edit}
                        options={state.options}
                        closeModal={handleClose} 
                    />
                </BannerModal>

            </>
        )
    }
}


export default BannerImageButton;