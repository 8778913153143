import styled from "styled-components";
  
export default styled.div`
  max-width: 1600px;
  margin: auto;
  font-family: "Open Sans";
  font-size: 1.3rem;
  line-height: 1.5;
  font-weight: 600;
  color: ${props => props.theme.black};
  padding: 1rem 1rem;
  display: flex;

  .left {
    min-width: 25%;
    max-width: 25%;
  }
  textarea,
  select {
    padding: 1rem 1rem;
    font-size: 1.3rem;
    font-family: "Open Sans";
    border: none;
    width: 100%;
    &:focus {
      outline: 0;
    }
  }

  select {
    border: solid 1px ${props => props.theme.lightGrey};
  }
  a,
  button {
    font-family: "Open Sans";
    font-size: 1.3rem;
    font-weight: 600;
    text-transform: uppercase;
    background: white;
    cursor: pointer;
    box-shadow: none;
    padding: 1rem 2rem;
    border: none;
    color: ${props => props.theme.black};
    border: solid 2px ${props => props.theme.lightGrey};
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    transition: all 0.2s ease-out;
    &:hover,
    &:active {
      /* color: white; */
      background: ${props => props.theme.lightGrey};
      border: solid 2px white;
    }
  }
  span {
    word-wrap: break-word;
  }
  .gallery {
    padding: 20px;
    width: 100%;
    display: flex;
    max-height: 78vh;
    flex-wrap: wrap;
    overflow-y: scroll;
    justify-content: center;
  }

  .galleryHeader {
    width: 100%;
    display: flex;
    justify-content: space-around;
    border: solid 3px rgb(242, 242, 242);
    background: rgb(242, 242, 242);
  }
  .galleryGroupsList {
    list-style: none;
    padding-left: 0;
  }
  .galleryGroup {
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease;
    display: flex;
    justify-content: space-between;
  }
  .galleryGroup.selected {
    transform: translateX(15px);
    padding-left: 10px;
  }
  .galleryGroup.selected::before {
    position: absolute;
    left: -15px;
    display: inline-block;
    content: "";
    width: 0px;
    height: 0px;
    border-left: solid 1rem rgb(82, 186, 213);
    border-right: solid 1rem transparent;
    border-top: solid 1rem transparent;
    border-bottom: solid 1rem transparent;
    background-color: transparent;
  }
  .colorIcon {
    text-align: right;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 25%;
  }
  .photoContainer {
    display: flex;
    align-content: flex-start;
    border: solid 3px rgb(242, 242, 242);
    width: 300px;
    height: 300px;
    margin: 0.5rem;
    position: relative;
  }

  .photo {
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .rejected {
    opacity: 0.6;
    filter: grayscale();
    transform: all 0.1s ease;
    &:hover {
      filter: none;
      opacity: 1;
    }
  }
  .selectCheckbox {
    align-self: flex-start;
    width: 40px;
    position: absolute;
    top: 3px;
    left: 3px;
    fill: rgb(242, 242, 242);
    z-index: 9;
  }
  .modal {
    width: 100%;
    height: 100%;
    background: rgba(72, 72, 72, 0.7);
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .log {
    max-height: 80vh;
    max-width: 25%;
    overflow: auto;
  }
`;
