import React, { Component } from "react";

export default class CampaignsList extends Component {
    render() {
        return (
            <ul
                data-testid="campaign-list"
                className="campaignsList"
                onClick={(ev) => {
                    this.props.setActiveCampaign(ev);
                    this.props.toggleDrawer();
                }}
            >
                {this.props.visibleCampaigns &&
                    this.props.visibleCampaigns.map((campaign) => (
                        <li className={`campaign`} data-id={campaign._id} key={campaign.name}>
                            {campaign.name}
                        </li>
                    ))}
            </ul>
        );
    }
}
