import { MdSettings, MdDelete } from "react-icons/md";
import React from "react";
import clientBg from "../../images/clientBg.jpg";
import formatDate from "../../utils/formatDate";
import { Link } from "react-router-dom";
import clientPlaceholder from "../../images/logo-placeholder.svg"

import { CardInfoWrapper, CardDescription, CardStyle, CardSettings, CardInfo } from "../styles/CardStyle";

const ClientCard = ({ client, openDeleteModal, openEditModal }) => {
    return (
        <>
            <CardStyle>
                <CardSettings img={clientBg}>
                    <div onClick={() => openDeleteModal(client)} className="action-button button-delete">
                        <MdDelete />
                    </div>

                    <div onClick={() => openEditModal(client)} className="action-button button-settings">
                        <MdSettings />
                    </div>
                </CardSettings>

                <CardDescription>
                    <Link to={`/client/${client._id}`}>
                        <img src={client.logo ? client.logo : clientPlaceholder} alt={`${client.name} Logo`} />
                        <h3>{client.name}</h3>
                        <p>{client.notes}</p>
                    </Link>
                </CardDescription>

                <CardInfoWrapper>
                    <CardInfo>
                        <span>
                            Campaigns: <b>{client.campaignCount}</b>
                        </span>
                        <p>
                            Created at: <b>{formatDate(client.created)}</b>
                        </p>
                    </CardInfo>
                </CardInfoWrapper>
            </CardStyle>
        </>
    );
};

export default ClientCard;
