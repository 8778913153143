import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import Loader from "./styles/Loader";
import Processing from "./styles/Processing";
import CombinationsPageStyles from "./styles/CombinationsPageStyles";
import RulesBox from "./RulesBox";

// Helper functions
function hexToRgba(hex, opacity) {
    opacity = isNaN(opacity) ? 100 : opacity;
    hex = hex.replace("#", "");
    if (hex.length === 6) {
        var r = parseInt(hex.substring(0, 2), 16);
        var g = parseInt(hex.substring(2, 4), 16);
        var b = parseInt(hex.substring(4, 6), 16);
    } else {
        var rd = hex.substring(0, 1) + hex.substring(0, 1);
        var gd = hex.substring(1, 2) + hex.substring(1, 2);
        var bd = hex.substring(2, 3) + hex.substring(2, 3);
        r = parseInt(rd, 16);
        g = parseInt(gd, 16);
        b = parseInt(bd, 16);
    }

    return "rgba(" + r + ", " + g + ", " + b + ", " + opacity / 100 + ")";
}

function collectAndCombine(obj) {
    // same function used on server
    // this expects the twice-nested elements structure from state
    let collected = [];
    const firstKeys = Object.keys(obj);
    firstKeys.forEach(firstKey => {
        const primaryObject = obj[firstKey];
        const secondaryKeys = Object.keys(primaryObject);
        secondaryKeys.forEach(secondaryKey => {
            if (secondaryKey === "pickedColor") return;
            const array = primaryObject[secondaryKey];
            // Gets rid of empty array elements but 0 is legit value
            const withoutEmpty = array.filter(element => element || element === 0);
            collected.push(withoutEmpty);
        });
    });

    return collected;
}

function combi(arr) {
    // the actual function that goes through the options and combines them in every possible permutation
    // takes in an array of arrays
    // Ex: combi([ color: [blue, red, green], font-size: [10, 11, 12]) -> spits out [[blue, 10], [blue, 11], [red, 12]...etc]
    const args = arr; // change to combi() and args = arguments if passing arrays directly
    const max = args.length - 1;
    const results = [];

    function helper(arr, index) {
        for (let j = 0, len = args[index].length || 1; j < len; j++) {
            const a = arr.slice(0); // clone the arr
            a.push(args[index][j]);
            if (index === max) {
                results.push(a);
            } else {
                helper(a, index + 1);
            }
        }
    }

    helper([], 0);
    return results;
}

class CombinationsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gotRules: false,
            loading: true,
            elements: {},
            selectedElement: null,
            processStarted: false,
            combinations: 1,
            processing: false,
            progress: 0,
            processingFailed: false,
            seriousError: false,
            seriousErrorMessage: "",
            estimate: null,
            minorErrors: [],
            useContrastChecker: false,
            contrastCheckerPairs: {
                pair1: {element1: "", option1: "", element2: "", option2: ""}
            },
            contrastRatioThreshold: 0,
            overwrite: false
        };
        this.previewRef = React.createRef();
        // Each element can have a file input - we store the names in state, and the actual fiels in this object
        // When state changes via a file input handleFileInput filters this object and adds or removes files for the appropriate element name
        // At the end the object is traversed and the files are added to the final FormData
        // In this way we mix the existing native file inputs that get swapped in and out of DOM with the expected
        // functionality of editing the file name input and having the expected result - deleting some of the attached files
        this.elementFiles = {};
    }

    async componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        this.projectID = params.get("pid");
        this.elementToWait = decodeURIComponent(params.get("wait"));
        if (this.elementToWait === "null") this.elementToWait = null;
        await this.fetchStatus(); // just in case the project is already processing;
        await this.getRulesAndDictionary();
        if (!this.state.processing) {
            if (this.props.location && this.props.location.state && this.props.location.state.overwrite) {
                this.setState({overwrite: this.props.location.state.overwrite})
            }

            if (
                !this.props.location ||
                !this.props.location.state ||
                !this.props.location.state.clientRules
            )
                await this.getClientRules();
            // fallback to avoid an ocasional race condition between page
            // loading things through scripts and us trying to discover elements
            const wait = time => new Promise(resolve => setTimeout(resolve, time));
            if (!this.elementToWait) await wait(3000);

            this.startForceLoadTimeout();
            this.startCheckLoadInterval();
        } else {
            this.setFetchStatusInterval();
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        clearInterval(this.fetchStatusInterval);
        clearTimeout(this.timeout);
    }

    checkLoad = () => {
        const frame = this.previewRef.current.contentDocument;
        this.frame = frame;
        const found = frame.querySelector(this.elementToWait);

        if (found || !this.elementToWait) {
            clearInterval(this.interval);
            clearTimeout(this.timeout);
            this.discoverElementsAndOptions();

            return this.setState({loading: false});
        }
        return;
    };

    getFullElementSelector = domElement => {
        const listOfClasses = domElement.classList.length
            ? `.${[...domElement.classList].join(".")}`
            : "";
        const fullElementSelector = `${domElement.nodeName}${listOfClasses}${
            domElement.id ? `#${domElement.id}` : ""
        }`;
        return fullElementSelector;
    };

    discoverElementsAndOptions = () => {
        const elements = [...this.frame.querySelectorAll(".omni")];
        let stateElements = {};
        elements.forEach((element, i) => {
            // Fill in element with its option keys, and an empty array for each
            //prettier-ignore
            // Use JSON bit to avoid passing references to nested objects/arrays
            const fullElementSelector = this.getFullElementSelector(element);
            stateElements[fullElementSelector] = JSON.parse(
                JSON.stringify(this.rules[element.nodeName] || {})
            );

            // Add existing property to empty array on each option

            const elementInState = stateElements[fullElementSelector];
            const options = Object.keys(elementInState);

            options.forEach(option => {
                let value =
                    window.getComputedStyle(element)[option] ||
                    (element.attributes[option]
                        ? element.attributes[option].value
                            ? element.attributes[option].value
                            : element.attributes[option]
                        : false) ||
                    (element[option]
                        ? element[option].value
                            ? element[option].value
                            : element[option]
                        : "");

                elementInState[option].push(value);
            });
        });

        // Save a copy of all these original options for use when reseting previews
        this.originalOptionValues = JSON.parse(JSON.stringify(stateElements));
        return this.setState({elements: stateElements});
    };
    startCheckLoadInterval = () => {
        this.interval = setInterval(this.checkLoad, 50);
    };
    startForceLoadTimeout = () => {
        this.timeout = setTimeout(() => {
            if (this.interval) {
                clearInterval(this.interval);
            }
            this.setState({loading: false});
            this.props.setBanner(
                true,
                "bad",
                "Waiting for element timed out. Moving on"
            );
        }, 30000);
    };

    getClientRules = () => {
        const xsrfToken = this.props.extractCSRF();
        return fetch("/getclientrules", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "xsrf-token": xsrfToken
            }
        })
            .then(res => {
                if (res.ok) return res.json();
                throw new Error(res.status + " " + res.statusText);
            })
            .then(data => {
                if (data.error) throw new Error(data.message);
                this.clientRules = data.clientRules;
            })
            .catch(err => {
                this.props.setBanner(true, "bad", err.message);
            });
    };
    getRulesAndDictionary = () => {
        if (this.state.gotRules) {
            return;
        }
        const xsrfToken = this.props.extractCSRF();
        return fetch("/getCombinationRulesAndDictionary", {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "xsrf-Token": xsrfToken
            }
        })
            .then(res => {
                if (res.ok) return res.json();
                throw new Error(res.status + " " + res.statusText);
            })
            .then(data => {
                if (data.error) throw new Error(data.message);
                this.setState({gotRules: true});
                this.rules = data.rules;
                this.dictionary = data.dictionary;
                this.propertiesWithColorPicker = data.propertiesWithColorPicker;
                this.propertiesWithFilePicker = data.propertiesWithFilePicker;
                this.propertiesWithRulesBox = data.propertiesWithRulesBox;
            })
            .catch(err => {
                this.setState({gotRules: true});
                this.props.setBanner(true, "bad", err.message);
            });
    };
    startProcess = ev => {
        ev.preventDefault();
        const elements = Object.keys(this.state.elements);
        return this.setState(
            {processStarted: true, selectedElement: elements[0]},
            () => {
                if (elements.length) return this.toggleSelectedElementHighlight;
                return this.props.setBanner(
                    true,
                    "bad",
                    "No omni elements found! Please check index.html and re-upload archive.",
                    true
                );
            }
        );
    };
    calculateCombinations = () => {
        const arrayOfArraysOfOptions = collectAndCombine(this.state.elements);
        const combinations = combi(arrayOfArraysOfOptions);
        return this.setState({combinations: combinations.length || 1});
    };
    addColor = ev => {
        ev.preventDefault();
        const [elementName, option] = ev.target.dataset.determines.split("::");
        const elements = {...this.state.elements};
        const element = elements[elementName];
        const color = hexToRgba(element.pickedColor[0]);
        element[option].push(color);
        return this.setState({elements}, () => {
            this.previewOption({elementName, option, lastValue: color});
            this.calculateCombinations();
        });
    };
    handleInput = ev => {
        const [elementName, option] = ev.target.name.split("::");
        const currentElementsInState = {...this.state.elements};

        const originalValue = ev.target.value;
        //test with regex the string isn't just ;;;;;;;;;;;;; but allow empty values
        if (
            !originalValue ||
            (originalValue && /[^;]/gm.test(originalValue.trim()))
        ) {
            currentElementsInState[elementName][option] = originalValue
                .split(";")
                .map(value => {
                    if (value || value === 0) {
                        return value;
                    }
                    return null;
                });

            // mapped array needs to be cleaned up before posting to server;
        }
        //  Get value for previewing
        const values = currentElementsInState[elementName][option];
        const lastValue = values[values.length - 1];

        return this.setState({elements: currentElementsInState}, () => {
            this.calculateCombinations();
            this.previewOption({elementName, option, lastValue});
            // filter the global elementFiles
            if (this.propertiesWithFilePicker.includes(option)) {
                const fileNamesInState = this.state.elements[elementName][option];
                this.elementFiles[elementName] = this.elementFiles[elementName].filter(
                    existingFile => fileNamesInState.includes(existingFile.name)
                );
            }
        });
    };

    handleSimpleInput = ev => {
        let value = ev.target.value;
        if (ev.target.type === "checkbox") value = ev.target.checked;
        console.log(value);
        this.setState({[ev.target.name]: value});
    };
    handleContrastCheckerInput = ev => {
        const [pairName, target] = ev.target.name.split("::");
        const contrastCheckerPairs = {...this.state.contrastCheckerPairs};
        const pair = {...contrastCheckerPairs[pairName]};
        pair[target] = ev.target.value;
        if (ev.target.value === "null") pair[target] = null;
        console.log(pair);
        contrastCheckerPairs[pairName] = pair;
        return this.setState({contrastCheckerPairs});
    };

    handleFileInput = ev => {
        // State contains the file NAMES
        // this.elementFiles contains the actual files to be added to POST req at the end
        const [elementName, option] = ev.target.name.split("::");
        const currentElementsInState = {...this.state.elements};
        let fileNames = [];
        const files = [...ev.target.files];
        // Push names to element in state
        files.forEach(file => {
            fileNames.push(file.name);
        });
        // Add the names of the newly selected files to state
        currentElementsInState[elementName][option] = [
            ...currentElementsInState[elementName][option],
            ...fileNames
        ];

        // Add/ filter files to the global elementFiles object
        this.elementFiles[elementName] = this.elementFiles[elementName].filter(
            existingFile => fileNames.includes(existingFile.name)
        );
        if (!this.elementFiles[elementName].length)
            this.elementFiles[elementName] = [...files];

        return this.setState(
            {elements: currentElementsInState},
            this.calculateCombinations
        );
    };
    previewOption = info => {
        const {elementName, option, lastValue} = info;
        // Exceptions for some synthetic options
        if (option === "pickedColor") return;
        const elements = [...this.frame.querySelectorAll(".omni")];
        // Get element on page
        const [domElement] = elements.filter((element, i) => {
            const fullElementSelector = this.getFullElementSelector(element);
            return fullElementSelector === elementName;
        });

        // Try to set the option
        // Check specifically for undefined else you'll get trapped by other falsies
        if (domElement.attributes[option] !== undefined) {
            domElement.setAttribute(option, lastValue);
        } else if (domElement[option] !== undefined) {
            domElement[option] = lastValue;
        } else {
            domElement.style[option] = lastValue;
        }
    };
    resetPreview = () => {
        const elementInState = this.state.elements[this.state.selectedElement];
        const options = Object.keys(elementInState);
        // Use the previewer to just set the original values back on each option
        //  We get the original values from the originalOptionValues object saved when first populating
        options.forEach(optionName => {
            // Exceptions for some synthetic options
            if (optionName === "pickedColor") return;
            this.previewOption({
                elementName: this.state.selectedElement,
                option: optionName,
                lastValue: this.originalOptionValues[this.state.selectedElement][
                    optionName
                    ][0]
            });
        });
    };
    restart = () => {
        const confirm = window.confirm(
            "You will LOSE all entered info and revert to the original. Are you sure?"
        );
        if (!confirm) return;
        // Get rid of the previews
        for (let elementName of Object.keys(this.originalOptionValues)) {
            let currentElement = this.originalOptionValues[elementName];
            let pairs = Object.entries(currentElement);
            pairs.forEach(([optionName, optionArray]) => {
                this.previewOption({
                    elementName,
                    option: optionName,
                    lastValue: optionArray[0] || ""
                });
            });
        }
        // Set the saved original values back in state for the inputs
        const originalElementsObject = JSON.parse(
            JSON.stringify(this.originalOptionValues)
        );
        // Empty out the elementFiles object
        this.elementFiles = {};
        return this.setState(
            {
                elements: originalElementsObject,
                combinations: 1,
                selectedElement: Object.keys(originalElementsObject)[0],
                processFinished: false
            },
            this.toggleSelectedElementHighlight
        );
    };
    toggleSelectedElementHighlight = (addColor = true) => {
        const elements = [...this.frame.querySelectorAll(".omni")];
        const [domElement] = elements.filter((element, i) => {
            const fullElementSelector = this.getFullElementSelector(element);
            return fullElementSelector === this.state.selectedElement;
        });
        if (!addColor) {
            if (domElement) {
                domElement.style.outline = 0;
                return;
            }
        }
        if (domElement) domElement.style.outline = "inset 8px #52bad5";
    };
    navigateElementList = (advance = true) => {
        this.toggleSelectedElementHighlight(false);
        const elements = Object.keys(this.state.elements);
        let index = elements.indexOf(this.state.selectedElement);
        // let showContrastChecker;
        if (!advance) {
            if (this.state.processFinished) {
                return this.setState({
                    processFinished: false,
                    showContrastChecker: true
                });
            } else if (this.state.showContrastChecker) {
                return this.setState(
                    {showContrastChecker: false},
                    this.toggleSelectedElementHighlight
                );
            }
            if (index - 1 >= 0) {
                index += -1;
            }
        } else {
            if (index + 1 < elements.length) {
                index += 1;
            } else if (!this.state.showContrastChecker) {
                return this.setState({showContrastChecker: true});
            } else {
                return this.setState({processFinished: true});
            }
        }

        this.setState(
            {
                selectedElement: elements[index]
            },
            this.toggleSelectedElementHighlight
        );
    };
    setFetchStatusInterval = () => {
        this.fetchStatusInterval = setInterval(this.fetchStatus, 3000);
    };
    fetchStatus = () => {
        // TODO make sure this is account and csrf protected
        return fetch(`/check/${this.projectID}`)
            .then(res => {
                if (res.ok) return res.json();
                throw new Error(res.status + res.statusText);
            })
            .then(data => {
                if (data.seriousError) {
                    clearInterval(this.fetchStatusInterval);
                    return this.setState({
                        seriousError: true,
                        seriousErrorMessage: data.message
                    });
                }
                if (data.status.current !== "done") {
                    if (!data.status || !data.status.current) {
                        return;
                    }
                    return this.setState({
                        combinations: data.status.combinations,
                        estimate: data.status.estimate,
                        progress: data.status.progress,
                        minorErrors: [...data.status.errors],
                        processing: true
                    });
                } else {
                    this.props.history.push(data.redirect);
                }
            });
    };
    postOptions = () => {
        // Append options to formdata
        const finalOptions = JSON.stringify(this.state.elements);
        const finalFormData = new FormData();
        finalFormData.append("finalOptions", finalOptions);
        if (this.state.useContrastChecker) {
            finalFormData.append(
                "contrastCheckerPairs",
                JSON.stringify(this.state.contrastCheckerPairs)
            );
            finalFormData.append(
                "contrastThreshold",
                this.state.contrastRatioThreshold || 0
            );
        }
        // Append all the files for the file inputs
        const arrOfArrays = Object.values(this.elementFiles);
        arrOfArrays.forEach(arrOfFiles => {
            if (!arrOfFiles.length) return;
            arrOfFiles.forEach(file => finalFormData.append("photos", file));
        });
        const xsrfToken = this.props.extractCSRF();
        return fetch(`/final-TEST/${this.projectID}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "xsrf-token": xsrfToken
            },
            body: finalFormData
        })
            .then(res => {
                if (res.ok) return res.json();
                throw new Error(res.status + " " + res.statusText);
            })
            .then(data => {
                if (data.success) {
                    return this.setState(
                        {processing: true},
                        this.setFetchStatusInterval
                    );
                } else {
                    throw data.message;
                }
            })
            .catch(err => this.props.setBanner(true, "bad", err.message));
    };
    renderOptions = () => {
        let clientRules = this.clientRules;
        if (
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.clientRules &&
            this.props.location.state.clientRules.length
        )
            clientRules = this.props.location.state.clientRules;

        const {selectedElement, elements} = this.state;
        if (!Object.keys(elements).length || !selectedElement)
            return console.log(elements, selectedElement);
        const options = Object.keys(elements[selectedElement]);
        return options.map(option => {
            if (option === "pickedColor") return null;
            let result = [
                <>
                    <label key={`${selectedElement}::${option}::label`}>
                        {this.dictionary[option]
                            ? this.dictionary[option]
                            : option.toUpperCase()}
                    </label>
                </>
            ];
            // IMAGE UPLOAD
            if (this.propertiesWithFilePicker.includes(option)) {
                // Create or maintain an array of files in the files object for this specific element
                // - used to keep track of selected files to be uploaded
                this.elementFiles[`${selectedElement}`] =
                    this.elementFiles[`${selectedElement}`] || [];
                result.push(
                    <input
                        type="file"
                        multiple={true}
                        accept="image/*"
                        name={`${selectedElement}::${option}`}
                        onChange={this.handleFileInput}
                        key={`${selectedElement}::${option}__input`}
                    />
                );
            }
            // COLOR PICKER
            if (this.propertiesWithColorPicker.includes(option)) {
                result.push(
                    <div className="colorRow" key={`${selectedElement}::pickedColor`}>
                        <input
                            type="color"
                            name={`${selectedElement}::pickedColor`}
                            data-determines={option}
                            value={this.state.elements[selectedElement].pickedColor}
                            onChange={this.handleInput}
                        />{" "}
                        <button
                            className="addColorButton"
                            type="button"
                            onClick={this.addColor}
                            data-determines={`${selectedElement}::${option}`}
                        >
                            Add
                        </button>
                    </div>
                );
            }

            result.push(
                <textarea
                    name={`${selectedElement}::${option}`}
                    value={this.state.elements[selectedElement][option].join(";")}
                    onChange={this.handleInput}
                    key={`${selectedElement}::${option}`}
                />
            );
            // RULES BOX for Brand Guidelines
            if (this.propertiesWithRulesBox.includes(option) && this.clientRules) {
                result.push(
                    <RulesBox
                        determines={`${selectedElement}::${option}`}
                        rules={clientRules}
                        callback={this.handleInput}
                        originalValue={this.state.elements[selectedElement][option].join(
                            ";"
                        )}
                    />
                );
            }
            return result;
        });
    };

    addContrastCheckerPair = () => {
        let number = Object.keys(this.state.contrastCheckerPairs).length + 1;
        const contrastCheckerPairs = {...this.state.contrastCheckerPairs};
        contrastCheckerPairs[`pair${number}`] = {
            element1: "",
            option1: "",
            element2: "",
            option2: ""
        };
        return this.setState({contrastCheckerPairs});
    };
    removeContrastCheckerPair = ev => {
        const pairName = ev.target.dataset.name;
        const contrastCheckerPairs = {...this.state.contrastCheckerPairs};
        delete contrastCheckerPairs[pairName];
        return this.setState({contrastCheckerPairs});
    };
    renderContrastCheckerPairs = () => {
        const toBeRendered = [];
        const pairsObject = this.state.contrastCheckerPairs;
        const elementsNames = Object.keys(this.state.elements);
        //Go through each pair which has 2 elements, each with 1 option
        Object.keys(pairsObject).forEach(pairName => {
            toBeRendered.push(
                <label>
                    {pairName}
                    <span onClick={this.removeContrastCheckerPair} data-name={pairName}>
            ✖
          </span>
                </label>
            );
            const pair = pairsObject[pairName];
            const {element1, element2} = pair;
            let element1NodeName,
                optionsForElement1,
                element2NodeName,
                optionsForElement2;
            if (element1) {
                [element1NodeName] = element1.split(".");
                optionsForElement1 = Object.keys(this.rules[element1NodeName]);
            }
            if (element2) {
                [element2NodeName] = element2.split(".");
                optionsForElement2 = Object.keys(this.rules[element2NodeName]);
            }

            // Go through both elements of the pair
            toBeRendered.push(
                <>
                    {/* Render the element select */}
                    <select
                        name={`${pairName}::element1`}
                        onChange={this.handleContrastCheckerInput}
                        defaultValue="null"
                    >
                        <option value="null">Select first element</option>
                        {elementsNames.map(elementName => (
                            <option value={elementName}>{elementName}</option>
                        ))}
                    </select>
                    {/* Render the option select if there's an element selected */}
                    {element1 ? (
                        <select
                            name={`${pairName}::option1`}
                            defaultValue="null"
                            onChange={this.handleContrastCheckerInput}
                        >
                            <option value="null">Select option</option>
                            {optionsForElement1.map(option => {
                                if (this.propertiesWithColorPicker.includes(option))
                                    return (
                                        <option value={option}>{this.dictionary[option]}</option>
                                    );
                            })}
                        </select>
                    ) : null}

                    {/* Render the exact same thing for the second element */}
                    <select
                        name={`${pairName}::element2`}
                        onChange={this.handleContrastCheckerInput}
                        defaultValue="null"
                    >
                        <option value="null">Select second element</option>
                        {elementsNames.map(elementName => (
                            <option value={elementName}>{elementName}</option>
                        ))}
                    </select>
                    {/* Render the option select if there's an element selected */}
                    {element2 ? (
                        <select
                            name={`${pairName}::option2`}
                            defaultValue="null"
                            onChange={this.handleContrastCheckerInput}
                        >
                            <option value="null">Select option</option>
                            {optionsForElement2.map(option => {
                                if (this.propertiesWithColorPicker.includes(option))
                                    return (
                                        <option value={option}>{this.dictionary[option]}</option>
                                    );
                            })}
                        </select>
                    ) : null}
                </>
            );
        });
        toBeRendered.push(
            <button onClick={this.addContrastCheckerPair}>Add another pair</button>
        );
        return toBeRendered;
    };

    render() {
        return (
            <CombinationsPageStyles>
                <>
                    <div className="controls">
                        <h3>
                            {!this.state.processStarted
                                ? "Ready to start"
                                : this.state.processFinished
                                    ? "Ready to mix"
                                    : `Selected: ${this.state.selectedElement}`}
                        </h3>
                        <h4 className={this.state.processFinished ? "bigger" : ""}>
                            Combinations: {this.state.combinations}{" "}
                        </h4>
                        <h4 className={this.state.processFinished ? "bigger" : ""}>
                            Files to upload:{" "}
                            {Object.values(this.elementFiles).reduce(
                                (acc, cur) => (acc += cur.length),
                                0
                            )}{" "}
                        </h4>

                        {!this.state.processStarted ? (
                            <>
                                {!this.state.processing ? (
                                    <button
                                        disabled={this.state.loading}
                                        onClick={this.startProcess}
                                    >
                                        Start
                                    </button>
                                ) : null}
                            </>
                        ) : (
                            <>
                                {this.state.processFinished ? (
                                    !this.state.processing && (
                                        <button
                                            type="submit"
                                            className="controlButton"
                                            onClick={this.postOptions}
                                        >
                                            Start Mixing{" "}
                                        </button>
                                    )
                                ) : (
                                    <button
                                        className="controlButton"
                                        onClick={() => this.navigateElementList(true)}
                                    >
                                        Next ➡
                                    </button>
                                )}
                                {!this.state.processing && (
                                    <>
                                        <button
                                            className="controlButton"
                                            onClick={() => this.navigateElementList(false)}
                                        >
                                            ⬅ Prev
                                        </button>

                                        <button
                                            onClick={this.resetPreview}
                                            title="Resets the preview only"
                                        >
                                            Reset element preview
                                        </button>
                                        <button
                                            className="dangerButton"
                                            title="Go back to original values"
                                            onClick={this.restart}
                                        >
                                            Restart
                                        </button>
                                    </>
                                )}
                                {this.state.showContrastChecker &&
                                !this.state.processing &&
                                !this.state.processFinished && (
                                    <>
                                        <label>Color Contrast Checker</label>
                                        <div>
                                            Use checker:
                                            <input
                                                id="useChecker"
                                                type="checkbox"
                                                value={this.state.useContrastChecker}
                                                name="useContrastChecker"
                                                onChange={this.handleSimpleInput}
                                            />
                                            <label htmlFor="useChecker"/>
                                        </div>
                                        {this.state.useContrastChecker &&
                                        !this.state.processing &&
                                        !this.state.processFinished && (
                                            <>
                                                <div>{this.renderContrastCheckerPairs()}</div>
                                            </>
                                        )}
                                    </>
                                )}
                                {!this.state.processFinished &&
                                !this.state.showContrastChecker && (
                                    <div className="optionsBlock">{this.renderOptions()}</div>
                                )}
                            </>
                        )}
                    </div>
                    <div className="preview">
                        <h3>Preview</h3>
                        {this.state.processing ? (
                            <>
                                <Processing
                                    done={`${this.state.progress}`}
                                    failed={this.state.processingFailed}
                                />
                                <h4>
                                    Estimated finish time:{" "}
                                    {this.state.estimate
                                        ? new Date(this.state.estimate).toString().slice(0, 25)
                                        : "calculating..."}
                                </h4>
                                {this.state.minorErrors.length ? (
                                    <div>
                                        <h3>Minor errors</h3>
                                        <h4>
                                            Minor errors will let the processing go on but not all
                                            banners may be usable
                                        </h4>
                                        <ul>
                                            {this.state.minorErrors.map(error => (
                                                <li>{error}</li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : null}
                            </>
                        ) : (
                            <>
                                {this.state.loading && <Loader/>}
                                <iframe
                                    src={`${this.projectID}/index.html`}
                                    ref={this.previewRef}
                                />
                            </>
                        )}
                    </div>
                </>
            </CombinationsPageStyles>
        );
    }
}

export default withRouter(CombinationsPage);
