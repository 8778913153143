import styled from "styled-components";
import { Link } from "react-router-dom";

export const CardStyle = styled.div`
    margin: 0 3rem 3rem 0;
    // margin-right: 2rem;
    // margin-bottom: 2rem;
    overflow: hidden;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    // min-height: 400px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    // width: 100%;
    // flex: 0 0 calc(25% - 2rem);
    // max-width: calc(25% - 2rem);
    width: 350px;

    .cardTitle {
        width: 220px;
        line-height: 30px;
        text-align: center;
        color: rgb(0, 0, 0);
        background: rgba(255, 255, 255, 0.8);
        padding: 8px 15px;
        border-radius: 15px;
        border: 1px solid rgb(224, 224, 224);
        margin: -10px auto 0px;
        display: block;
        word-break: break-word;
        margin-top: -10px;
        margin-bottom: 22px;
    }

`;

export const CardSettings = styled.div`
    height: 150px;
    width: 100%;
    max-height: 120px;
    text-align: right;
    padding: 1rem;
    // background-size: cover;
    background-size: 200px;
    background-position: center center;
    background-image: url(${(props) => props.img});
`;

export const CardDescription = styled.div`
    padding: 0 2rem 2rem;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-left: 0;
    border-right: 0;
    width: 100%;
    text-align: center;
    
    img {
        margin-top: -50px;
        width: 140px;
        height: 90px;
        object-fit: contain;
        border-radius: 15px;
        border: 2px solid #fff;
        background-color: #fff;
    }

    p {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: 81px;
    }

    h3,
    p {
        color: #000;
        margin: 0;
    }
`;

export const CardInfoWrapper = styled.div`
    display: flex;
    justify-content: center;
    color: #000;
    text-align: center;
    width: 100%;
    background: #f3f3f3;
`;

export const CardInfo = styled.div`
    // margin-top: 1.5rem;
    padding: 3px 7px;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    h4,
    p {
        margin: 0;
    }
`;
