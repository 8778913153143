import styled from "styled-components";

export default styled.div`
  max-width: 1600px;
  margin: auto;
  font-size: 1.5rem;
  font-family: "Open Sans";
  color: ${props => props.theme.black};
  padding: 1rem 1rem;
  transition: all 0.2s ease;
  &.loading {
    transform: translateY(2.7rem);
  }
  .accounts {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 20px;
    display: grid;
    max-height: 30vh;
    overflow-y: auto;
    @media (max-width: 700px) {
      grid-template-columns: 100%;
    }
  }
  label,
  h3 {
    font-size: 1.7rem;
    font-weight: 600;
    text-transform: uppercase;
    border-bottom: solid 2px ${props => props.theme.black};
    width: 100%;
    display: block;
  }

  ul {
    list-style: none;
    padding: 1rem 1rem;
    margin: 0;
  }
  li {
    font-size: 1.2em;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease-out;
  }
  li.selected {
    transform: translateX(1.5em);
    &:before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      left: -1.5em;
      top: 0;
      border-left: solid 1em ${props => props.theme.lightGrey};
      border-right: solid 1em transparent;
      border-top: solid 1em transparent;
      border-bottom: solid 1em transparent;
    }
  }
  a,
  button {
    font-family: "Open Sans";
    font-size: 1.7rem;
    font-weight: 600;
    text-transform: uppercase;
    background: white;
    cursor: pointer;
    box-shadow: none;
    padding: 1rem 2rem;
    border: none;
    color: ${props => props.theme.black};
    border: solid 2px ${props => props.theme.lightGrey};
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    transition: all 0.2s ease-out;
    &:hover,
    &:active {
      /* color: white; */
      background: ${props => props.theme.lightGrey};
      border: solid 2px white;
    }
  }
  .dismissButtonGrouping {
    position: relative;
    button {
      position: absolute;
      right: 0;
      top: 5px;
      width: min-content;
      border: none;
      margin: 0;
      padding: 0;
      color: ${props => props.theme.black};
    }
  }
  .workingSpace {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 20px;

    label {
      border-bottom: solid 2px ${props => props.theme.lightGrey};
    }
    @media (max-width: 700px) {
      grid-template-columns: 100%;
    }
  }
`;
