import React, { useContext, useEffect, useState } from "react";
import DefaultLayout from "./DefaultLayout";
import Title from "./styles/TitleStyle";
import { GeneralModalContext } from "./GeneralModal";
import CampaignDetails from "./Campaigns/CampaignDetails";
import ShareCampaign from "./Campaigns/ShareCampaign";
import { ClientSearchBarStyle } from "./styles/ClientSearchBarStyle";
import {MdClose, MdSearch} from "react-icons/md";
import CampaignCard from "./Campaigns/CampaignCard";
import DeleteModal from "./Modals/DeleteModal";
import {Link} from "react-router-dom";
import {IoArrowBack} from "react-icons/all";
import GeneralModalStyles from "./styles/GeneralModalStyles";

const style = {
    display: "flex",
    flexWrap: "wrap",
    overflowY: "auto",
    margin: 0,
    padding: 0,
    marginRight: "-3rem"
};

export const CampaignSearchBar = ({ handleSearchCampaignInput, openCampaignModal, campaign = null, isCampaignGroup = false }) => {
    return (
        <ClientSearchBarStyle style={{ marginBottom: "4rem", textAlign: "right" }}>
            <span className="search-client-wrapper">
                <MdSearch />
                <input onChange={e => handleSearchCampaignInput(e)} id="search-client" type="text" placeholder="Search Client" />
            </span>
            <div className="filter-add-client">
                <button className="confirm-button" onClick={() => openCampaignModal(campaign)}>
                    Add Campaign
                </button>
                {!isCampaignGroup && <button className="confirm-button" onClick={()=>openCampaignModal(campaign, true)} style={{ marginLeft: 10 }}>
                    Add Group Campaign
                </button>}
                
            </div>
        </ClientSearchBarStyle>
    )
}


const CampaignsMain = ({ match, extractCSRF, setBanner, user, setActiveCampaign }) => {

    const [campaigns, setCampaigns] = useState([]);
    const [visibleCampaigns, setVisibleCampaigns] = useState([]);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [loadingCampaigns, setLoadingCampaigns] = useState(true);
    const [isCampaignGroup, setIsCampaignGroup] = useState(false);

    const [modalType, setModalType] = useState();

    const { showModal, handleShowModal, handleCloseModal } = useContext(GeneralModalContext);

    const clientId = match.params.id;

    useEffect(() => {
        fetchCampaigns();
    }, []);

    const fetchCampaigns = async () => {
        
        setLoadingCampaigns(true);

        try {
            const res = await fetch("/getClientCampaigns", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "xsrf-token": extractCSRF(),
                },
                body: JSON.stringify({ id: clientId, parent: null }),
            });
            if (!res.ok) throw new Error(res.statusText);
            const data = await res.json();

            if (data.error) {
                throw new Error(data.message);
            }

            setCampaigns(data);
            setVisibleCampaigns(data);
            setLoadingCampaigns(false);


        } catch (err) {
            setLoadingCampaigns(false);
            setBanner(true, "bad", err.message);
        }
    };

    const deleteCampaign = (ev) => {
        const campaign = { ...selectedCampaign };
        fetch("/campaign", {
            method: "delete",
            headers: {
                "Content-Type": "application/json",
                "xsrf-token": extractCSRF(),
            },
            body: JSON.stringify({ campaign }),
        })
            .then((res) => {
                if (res.ok) return res.json();
                throw new Error(res.status + " " + res.statusText);
            })
            .then((data) => {
                if (data.error) throw new Error(data.message);

                let oldCampaigns = [...campaigns];
                oldCampaigns = oldCampaigns.filter((current) => {
                    if (current._id === campaign._id) return false;
                    return current;
                });

                handleCloseModal();

                setCampaigns(oldCampaigns);
                setVisibleCampaigns(oldCampaigns);
                setSelectedCampaign(null);
            })
            .catch((err) => setBanner(true, "bad", err.message, false));
    };

    const updateCampaign = (updatedCampaign) => {
        let newCampaigns = [...campaigns];
        let found = false;

        newCampaigns = newCampaigns.map((current) => {
            if (current._id === updatedCampaign._id) {
                current = updatedCampaign;
                found = true;
            }
            return current;
        });
        if (!found) {
            newCampaigns.unshift(updatedCampaign);
        }

        setCampaigns(newCampaigns);
        setVisibleCampaigns(newCampaigns);
        setSelectedCampaign(null);
    };

    const handleSearchCampaignInput = (ev) => {
        const txt = ev.target.value.toLowerCase();
        const newCampaigns = [...campaigns];

        const visibleCampaigns = newCampaigns.filter((client) => {
            if ((client.name && client.name.toLowerCase().includes(txt)) || (client.notes && client.notes.toLowerCase().includes(txt))) {
                return client;
            }
        });

        setVisibleCampaigns(visibleCampaigns);
    };

    const openCampaignModal = (campaign, isGroup = false) => {
        setIsCampaignGroup(isGroup);
        setSelectedCampaign(campaign);
        setModalType("campaign-modal");
        handleShowModal();
    };

    const openShareModal = (campaign) => {
        setSelectedCampaign(campaign);
        setModalType("share-modal");
        handleShowModal();
    };

    const openDeleteModal = (campaign) => {
        setSelectedCampaign(campaign);
        setModalType("delete-campaign");
        handleShowModal();
    };

    const openNotesModal = (campaign) => {
        setSelectedCampaign(campaign);
        setModalType("show-notes");
        handleShowModal();
    };

    let shareModal, editModal, DeleteModalUx, ShowNotesModal;

    if (showModal && modalType === "campaign-modal") {
        editModal = (
            <CampaignDetails
                extractCSRF={extractCSRF}
                selectedCampaign={selectedCampaign}
                handleCloseModal={handleCloseModal}
                updateCampaign={updateCampaign}
                setBanner={setBanner}
                showModal={showModal}
                clientId={clientId}
                userId={user._id}
                isCampaignGroup={selectedCampaign ? selectedCampaign.isCampaignGroup : isCampaignGroup}
            />
        );
    }

    if (showModal && modalType === "share-modal") {
        shareModal = (
            <ShareCampaign
                extractCSRF={extractCSRF}
                selectedCampaign={selectedCampaign}
                handleCloseModal={handleCloseModal}
                showModal={showModal}
                setBanner={setBanner}
                updateCampaign={updateCampaign}
            />
        );
    }

    if (showModal && modalType === "delete-campaign") {
        DeleteModalUx = (
            <DeleteModal
                acceptFunction={deleteCampaign}
                showModal={showModal}
                message={["Do you really want to delete ", <b>{selectedCampaign.name}</b>, "?"]}
                handleCloseModal={handleCloseModal}
            />
        );
    }

    if (showModal && modalType === "show-notes") {
        ShowNotesModal = (
            <GeneralModalStyles className="modal-wrapper">
                <div className="modal-body" style={{width: "550px"}}>
                    <div onClick={handleCloseModal} className="modal-close">
                        <MdClose className="action-button button-delete"/>
                    </div>

                    <div style={{padding: "40px 10px 20px"}}>{selectedCampaign.notes}</div>
                </div>
            </GeneralModalStyles>
        )
    }
    
    return (
        <DefaultLayout>
            <div>
                <div>{campaigns.length !== 0 && <Link to={`/clients`} style={{border:"0", fontSize: "20px", fontWeight: "bold"}}><IoArrowBack style={{transform: "translateY(3px)"}}/>Back</Link>}</div>
                <Title>{campaigns.length !== 0 && <Link to={`/clients`} style={{color: "#52bad5"}}>{campaigns[0].client.name}</Link>} Campaigns</Title>
            </div>

            <CampaignSearchBar handleSearchCampaignInput={handleSearchCampaignInput} openCampaignModal={ openCampaignModal }/>

            <div style={style} onClick={(ev) => setActiveCampaign(ev)}>
                {visibleCampaigns.length !== 0 &&
                    visibleCampaigns.map((campaign) => {
                        if (!("parent" in campaign) || campaign.parent === null) {
                            return (
                                <CampaignCard
                                    key={campaign._id}
                                    dataId={campaign._id}
                                    campaign={campaign}
                                    openShareModal={openShareModal}
                                    openEditModal={openCampaignModal}
                                    openDeleteModal={openDeleteModal}
                                    openNotesModal={openNotesModal}
                                />
                            );
                        }
                    })}
                {loadingCampaigns === false && visibleCampaigns.length === 0 && <h2>No campaigns found</h2>}
                {loadingCampaigns === true && <h2>Loading campaigns...</h2>}
            </div>

            {DeleteModalUx}
            {editModal}
            {shareModal}
            {ShowNotesModal}
        </DefaultLayout>
    );
};

export default CampaignsMain;
