import React, {useState} from "react";
import GeneralModalStyles from "../styles/GeneralModalStyles";
import Title from "../styles/TitleStyle";
import {MdClose} from "react-icons/md";
import FormStyles from "../styles/FormStyles";

const UploadBannerArchive = ({extractCSRF, setBanner, activeCampaign, handleCloseModal, saveCampaign}) => {
    const [selectedArchive, setSelectedArchive] = useState(null);
    const [overwrite, setOverwrite] = useState(false);

    const handleArchiveInputChange = (e) => {
        e.stopPropagation();

        const archiveInput = e.target;
        const file = archiveInput.files[0];

        setSelectedArchive(file);
    }

    const handleArchiveDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const file = e.dataTransfer.items[0].getAsFile();
        if (file.type !== "application/x-zip-compressed") {
            return;
        }

        setSelectedArchive(file);
    };

    const toggleOverwrite = (ev) => {
        setOverwrite(ev.target.checked);
    };

    const uploadArchive = (e) => {
        e.stopPropagation();
        e.preventDefault();

        let form = new FormData();
        form.append("archive", selectedArchive, selectedArchive.name);
        form.append("overwrite", overwrite);

        fetch(`/uploadbanners?cid=${activeCampaign._id}`, {
            method: "post",
            headers: {
                "xsrf-token": extractCSRF(),
            },
            body: form,
        })
            .then((res) => {
                if (!res.ok) throw new Error(res.statusText);
                return res.json();
            })
            .then((data) => {
                if (data.success) {
                    const newActiveCampaign = { ...activeCampaign };
                    newActiveCampaign.projectID = data.projectID;
                    saveCampaign(newActiveCampaign);

                    handleCloseModal();
                } else {
                    setBanner(true, "bad", data.message, false);
                }
            })
            .catch((err) => {
                setBanner(true, "bad", err.message, false);
            });
    };

    return (
        <GeneralModalStyles className="modal-wrapper">
            <div className="modal-body">
                <div onClick={handleCloseModal} className="modal-close">
                    <MdClose className="action-button button-delete" />
                </div>

                <FormStyles>
                    <Title>Upload Archive</Title>
                    <div className="archiveDrop" style={{display:"flex"}} onDrop={handleArchiveDrop}>
                        <div>
                            <p style={{fontSize: "16px"}} id="archiveDropInstructions">Drop archive file or browse your system</p>
                            <label id="browseFiles">
                                {/*<p id="browseFilesLink">Or browse your system</p>*/}
                                <input
                                    type="file"
                                    name="archive"
                                    id="archive"
                                    required
                                    accept="application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip"
                                    onChange={handleArchiveInputChange}
                                />
                            </label>
                            {/*<p>Selected archive: {selectedArchive ? selectedArchive.name : "None"}</p>*/}
                            <div style={{display:"flex", marginTop: "5px"}}>
                                <span style={{marginRight: "10px"}}>Overwrite existing files:</span>
                                <input id="overwrite" name="overwrite" type="checkbox" onChange={toggleOverwrite} value={overwrite} />
                                <label htmlFor="overwrite" />
                            </div>
                        </div>

                        <img id="svgWrapper" style={{width: "80px", marginTop: "60px"}} src="/folder.svg" alt="folder icon" />
                    </div>

                    <div className="button-wrapper" style={{marginTop: "30px"}}>
                        <button className={`confirm-button confirmArchiveButton${selectedArchive ? "__enabled" : ""}`} disabled={!selectedArchive} onClick={uploadArchive}>Upload</button>
                    </div>
                </FormStyles>
            </div>
        </GeneralModalStyles>
    );
};

export default UploadBannerArchive;