import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import CampaignNavStyles, {
  StyledLinks,
  Selected
} from "./styles/CampaignNavStyles";
import ClientContext from "./ClientContext";
import CampaignList from "./CampaignsList";
import CampaignDrawerStyles from "./styles/CampaignDrawerStyles";
import SearchBox from "./styles/SearchBox";

const CampaignDrawer = props => (
  <CSSTransition
    in={props.showing}
    appear={true}
    mountOnEnter={true}
    unmountOnExit={true}
    timeout={{ enter: 200, exit: 200 }}
    classNames="drawer"
  >
    <CampaignDrawerStyles>
      <SearchBox
        value={props.searchCampaigns}
        name="Search"
        onKeyUp={props.handleSearchCampaignsInput}
      />
      <CampaignList {...props} />
    </CampaignDrawerStyles>
  </CSSTransition>
);

const Add = styled.button`
  font-family: "Open Sans";
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  padding: 0 2rem;
  font-weight: 400;
  background: none;
  border: none;
  border-bottom: 2px solid ${props => props.theme.lightGrey};
  text-transform: uppercase;
  color: ${props => props.theme.black};
  cursor: pointer;
  min-width: 100px;
  position: relative;
  &:after {
    content: "";
    width: 0;
    height: 2px;
    background: ${props => props.theme.lightBlue};
    position: absolute;
    margin-top: 1em;
    transition: all 0.4s ease;
  }
  &:focus,
  &:hover {
    outline: none;
    &:after {
      width: calc(100% - 4rem);
    }
  }
  @media (max-width: 700px) {
    min-width: 80px;
    padding: 0 1rem;
  }
`;

class ClientNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showingCampaignDrawer: !!this.props.showingCampaignDrawer
    };
  }
  // componentDidMount() {
  //   console.log(this.props.showingCampaignDrawer);
  //   this.setState({
  //     showingCampaignDrawer: !!this.props.showingCampaignDrawer
  //   });
  // }
  static contextType = ClientContext;
  toggleCampaignDrawer = e => {
    const current = this.state.showingCampaignDrawer;
    return this.setState(
      {
        showingCampaignDrawer: !current
      },
      this.context.resetVisibles
    );
  };
  showCampaignDrawer = () => this.setState({ showingCampaignDrawer: true });
  hideCampaignDrawer = () => this.setState({ showingCampaignDrawer: false });
  render() {
    return (
      <ClientContext.Consumer>
        {context => {
          const {
            activeClient,
            addClient,
            setActiveClient,
            visibleClients,
            handleSearchClientsInput,
            
          } = context;
          return (
            <CampaignNavStyles>
              <Add
                onClick={() => {
                  addClient();
                  this.hideCampaignDrawer();
                  this.props.history.push("/client");
                }}
              >
                <span role="img" aria-label="plus sign">
                  ➕
                </span>
                <span> New client </span>
              </Add>
              <Selected
                data-testid="active-client"
                onClick={this.toggleCampaignDrawer}
              >
                {(activeClient && activeClient.name) ||
                  (activeClient && "Creating new client") ||
                  "Select a client"}
              </Selected>
              {activeClient && (
                <StyledLinks>
                  <Link to="/client">Overview</Link>
                  <Link to="/clientrules">Rules</Link>
                </StyledLinks>
              )}
              <CampaignDrawer
                setActiveCampaign={setActiveClient}
                visibleCampaigns={visibleClients}
                showing={this.state.showingCampaignDrawer}
                toggleDrawer={this.toggleCampaignDrawer}
                handleSearchCampaignsInput={handleSearchClientsInput}
              />
            </CampaignNavStyles>
          );
        }}
      </ClientContext.Consumer>
    );
  }
}
export default withRouter(ClientNav);
