import React, { Component } from 'react';
import styled from "styled-components";

import {Dispatcher, Events} from '../../../Events';

import FileUploader from '../../../FileUploader/FileUploader'

const BannerImgStyling = styled.div`
textarea {
    width:100%;
    padding:1rem;
}

.color-picker {
    // width:30px;
    background:black;
}


.section-2 {
    display:grid;
    width:100%;
    grid-template-columns: 2fr 1fr .5fr;
    grid-gap:1rem;

    input, select {
        height:30px;
    }
}

input[type="reset"] {
    padding: 8px 25px;
    border-radius: 5px;
    background: lightgray;
    color: #000;
    margin-left: 10px;
    border:2px solid transparent;
    font-weight:bold;

    &:hover {
        cursor:pointer;
        background:none;
        border:2px solid lightgray;
    }
}

.item-display {
    display:flex;
    flex-direction:column;
}

input[type="submit"] {
    padding: 8px 25px;
    border-radius: 5px;
    background: #52bad5;
    color: white;
    margin-left: 20px;
    border:2px solid transparent;
    font-weight:bold;

    &:hover {
        cursor:pointer;
        background:white;
        border:2px solid #52bad5;
        color:#52bad5;
    }
}

`

class BannerImgOptions extends Component {

    static defaultProps = {
        id: null,
        edit: false,
        options: { 
            style: '',
            class: 'omni',
        },
        picName: null,
        closeModal: () => {}
    }

    constructor(props) {

        super(props)

        this.state = { 
            file: null,
            uploader: null,
            imgName: 'No file chosen',
            options: props.options,
            toggleCustomCss: false,
        }


    }
       
    onSubmit = (e) => {

        e.preventDefault();

        Dispatcher.dispatch({
            event: this.props.edit ? Events.UPDATE_BANNER_ELEMENT : Events.ADD_BANNER_ELEMENT,
            payload: {
                'id': this.props.id,
                'type': 'BannerImageItem',
                'options': this.state.options,
            }
        });

       this.props.closeModal();

    }
    
    setClass = e => {
        const options = { ...this.state.options, class: e.target.value}
        this.setState(() =>({options}));
    }

    setStyle = e => {
        const options = { ...this.state.options, style: e.target.value}
        this.setState(() =>({options}));
    }

    onChange = (uploader, e) => {
        this.setState({
            options : {...this.state.options, ...uploader.uploads[0]},
            imgName: uploader.uploads[0].filename
        })
    }

   render() {

    const {state, props }  = this;
    
    const { onSubmit, onChange, setStyle } = this;
    
    // img default options
    const imgOptions = {
        width:'100px',
        height:'100px'
    }

    return (
        <BannerImgStyling>
            <form onSubmit={onSubmit}>

                <div className="section-3">
                
                    <FileUploader 
                        // do not forget to remove MULTIPLE
                        multiple={true}
                        uploads={[state.options]} 
                        onChange={onChange} 
                        dimensions={imgOptions}
                    />

                </div>

                <div className="section-4">
                    <div>
                        <label htmlFor="text-mixer"><b>Mixer Identifier</b></label>
                        <div style={{display:'flex', 'alignItems': 'center'}}>
                            <input id="text-mixer" placeholder="omni" onChange={this.setClass} value={state.options.class} style={{width:'100%'}} />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="text-mixer"><b>Custom CSS</b></label>
                        <div style={{display:'flex', 'alignItems': 'center'}}>
                                <textarea 
                                    id="text-css"
                                    value={state.options.style}
                                    placeholder="background-color: red"
                                    onChange={setStyle} 
                                    style={{marginTop:'1rem'}} 
                                    rows="8" ></textarea>
                        </div>
                </div>

                </div>

                        
                <div style={{display:'flex', justifyContent : 'flex-end', marginTop:'2rem'}}>
                    <div>
                        <input type="submit" value={ props.edit ? 'Update' : 'Add' }/>
                        <input type="reset" onClick={ props.closeModal} value="Cancel"/>
                    </div>
                </div>
            </form>
        </BannerImgStyling>
    )
   }
}

export default BannerImgOptions;