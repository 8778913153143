import React from "react";
import Loader from "./styles/Loader";
import GooglePageStyles from "./styles/GooglePageStyles";
import Form from "./styles/Form";
import FacebookStyles from "./styles/FacebookStyles";
import ExtendedWrapperFromModalStyles from "./styles/ExtendedWrapperFromModalStyles";
import FormStyles from "./styles/FormStyles";
import Title from "./styles/TitleStyle";

export default class GoogleAdsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingCustomers: true,
            customers: [],
            selectedCustomer: null,
            loadingCampaigns: false,
            campaigns: [],
            visibleCampaigns: false,
            selectedCampaign: null,
            loadingAdGroups: false,
            adGroups: [],
            visibleAdGroups: false,
            selectedAdGroup: null,
            creatingNewAdGroup: false,
            creatingAd: false,
            newAdGroup: {name: "", status: "PAUSED"},
            newAd: {name: "", displayUrl: "", finalUrl: "", images: ""},
            url: {
                website: "",
                source: "",
                medium: "",
                name: "",
                term: "",
                content: "{{ImageName}}&cn={{BannerID}}&cid={{CampaignID}}"
            },

            googleErrors: [],
            projectGroups: []
        };
        this.cid = this.props.match.match.params.id;
    }

    fetchAccounts = () => {
        fetch(`/getListOfCustomers?cid=${this.cid}&eid=${this.props.eid}`, {
            credentials: "same-origin"
        })
            .then(res => {
                if (res.ok) return res.json();
                throw new Error(res.status + " " + res.statusText);
            })
            .then(data => {
                if (data.entries)
                    return this.setState({
                        customers: [...data.entries],
                        loadingCustomers: false
                    });
                if (data.error && data.message.includes("invalid_grant")) {
                    this.props.setBanner(
                        true,
                        "bad",
                        "Session expired. Please log into your Google account again.",
                        false,
                        10000
                    );
                    return this.setState({loadingCustomers: false, customers: []});
                }
                throw new Error(data.message);
            })
            .catch(err => this.props.setBanner(true, "bad", err.message));
        const xsrfToken = this.props.extractCSRF();
        fetch("/getProjectGroups", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "xsrf-token": xsrfToken
            },
            body: JSON.stringify({cid: this.cid})
        })
            .then(res => {
                if (res.ok) return res.json();
                throw new Error(res.status + ": " + res.statusText);
            })
            .then(data => {
                if (data.error) return this.props.setBanner(true, "bad", data.message);
                return this.setState({projectGroups: data.groups});
            })
            .catch(err => this.props.setBanner(true, "bad", err.message));
    };

    componentDidMount() {
        // Get campaigns
        //this.props.eid && this.fetchAccounts();
        this.fetchAccounts();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.eid !== this.props.eid) {
            this.props.eid && this.fetchAccounts();
        }
    }

    getListOfCampaigns = () => {
        // @todo: remove eid from all links
        fetch(
            `/getListOfCampaigns?cid=${this.cid}&eid=${
                this.state.selectedCustomer
                    ? this.state.selectedCustomer.customerId
                    : this.props.eid
            }`,
            {
                credentials: "same-origin"
            }
        )
            .then(res => {
                if (res.ok) return res.json();
                throw new Error(res.status + " " + res.statusText);
            })
            .then(data => {
                if (data.entries)
                    return this.setState({
                        campaigns: [...data.entries],
                        visibleCampaigns: [...data.entries],
                        loadingCampaigns: false
                    });
                return this.setState({campaigns: [], loadingCampaigns: false});
            })
            .catch(err => this.props.setBanner(true, "bad", err.message));
    };
    getListOfAdGroups = () => {
        fetch(
            `/getListOfAdGroups?cid=${this.cid}&eid=${
                this.state.selectedCustomer
                    ? this.state.selectedCustomer.customerId
                    : this.props.eid
            }`,
            {
                credentials: "same-origin"
            }
        )
            .then(res => {
                if (res.ok) return res.json();
                throw new Error(res.status + " " + res.statusText);
            })
            .then(data => {
                console.log(data);
                if (data.entries)
                    return this.setState({
                        adGroups: [...data.entries],
                        visibleAdGroups: [...data.entries],
                        loadingAdGroups: false
                    });
                return this.setState({adGroups: [], loadingAdGroups: false});
            })
            .catch(err => this.props.setBanner(true, "bad", err.message));
    };
    selectThing = ev => {
        const category = ev.target.options[ev.target.selectedIndex].dataset.category;

        if(category === "newAdGroup"){
            this.startCreatingNewAdGroup();
        }

        const uniq = ev.target.options[ev.target.selectedIndex].dataset.uniq;
        const listOfThings = this.state[category];

        let uniqPart = "";
        switch (category) {
            case "customers":
                uniqPart = "customerId";
                break;
            case "campaigns":
                uniqPart = "id";
                break;
            case "adGroups":
                uniqPart = "id";
                break;
            default:
                return;
        }

        const found = {
            ...listOfThings.filter(item => item[uniqPart] == uniq)[0]
        };


        switch (category) {
            case "customers":
                return this.setState(
                    {
                        selectedCustomer: found,
                        selectedCampaign: null,
                        selectedAdGroup: null,
                        creatingNewAdGroup: false,
                        loadingCampaigns: true,
                        loadingAdGroups: true
                    },
                    () => {
                        this.getListOfCampaigns();
                        this.getListOfAdGroups();
                    }
                );

            case "campaigns":
                // when you select a campaign we filter the adGroups list to just those belonging to that campaign
                const filteredAdGroups = this.state.adGroups.filter(
                    adGroup => adGroup.campaignId == found.id
                );

                return this.setState({
                    selectedCampaign: found,
                    selectedAdGroup: null,
                    visibleAdGroups: filteredAdGroups,
                    creatingNewAdGroup: false
                });

            case "adGroups":
                // when you select an adGroup we filter the campaigns list to just the campaign that the adGroup belongs to
                // and because adGroup can only belong to one campaign we also set the filtered campaign to be the selected one
                // const filteredCampaigns = this.state.campaigns.filter(
                //   campaign => campaign.id === found.campaignId
                // );
                // *** maybe not, seems confusing to user who can't go back to the full list of campaigns ***
                return this.setState({
                    selectedAdGroup: found,
                    creatingNewAdGroup: false,
                    newAdGroup: {name: "", status: "PAUSED"}
                    // visibleCampaigns: filteredCampaigns,
                    // selectedCampaign: filteredCampaigns[0]
                });
            default:
                return;
        }
    };
    startCreatingNewAdGroup = () => {
        // ev.preventDefault();
        // ev.stopPropagation();
        return this.setState({creatingNewAdGroup: true, selectedAdGroup: null});
    };
    handleInput = ev => {
        const [obj, key] = ev.target.name.split("__");
        const previous = {...this.state[obj]};
        previous[key] = ev.target.value;
        return this.setState({[obj]: previous}, () => {
            if (obj === "url") return this.buildURL();
        });
    };
    dismissErrors = () => {
        this.setState({googleErrors: []});
    };
    createAdGroup = ev => {
        ev.preventDefault();
        this.setState({loadingAdGroups: true});
        const adGroup = {...this.state.newAdGroup};
        adGroup.campaignId = this.state.selectedCampaign.id;
        const xsrfToken = this.props.extractCSRF();
        fetch(`/createNewAdGroup`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "xsrf-token": xsrfToken
            },
            body: JSON.stringify({
                cid: this.cid,
                eid: this.state.selectedCustomer.customerId,
                campaign: this.state.selectedCampaign.resourceName,
                adGroup
            })
        })
            .then(res => {
                if (res.ok) return res.json();
                throw new Error(res.status + " " + res.statusText);
            })
            .then(data => {
                console.log(data);
                console.log("====== AD GROUPS ======")
                console.log(...this.state.adGroups)
                if (data.error) {
                    if (data.error.message) {
                        this.props.setBanner(
                            true,
                            "bad",
                            data.error.message
                        );
                        // This works, catches the duplicate error
                    } else {
                        this.props.setBanner(
                            true,
                            "bad",
                            "An error has occured. Please refresh the page and try again in a few seconds"
                        );
                    }
                    return this.setState({loadingAdGroups: false});
                }
                this.props.setBanner(true, "good", `Group ${adGroup.name} was created successfully`);
                const newAdGroup = data;
                const adGroups = [...this.state.adGroups];
                const visibleAdGroups = [...this.state.visibleAdGroups];
                adGroups.unshift(newAdGroup);
                visibleAdGroups.unshift(newAdGroup);
                return this.setState({
                    loadingAdGroups: false,
                    adGroups,
                    visibleAdGroups,
                    creatingNewAdGroup: false
                });
            })
            .catch(err => this.props.setBanner(true, "bad", err.message));
    };
    createAds = ev => {
        ev.preventDefault();
        const rawNewAd = {...this.state.newAd};
        const data = {
            cid: this.cid,
            eid: this.state.selectedCustomer.customerId,
            adGroupId: this.state.selectedAdGroup.id,
            adGroupStatus: this.state.selectedAdGroup.status,
            newAd: {}
        };
        const displayUrl = rawNewAd.displayUrl
            .toLowerCase()
            .trim()
            .split(/\s/gm)
            .join("");
        const finalUrl = rawNewAd.finalUrl.trim().toLowerCase();

        const images = rawNewAd.images
            .trim()
            .split(",")
            .map(item => {
                let [first, second] = item.trim().split("-");
                // test for any non-digit character and return an empty string in case you find it
                if (!second || !second.length) return /\D/gm.test(first) ? "" : first;
                if (/\D/gm.test(second) || /\D/gm.test(first)) return "";
                if (parseInt(second) < parseInt(first)) {
                    let temp = second;
                    second = first;
                    first = temp;
                }
                return `${first}-${second}`;
            })
            .filter(item => item);
        if (
            !displayUrl ||
            !finalUrl.length ||
            (!images.length && !rawNewAd.projectGroup) ||
            !rawNewAd.name
        )
            return this.props.setBanner(
                true,
                "bad",
                "Something's missing. Can't create ads"
            );
        data.newAd.name = rawNewAd.name;
        data.newAd.displayUrl = displayUrl;
        data.newAd.finalUrl = finalUrl;
        data.newAd.images = images;
        if (rawNewAd.projectGroup === "__allimages") {
            data.newAd.images = ["0-99999"];
        } else {
            data.newAd.projectGroup = rawNewAd.projectGroup;
        }
        this.setState({creatingAd: true});
        const xsrfToken = this.props.extractCSRF();
        fetch(`/createNewAd`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "xsrf-token": xsrfToken
            },
            credentials: "same-origin",
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) return res.json();
                throw new Error(res.status + " " + res.statusText);
            })
            .then(data => {
                if (!data.error) {
                    return this.setState(
                        {creatingAd: false, googleErrors: []},
                        this.props.setBanner(
                            true,
                            "good",
                            "Success: all images posted to Google Ads"
                        )
                    );
                }
                this.setState(
                    {creatingAd: false, googleErrors: data.errors},
                    this.props.setBanner(
                        true,
                        "bad",
                        data.message || "Something went wrong. Check log",
                        false,
                        5000
                    )
                );
                console.log(data.errors);
            })
            .catch(err => {
                console.log(err)
                this.setState(
                    {creatingAd: false},
                    this.props.setBanner(true, "bad", err.message)
                )
            });
    };
    buildURL = () => {
        const url = {...this.state.url};
        if (!url.website || !url.source) return;
        const finalUrl = `${url.website}?utm_source=${url.source}${
            url.medium ? `&utm_medium=${url.medium}` : ""
        }${url.name ? `&utm_campaign=${url.name}` : ""}${
            url.term ? `&utm_term=${url.term}` : ""
        }${url.content ? `&utm_content=${url.content}` : ""}`;
        const newAd = {...this.state.newAd};
        newAd.finalUrl = finalUrl;
        this.setState({newAd});
    };

    render() {
        return (
            <>
                <ExtendedWrapperFromModalStyles style={{width: "1000px", marginTop: "40px", marginBottom: "40px"}}>
                    <Title>Generate Ad Set</Title>
                    {!this.cid ? (
                        <h2>Select a campaign and log into Google.</h2>
                    ) : (
                        <>
                            <FacebookStyles
                                className={
                                    this.state.loadingCustomers ||
                                    this.state.loadingCampaings ||
                                    this.state.loadingAdGroups
                                        ? "loading"
                                        : ""
                                }
                            >
                                <FormStyles className="accounts">
                                    <div className="listContainer adAccounts">
                                        <h3>{this.props.user.googleAccount.name} ➡ Ad Account</h3>
                                        <select onChange={this.selectThing}>
                                            <option>Select Ad Account</option>
                                            {this.state.customers
                                                ? this.state.customers.map((customer, i) => (
                                                    <option
                                                        key={i}
                                                        className={`adAccountNameListItem ${
                                                            this.state.selectedCustomer &&
                                                            customer.customerId === this.state.selectedCustomer.customerId
                                                                ? "selected"
                                                                : ""
                                                        }`}
                                                        data-category="customers"
                                                        data-uniq={customer.customerId}
                                                    >
                                                        {customer.name}
                                                    </option>
                                                ))
                                                : ""}

                                        </select>
                                    </div>

                                    <div className="listContainer adAccounts">
                                        <h3>Campaign</h3>
                                        {this.state.visibleCampaigns
                                            ? (
                                                <select onChange={this.selectThing}>
                                                    <option>Select Campaign</option>
                                                    {this.state.visibleCampaigns.map((campaign, i) => (
                                                        <option
                                                            key={i}
                                                            className={`adAccountNameListItem ${
                                                                this.state.selectedCampaign &&
                                                                campaign.id === this.state.selectedCampaign.id
                                                                    ? "selected"
                                                                    : ""
                                                            }`}
                                                            data-category="campaigns"
                                                            data-uniq={campaign.id}
                                                        >
                                                            {campaign.name}
                                                        </option>
                                                    ))
                                                    }
                                                </select>
                                            )
                                            : ""
                                        }
                                    </div>

                                    <div className="listContainer adAccounts">
                                        <h3>Ad Group</h3>
                                        {this.state.selectedCampaign
                                            ? (
                                                <select onChange={this.selectThing}>
                                                    <option selected disabled>Select Ad Set</option>
                                                    <option data-category="newAdGroup">Create New Ad Group +</option>
                                                    {this.state.visibleAdGroups.map((adGroup, i) => (
                                                        <option

                                                            key={i}
                                                            className={`adAccountNameListItem ${
                                                                this.state.selectedAdGroup &&
                                                                adGroup.id === this.state.selectedAdGroup.id
                                                                    ? "selected"
                                                                    : ""
                                                            }`}
                                                            data-category="adGroups"
                                                            data-uniq={adGroup.id}
                                                        >
                                                            {adGroup.name}
                                                        </option>
                                                    ))
                                                    }
                                                </select>
                                            )
                                            : ""
                                        }
                                    </div>

                                </FormStyles>

                                <div className="workingSpace">
                                    {this.state.creatingNewAdGroup ? (
                                        <div>
                                            <h3>Create a new Ad Group</h3>
                                            <FormStyles
                                                data-testid="adsets-new"
                                                onSubmit={this.createNewAdSet}
                                            >
                                                <div className="group" style={{display:"flex"}}>
                                                    <div style={{width: "33.33%", paddingRight:"20px", boxSizing:"border-box"}}>
                                                        <label>Name:</label>
                                                        <input
                                                            className="formInput"
                                                            type="text"
                                                            name="newAdGroup__name"
                                                            data-testid="adGroup-name"
                                                            value={this.state.newAdGroup.name}
                                                            onChange={this.handleInput}
                                                            placeholder="Ad Group Name"
                                                        />
                                                        <input
                                                            name="campaign"
                                                            type="hidden"
                                                            value={this.state.selectedCampaign.resourceName}
                                                        />
                                                    </div>

                                                    <div style={{width: "33.33%", paddingRight:"20px", boxSizing:"border-box"}}>
                                                        <label>Campaign:</label>
                                                        <input
                                                            className="formInput"
                                                            type="text"
                                                            disabled
                                                            value={this.state.selectedCampaign.name}
                                                        />
                                                    </div>

                                                    <div style={{width: "33.33%", boxSizing:"border-box"}}>
                                                        <label>Status:</label>
                                                        <select
                                                            className="formInput"
                                                            name="newAdGroup__status"
                                                            value={this.state.newAdGroup.status}
                                                            readOnly
                                                            data-testid="adGroup-status"
                                                        >
                                                            <option value="PAUSED">Paused</option>
                                                            <option value="ENABLED">Enabled</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="button-wrapper" style={{marginTop: "40px"}}>
                                                    <button
                                                        className="confirm-button"
                                                        data-testid="adGroup-submit"
                                                        type="submit"
                                                        onClick={this.createAdGroup}
                                                    >
                                                        Create
                                                    </button>
                                                </div>
                                            </FormStyles>
                                        </div>
                                    ) : null}

                                    {this.state.selectedAdGroup ? (
                                        <div>
                                            <h3>Create ads</h3>
                                            {this.state.creatingAd ? (
                                                <div className="spinner active">
                                                    <h3>Posting to Google Ads</h3>
                                                </div>
                                            ) : (
                                                <>
                                                    <FormStyles
                                                        data-testid="adsets-new"
                                                        onSubmit={this.createNewAdSet}
                                                    >
                                                        <h4>URL Builder</h4>
                                                        <div className="group" style={{display:"flex"}}>
                                                            <div style={{width: "33.33%", paddingRight:"20px", boxSizing:"border-box"}}>
                                                                <label>* Website URL</label>
                                                                <input
                                                                    className="formInput"
                                                                    type="text"
                                                                    name="url__website"
                                                                    value={this.state.url.website}
                                                                    onChange={this.handleInput}
                                                                    placeholder="The full website URL (e.g. https://www.example.com)"
                                                                />
                                                            </div>
                                                            <div style={{width: "33.33%", paddingRight:"20px", boxSizing:"border-box"}}>
                                                                <label>* Campaign Source</label>
                                                                <input
                                                                    className="formInput"
                                                                    type="text"
                                                                    name="url__source"
                                                                    value={this.state.url.source}
                                                                    onChange={this.handleInput}
                                                                    placeholder="The referrer: (e.g. google, newsletter)"
                                                                />
                                                            </div>
                                                            <div style={{width: "33.33%", boxSizing:"border-box"}}>
                                                                <label>Campaign Medium</label>
                                                                <input
                                                                    className="formInput"
                                                                    type="text"
                                                                    name="url__medium"
                                                                    value={this.state.url.medium}
                                                                    onChange={this.handleInput}
                                                                    placeholder="Marketing medium: (e.g. cpc, banner, email)"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="group" style={{display:"flex"}}>
                                                            <div style={{width: "33.33%", paddingRight:"20px", boxSizing:"border-box"}}>
                                                                <label>Campaign Name</label>
                                                                <input
                                                                    className="formInput"
                                                                    type="text"
                                                                    name="url__name"
                                                                    value={this.state.url.name}
                                                                    onChange={this.handleInput}
                                                                    placeholder="Product, promo code, or slogan (e.g. spring_sale)"
                                                                />
                                                            </div>
                                                            <div style={{width: "33.33%", paddingRight:"20px", boxSizing:"border-box"}}>
                                                                <label>Campaign Term</label>
                                                                <input
                                                                    className="formInput"
                                                                    type="text"
                                                                    name="url__term"
                                                                    value={this.state.url.term}
                                                                    onChange={this.handleInput}
                                                                    placeholder="Identify the paid keywords"
                                                                />
                                                            </div>
                                                            <div style={{width: "33.33%", boxSizing:"border-box"}}>
                                                                <label>Campaign Content</label>
                                                                <input
                                                                    className="formInput"
                                                                    disabled
                                                                    type="text"
                                                                    value={this.state.url.content}
                                                                />
                                                            </div>
                                                        </div>
                                                    </FormStyles>

                                                    <FormStyles
                                                        data-testid="adsets-new"
                                                        onSubmit={this.createNewAdSet}
                                                    >
                                                        <h4>Resulting Ad</h4>
                                                        <div className="group" style={{display:"flex"}}>
                                                            <div style={{width: "33.33%", paddingRight:"20px", boxSizing:"border-box"}}>
                                                                <label>Name Prefix</label>
                                                                <input
                                                                    className="formInput"
                                                                    type="text"
                                                                    value={this.state.newAd.name}
                                                                    name="newAd__name"
                                                                    onChange={this.handleInput}
                                                                />
                                                            </div>

                                                            <div style={{width: "33.33%", paddingRight:"20px", boxSizing:"border-box"}}>
                                                                <label>* Display URL</label>
                                                                <input
                                                                    className="formInput"
                                                                    type="text"
                                                                    value={this.state.newAd.displayUrl}
                                                                    name="newAd__displayUrl"
                                                                    onChange={this.handleInput}
                                                                />
                                                            </div>

                                                            <div style={{width: "33.33%", boxSizing:"border-box"}}>
                                                                <label>Final URL</label>
                                                                <input
                                                                    className="formInput"
                                                                    type="text"
                                                                    value={this.state.newAd.finalUrl}
                                                                    disabled
                                                                    name="newAd__finalUrl"
                                                                    placeholder="Use URL builder for this"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="group" style={{display:"flex"}}>
                                                            <div style={{width: "50%", paddingRight:"20px", boxSizing:"border-box"}}>
                                                                <label>Select an image group</label>
                                                                <select
                                                                    value={this.state.newAd.projectGroup}
                                                                    onChange={this.handleInput}
                                                                    name="newAd__projectGroup"
                                                                >
                                                                    <option
                                                                        value=""
                                                                        selected={!this.state.newAd.projectGroup}
                                                                    >
                                                                        None
                                                                    </option>
                                                                    <option value="__allimages">All Images</option>
                                                                    {this.state.projectGroups.map(group => (
                                                                        <option value={group.name}>{group.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>

                                                            <div style={{width: "50%", boxSizing:"border-box"}}>
                                                                <label>Or add manual image numbers</label>
                                                                <input
                                                                    className="formInput"
                                                                    type="text"
                                                                    value={this.state.newAd.images}
                                                                    name="newAd__images"
                                                                    placeholder="Image numbers or range. Ex: 1, 2, 10-20"
                                                                    onChange={this.handleInput}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="button-wrapper" style={{marginTop: "40px"}}>
                                                            <button
                                                                className="confirm-button"
                                                                type="submit"
                                                                onClick={this.createAds}
                                                            >
                                                                Create Ads
                                                            </button>
                                                        </div>
                                                    </FormStyles>
                                                </>
                                            )}
                                        </div>
                                    ) : null}
                                    {this.state.googleErrors && this.state.googleErrors.length ? (
                                        <div className="listContainer">
                                            <div className="dismissButtonGrouping">
                                                <h3>Error log</h3>
                                                <button
                                                    title="Dismiss"
                                                    type="button"
                                                    onClick={this.dismissErrors}
                                                >
                                                    ✖
                                                </button>
                                            </div>
                                            {this.state.googleErrors.map(error => (
                                                <p>{JSON.stringify(error)}</p>
                                            ))}
                                        </div>
                                    ) : null}
                                </div>
                            </FacebookStyles>
                        </>
                    )}
                </ExtendedWrapperFromModalStyles>
            </>
        );
    }
}
