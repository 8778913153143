import axios from 'axios';

const getCSFR = function(){
    try {
        const csrfCookie = document.cookie
          .split(";")
          .filter(part => part.indexOf("xsrf-token") >= 0);
        if (!csrfCookie[0]) return null;
        const csrfToken = csrfCookie[0].split("=")[1];
        if (!csrfToken) throw new Error("No CSRF");
        return csrfToken;
      } catch (err) {
        console.log(err);
        return this.setBanner(
          true,
          "bad",
          "Request cannot be completed. Refresh page and try again",
          true
        );
    }
};

axios.interceptors.request.use(function(config){
    console.log(config);
    config.headers["xsrf-token"] = getCSFR();
    return config;
});

export default axios;