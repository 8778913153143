import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: inherit;
    padding: 2rem 5rem;
    display: flex;
    flex-direction: column;
`;

export default Container;
